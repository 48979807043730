import { AddressAPI } from '@app/lib/api/address/api.address.models';
import { UserProfile } from '@app/shared/models/user-profiles.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserProfileActions = createActionGroup({
  source: 'User Profiles',
  events: {
    'On Get User Profile': props<{ userId: string }>(),
    'On Get Hobbies': emptyProps(),
    'On Get Locations': emptyProps(),
    'On Upload Avatar User Profile': props<{ avatar_url: string }>(),
    'On Upload Frame User Profile': props<{ frame_url: string }>(),
    'On Delete Frame User Profile': emptyProps(),
    'On Upload BackGround User Profile': props<{ cover_url: string }>(),
    'On Update User Profile': props<{ newProfile: UserProfile }>()
  }
});

export const UserProfileApiActions = createActionGroup({
  source: 'User Profiles Api',
  events: {
    'On Get User Profile Success': props<{ userProfile: UserProfile }>(),
    'On Get User Profile Fail': props<{ message: string }>(),

    'On Get User Hobbies Success': props<{ hobbies: string[] }>(),

    'On Get Locations Success': props<{ locations: AddressAPI.CountriesModelResponse[] }>(),

    'On Create User Profile Success': props<{ userProfile: UserProfile }>(),
    'On Create User Profile Fail': props<{ message: string }>(),

    'On Update User Profile Success': props<{ newProfile: UserProfile }>(),
    'On Update User Profile Fail': props<{ message: string }>(),

    'On Upload Avatar User Profile Success': props<{ avatar_url: string }>(),

    'On Upload Frame User Profile Success': props<{ frame_url: string }>(),

    'On Set Error': props<{ isError: boolean }>(),

    'On Reset Status Update User Profile': emptyProps(),

    'On Reset Data User Profile': emptyProps()
  }
});
