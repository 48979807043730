import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { MediaService } from '@app/core/services/media.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UtilityService } from '@app/core/services/utility.service';
import { ADS_ACTION, ADS_CATEGORY } from '@app/lib/api/client/api.client.constant';
import { TranslateParamModel } from '@app/lib/api/translate/api.translate.model';
import { selectUserPosts } from '@app/modules/main/states/user-posts/user-posts.selectors';
import {
  FILE_EXTENSION,
  MAX_CAPTION_LENGTH,
  MAX_TRUNCATED_TEXT_HEIGHT,
  MEDIA_TYPE,
  POST_PATTERN,
  POST_TYPE,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { FILE_TYPE_URL, Media, MediaInfo, checkUrl } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { CustomVideoDirective } from '../../custom-video/custom-video.directive';
import { CustomVideoService } from '../../custom-video/custom-video.service';
import { EventImg } from '../../media/media.component';
interface Language {
  name: string;
  code: string;
}

@Component({
  selector: 'post-content',
  templateUrl: './post-content.component.html',
  styleUrls: ['./post-content.component.scss']
})
export class PostContentComponent implements AfterViewInit {
  @Input() data: any;
  @Input() isShareBody = false;
  @Input() isPostView = false;
  @Input() isSharedPost = false;
  @Input() isWatchScreen = false;
  @Output() deletePost = new EventEmitter();
  @Output() archivePost = new EventEmitter();

  mediaData: MediaInfo | undefined;
  mediaList: Media[] = [];
  isShowAllContent = false;
  isTranslate = false;
  textTranslated: string;
  activeIndex: number;
  hostUrl = environment.baseURL;
  MAX_CAPTION_LENGTH = MAX_CAPTION_LENGTH;
  isClickedPlay = false;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  contentHeight = 0;
  POST_PATTERN = POST_PATTERN;
  MEDIA_TYPE = MEDIA_TYPE;
  POST_TYPE = POST_TYPE;
  isShowView = false;
  languages: Language[];
  formGroup: FormGroup;
  userPosts$ = this.store.select(selectUserPosts);
  apiUrl: string = environment.baseURL;
  @ViewChild('iframeEl') iframeEl: ElementRef;
  isShowScaleView = false;
  itemScale: any;
  @ViewChild(CustomVideoDirective) videoDirective: CustomVideoDirective;

  constructor(
    private el: ElementRef,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private translationService: TranslationService,
    private store: Store,
    public mediaService: MediaService,
    private location: Location,
    private toastMessageService: ToastMessageService,
    private analyticsService: AnalyticsService,
    private utilityService: UtilityService,
    private customVideoService: CustomVideoService
  ) {
  }

  applyThemeStyles(theme: any) {
    if (!theme) {
      return `min-h-[120px]`;
    }
    if (theme?.theme_type === 'IMAGE') {
      if (this.el.nativeElement.querySelector('.theme-bg')) {
        this.el.nativeElement.querySelector(
          '.theme-bg'
        ).style.backgroundImage = `url("${this.apiUrl}/storage/files/web/${theme?.background_img}.webp")`;
      }
      return `bg-cover h-[300px] md:h-[360px] bg-[#${theme?.background_color[0]}]`;
    } else if (theme?.theme_type === 'COLOR') {
      return `${theme?.background_thumbnail} h-[300px] md:h-[360px]`;
    }
    return `min-h-[120px]`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      if (this.data.media_urls) {
        this.mediaData = this.data.media_urls;
        this.mediaList = this.mediaData ? this.mediaData.media : [];
        if (this.mediaData) {
          if (!this.isShareBody) {
            this.mediaList = this.mediaData.media?.map(el => {
              let itemImg = { ...el };
              switch (itemImg.type) {
                case MEDIA_TYPE.image:
                  itemImg.original = el.original.includes('blob:') ? el.original : el.original + FILE_EXTENSION.image;
                  itemImg.thumbnail = el.thumbnail + FILE_EXTENSION.image;
                  break;
                case MEDIA_TYPE.video:
                  itemImg.original = el.original.includes('blob:') ? el.original : el.original + FILE_EXTENSION.video;
                  itemImg.thumbnail = el.thumbnail + FILE_EXTENSION.video;
                  break;
              }
              return itemImg;
            });
          } else {
            this.mediaList = this.mediaData.media;
          }
        }
      }
      if (this.data && this.data.post_info && this.data?.post_info?.media_urls) {
        this.mediaData = this.data?.post_info?.media_urls;
        this.mediaList = this.mediaData ? this.mediaData.media : [];
        if (this.mediaData) {
          if (!this.isShareBody) {
            this.mediaList = this.mediaData.media?.map(el => {
              let itemImg = { ...el };
              switch (itemImg.type) {
                case MEDIA_TYPE.image:
                  itemImg.original = el.original + FILE_EXTENSION.image;
                  itemImg.thumbnail = el.thumbnail + FILE_EXTENSION.image;
                  break;
                case MEDIA_TYPE.video:
                  itemImg.original = el.original + FILE_EXTENSION.video;
                  itemImg.thumbnail = el.thumbnail + FILE_EXTENSION.video;
                  break;
              }
              return itemImg;
            });
          } else {
            this.mediaList = this.mediaData.media;
          }
        }
      }
      this.checkResetMediaList();
    }
  }

  ngAfterViewInit(): void {
    this.contentHeight = this.el.nativeElement.querySelector('.post-content')?.offsetHeight | 0;
    if (this.iframeEl && this.data.post_type === POST_TYPE.livestream) {
      this.iframeEl.nativeElement.src = `${this.data.livestream_info?.url}&mute=true`;
    }
    this.cdRef.detectChanges();
    this.el.nativeElement.querySelector(
      '.custom-background'
    ).style.backgroundImage = `url("${this.hostUrl}/storage/files/web/${this.data?.theme_object?.background_img}.png")`;
  }

  onExpandText() {
    this.isShowAllContent = !this.isShowAllContent;
  }

  onTranslateText() {
    this.textTranslated = '';
    const selectedLangCode = this.translationService.getKeyLocalUserProfile('language')?.toLowerCase();
    this.isTranslate = !this.isTranslate;
    this.getTranslateText(selectedLangCode?.toLowerCase() || 'en');
    if (this.contentHeight > MAX_TRUNCATED_TEXT_HEIGHT) {
      this.isShowAllContent = true;
    }
  }

  getTranslateText(target: string) {
    let content = this.data.content ? this.data.content : this.data?.post_info?.content || '';
    content = this.markHashtagsForTranslation(content);
    const params: TranslateParamModel = {
      q: content.trim(),
      source: this.data?.language ? this.data.language : 'auto',
      target: target,
      format: 'html'
    };
    this.translationService.translateText(params).subscribe((res: any) => {
      if (res && res.success) {
        this.textTranslated = res?.data.translatedText;
      } else {
        this.isTranslate = false;
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          this.translationService.getTranslation('TRANSLATION.TRANSLATE_FAILED'),
          TOAST_MESSAGE_LIFETIME_DURATIONS.medium
        );
      }
    });
  }

  handleChangeLanguage(event: any) {
    this.textTranslated = '';
    this.getTranslateText(event.value.code);
  }

  clickImage(event: EventImg) {
    if (!this.mediaService.isUpdateVolume) {
      if (this.mediaService.isScaleVideo) {
        this.detachVideo(true);
      }
      if (!this.data.ads_id || !this.data?.advertising_object.ad?.destination_url) {
        this.isShowView = true;
        this.activeIndex = event.i;
        this.location.go(`/photo?postId=${this.data.id}&activeIndex=${this.activeIndex}`);
        this.analyticsService.pushToBuffer(event.media, ADS_CATEGORY.image, ADS_ACTION.click);
      } else if (this.data?.advertising_object.ad?.destination_url) {
        this.handleNavigateAdLink(this.data?.advertising_object.ad?.destination_url, this.data?.ads_id);
      }
    }
  }

  validUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    return checkUrl(urlString, style);
  }

  goToLivestreamDetail() {
    this.router.navigate(['/livestream', this.data.livestream_info?.id]);
  }
  applyFontStyles(theme: any) {
    if (theme) {
      return `placeholder:text-[#${theme.font_color}] text-[#${theme.font_color}]`;
    }
    return ``;
  }

  formattedContent() {
    let content = this.data.content ? this.data.content : this.data?.post_info?.content || '';
    const classHastag = this.data?.theme_object
      ? 'text-branding-base-white underline'
      : 'text-branding-primary-500 hover:underline';
    const colorTagPeople = this.data?.theme_object
      ? 'text-branding-base-white underline'
      : 'text-branding-primary-700 hover:underline text-sm';
    content = content?.replace(/(^|[^<])(#[a-zA-ZÀ-Ỹà-ỹ0-9_]+)/g, (match: any, prefix: any, symbol: any, word: any) => {
      return `<a href="hashtag/${symbol.replace('#', '')}" class="cursor-pointer ${classHastag}">${match}</a>`;
    });

    this.data?.message_ranges?.forEach((x: { full_name: string; entity_id: string }) => {
      let name: string = '@' + x?.full_name ?? '';
      content = content.replace(
        new RegExp(name, 'g'),
        `<a href="personal-profile/${x?.entity_id}" class="font-semibold ${colorTagPeople} hover:cursor-pointer">${x.full_name}</a>`
      );
    });
    content = this.translationService.urlify(content);
    return content ? content?.replace(/\n/g, '<br>') : content;
  }

  translateContent() {
    return this.formatContentTranslation(this.textTranslated);
  }

  handleNavigateAdLink(url: string, id: string): void {
    url &&
      id &&
      window.open(
        `${this.hostUrl}/link-hub/?url=${this.utilityService.ensureURLProtocol(
          url
        )}&adsId=${id}&platform=Web&screenSize=${innerWidth}x${innerHeight}&module=soctrip`,
        '_blank'
      );
  }

  onDeletePost(postData: any) {
    this.deletePost.emit(postData);
  }

  onArchivePost(postData: any) {
    this.archivePost.emit(postData);
  }

  checkResetMediaList(): void {
    if (
      !this.data?.media_urls?.media?.length &&
      !this.data?.library_object?.files?.length &&
      !this.data?.post_info?.media_urls.media.length
    ) {
      this.mediaList = [];
    }
  }

  contentNoHashTag(content: string) {
    let tempContent = content?.replace(/(^|[^<])([#])(\w+)/g, (match: any, prefix: any, symbol: any, word: any) => '');

    this.data?.message_ranges?.forEach((x: { full_name: string; entity_id: string }) => {
      let name: string = '@' + x?.full_name ?? '';
      tempContent = tempContent.replace(new RegExp(name, 'g'), '');
    });

    return tempContent;
  }

  markHashtagsForTranslation(content: string) {
    let tempContent = content?.replace(
      /(^|[^<])([#])(\w+)/g,
      (match: any, prefix: any, symbol: any, word: any) => `<${word}_tag></${word}_tag>`
    );
    this.data?.message_ranges?.forEach((x: { full_name: string; entity_id: string }) => {
      let name: string = '@' + x?.full_name ?? '';
      const nameTag = x.full_name.replace(' ', '_');
      tempContent = tempContent.replace(new RegExp(name, 'g'), `<${nameTag}_name></${nameTag}_name>`);
    });

    tempContent = tempContent?.replace(/\n/g, '<br/>');

    return tempContent;
  }

  formatContentTranslation(content: string) {
    let outputString = this.translationService.replaceHashtagTranslation(content);

    outputString = outputString?.replace(/(^|[^<])([#])(\w+)/g, (match: any, prefix: any, symbol: any, word: any) => {
      return `<a href="hashtag/${word}" class="cursor-pointer hover:underline text-branding-primary-500">${match}</a>`;
    });

    this.data?.message_ranges?.forEach((x: { full_name: string; entity_id: string }) => {
      let name: string = '@' + x?.full_name.toLowerCase() ?? '';
      outputString = outputString.replace(
        new RegExp(name, 'g'),
        `<a href="personal-profile/${x?.entity_id}" class="text-sm font-semibold text-branding-primary-700 hover:cursor-pointer hover:underline">${x.full_name}</a>`
      );
    });
    return outputString;
  }

  isShowTranslateButton(postData: any) {
    let content = postData.content ? postData.content : postData?.post_info?.content || '';
    if (postData?.theme_object || !this.formattedContent()) return false;
    if (postData?.preview_data && !postData.media_urls && this.translationService.isOnlyLink(content)) return false;
    if (!this.contentNoHashTag(content)?.length) return false;
    return this.translationService.checkLanguage(content, postData?.language);
  }

  detachVideo(isDetachPrevious = false) {
    this.mediaService.isScaleVideo = false;
    this.isShowScaleView = false;
    const viewContainerRef = isDetachPrevious ? this.mediaService.viewContainerRef : this.videoDirective.viewContainerRef;
    const original = isDetachPrevious ? this.mediaService.originalScale : this.itemScale.original;
    this.customVideoService.detach(viewContainerRef, original);
    const componentRef = this.customVideoService.getComponentRef(original);
    if (!isDetachPrevious) {
      componentRef?.location.nativeElement.querySelector('video').play();
    } else {
      this.mediaService.unScaleVideo.next(this.mediaService.originalScale);
    }
    componentRef?.setInput('isShowScaleIcon', this.mediaList.length === 1 ? true : false); 
  }

  handleScaleVideo(e: any) {
    if (e.isExistScale) {
      this.detachVideo(true);
    }
    this.isShowScaleView = true;
    this.itemScale = {...e};
    const componentRef = this.customVideoService.getComponentRef(this.itemScale.original);
    componentRef?.location.nativeElement.querySelector('video').play();
    componentRef?.setInput('isShowScaleIcon', false);
    setTimeout(() => {
      this.mediaService.isScaleVideo = true;
      this.mediaService.originalScale = this.itemScale.original;
      this.mediaService.viewContainerRef = this.videoDirective.viewContainerRef;
    });
  }
}
