import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@app/core/services/translation.service';
import { UserProfileService } from '@app/core/services/user-profile.service';
import { ApiClient } from '@app/lib/api/api-client';
import { EventApiModels } from '@app/lib/api/event/api.event.models';
import { FAN_PAGES_API_ENDPOINTS } from '@app/lib/api/page/api.page.constants';
import { PageAPI } from '@app/lib/api/page/api.page.model';
import { CATEGORY_LIST } from '@app/lib/api/page/category-mapping';
import {
  DetailPost,
  PostListPaging,
  PostListResponse,
  TopPerfPost,
  TopPerfPostResponse
} from '@app/lib/api/post/api.post.model';
import { StatsApiModels } from '@app/lib/api/statistics/api.statistics.models';
import {
  ASPECT_RATIO,
  FILE_EXTENSION,
  GROUP_OF_POST_STATUSES,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  PAGE_STATUS,
  ROLES,
  SORT
} from '@app/shared';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { MessageService } from 'primeng/api';
import { Observable, Subject, first, map } from 'rxjs';
import { ParamsSearch } from '../../advertisement/component/advertisement-create/component/ads-manager/dialog-select-post/dialog-select-post.component';
import { getFanpageInfo } from '../store/actions';
import { selectFanpageInfo, selectFanpageRole } from '../store/selector';

export type FanpageTabModel = {
  index: number;
  label: string;
  value: string;
};

type ViewTabModel = {
  value: string;
  index: number;
  basicViewIndex: number;
};
@Injectable({
  providedIn: 'root'
})
export class FanpageService {
  private api: ApiClient;

  fanpageInfo$ = this.store.select(selectFanpageInfo);
  fanpageRole$ = this.store.select(selectFanpageRole);

  activeIndex = 0;
  tabViewIndex = 0;
  totalFollower = 0;
  needUpdate: string;
  showGallery = false;
  apiUrl = environment.baseURL;
  fanpageInfo: PageAPI.PageInfo;
  translateResult: any;
  translateCommonResult: any;
  dataEvents: EventApiModels.EventDetails[] = [];
  isUpdateInfo = false;
  userChange: Subject<any> = new Subject<any>();
  defaultFanpageRoles: any[];
  fanpageTabView: any;
  translatedCategoryList: any;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private phoToEditService: NgxPhotoEditorService,
    private userProfileService: UserProfileService,
    private translateService: TranslationService
  ) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
    this.fanpageTabView = {
      Timeline: { value: 'Timeline', index: 0, basicViewIndex: 0 },
      Follower: { value: 'Follower', index: 1, basicViewIndex: 0 },
      Library: { value: 'Library', index: 2, basicViewIndex: 1 },
      Events: { value: 'Events', index: 3, basicViewIndex: 2 },
      // coin: { label: 'Coin', index: 4, basicViewIndex: 0 },
      About: { value: 'About', index: 4, basicViewIndex: 3 }
    };
    this.defaultFanpageRoles = [
      {
        label: 'ROLES.OWNER',
        description: 'MANAGE_ALL_ASPECTS',
        role: ROLES.OWNER,
        priority: 4
      },
      {
        label: 'ROLES.ADMIN',
        description: 'MANAGE_ALL_ASPECTS',
        role: ROLES.ADMIN,
        priority: 3
      },
      {
        label: 'ROLES.EDITOR',
        description: 'EDITOR_PERMISSION',
        role: ROLES.EDITOR,
        priority: 2
      },
      {
        label: 'ROLES.ADVERTISER',
        description: 'ADVERTISER_PERMISSION',
        role: ROLES.ADVERTISER,
        priority: 1
      },
      {
        label: 'ROLES.ANALYST',
        description: 'ANALYST_PERMISSION',
        role: ROLES.ANALYST,
        priority: 1
      }
    ];
  }

  addMediaExtension(filePath: string): string {
    const parts = filePath.split('.');
    return parts.length > 1 ? filePath : filePath + FILE_EXTENSION.image;
  }

  mergeTabToUrl(tab: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { Tab: tab },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  handleNavigateToFanpageTab(viewTab: ViewTabModel): void {
    this.fanpageRole$.subscribe(data => {
      this.activeIndex = data?.user_role === 'USER' || !data?.user_role ? viewTab.basicViewIndex : viewTab.index;
      this.tabViewIndex = this.activeIndex;
      this.mergeTabToUrl(viewTab.value);
    });
  }

  showCreatePostOption(role: string, status: string): boolean {
    if (role === ROLES.OWNER && status === PAGE_STATUS.PENDING_REVIEW) {
      return false;
    }
    return [ROLES.OWNER, ROLES.ADMIN, ROLES.EDITOR].includes(role);
  }

  onChangeFanpageCoverImage($event: any, fanpageId: string | null) {
    this.phoToEditService
      .open($event, {
        aspectRatio: ASPECT_RATIO.landscapeCover,
        autoCropArea: 1,
        hideModalHeader: true,
        viewMode: 1
      })
      .subscribe(data => {
        const formData = new FormData();
        formData.append('file', data.file as any);
        this.userProfileService
          .uploadGroupAvatarOrCover('cover', formData, fanpageId)
          .pipe(first())
          .subscribe(res => {
            const coverUrl = (res && res.data.uris.thumbnail) || '';
            if (fanpageId) {
              const param = {
                icon_cover_image: coverUrl
              };
              this.api.page.updatePageCoverImage(param, fanpageId).subscribe(res => {
                if (res.success) {
                  this.messageService.add({
                    severity: 'success',
                    summary: this.translateCommonResult.SUCCESS,
                    detail: this.translateService.getTranslation('FANPAGE.UPLOAD_COVER_IMAGE_SUCCESSFULLY')
                  });
                  this.store.dispatch(getFanpageInfo({ fanpageId: fanpageId }));
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateCommonResult.ERROR,
                    detail: this.translateService.getTranslation('ERROR.FANPAGE.UPLOAD_COVER_IMAGE_FAILED')
                  });
                }
              });
            }
          });
      });
  }

  onChangeFanpageAvatar($event: any, fanpageId: string | null) {
    this.phoToEditService
      .open($event, {
        aspectRatio: 3 / 3,
        autoCropArea: 1,
        hideModalHeader: true,
        viewMode: 1
      })
      .subscribe(data => {
        const formData = new FormData();
        formData.append('file', data.file as any);
        this.userProfileService
          .uploadGroupAvatarOrCover('avatar', formData, fanpageId)
          .pipe(first())
          .subscribe(res => {
            const avatarUrl = (res && res.data.uris.thumbnail) || '';
            const param = {
              icon_cover_image: avatarUrl
            };
            this.api.page.updatePageAvatar(param, fanpageId).subscribe(res => {
              if (res.success) {
                this.messageService.add({
                  severity: 'success',
                  summary: this.translateCommonResult.SUCCESS,
                  detail: this.translateService.getTranslation('FANPAGE.UPLOAD_AVATAR_SUCCESSFULLY')
                });
                this.store.dispatch(getFanpageInfo({ fanpageId: fanpageId }));
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translateCommonResult.ERROR,
                  detail: this.translateService.getTranslation('ERROR.FANPAGE.UPLOAD_AVATAR_FAILED')
                });
              }
            });
          });
      });
  }

  createFanpagePost(post: PageAPI.PageCreatePost): Observable<PageAPI.PageCreatePostResponse> {
    return this.api.page.createFanpagePost(post);
  }

  getFollowingFanPages(
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    textSearch?: string | null
  ): Observable<PageAPI.MyPageResponse> {
    return this.api.page.getFollowingFanpage(pageNum, pageSize, textSearch);
  }

  getMyFanpage(
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    textSearch = ''
  ): Observable<PageAPI.MyPageResponse> {
    return this.api.page.getMyFanpage(pageNum, pageSize, textSearch);
  }

  getFanpagesWithAdmins(pageSize = PAGE_SIZE_DEFAULT, pageNum = PAGE_NUM_DEFAULT): Observable<PageAPI.MyPageResponse> {
    return this.api.page.getFanpagesWithAdmins(pageSize, pageNum);
  }

  getFanpagesWithNoAdmins(
    pageSize = PAGE_SIZE_DEFAULT,
    pageNum = PAGE_NUM_DEFAULT
  ): Observable<PageAPI.MyPageResponse> {
    return this.api.page.getFanpagesWithNoAdmins(pageSize, pageNum);
  }

  getFanPagesInterestSuggestions(
    pageNum: number,
    pageSize: number,
    textSearch?: string | null
  ): Observable<PageAPI.MyPageResponse> {
    return this.api.page.getFanpageSuggestion(pageNum, pageSize, textSearch);
  }

  getFanpageRole(fanPageId: string | null): Observable<PageAPI.PageRoleResponse> {
    return this.api.page.getFanpageRole(fanPageId);
  }

  getFanpageOverview(fanPageId: string): Observable<PageAPI.PageOverviewResponse> {
    return this.api.page.getFanpageOverview(fanPageId);
  }

  updateFanpageOverview(fanPageId: string, data: PageAPI.PageOverview): Observable<PageAPI.PageOverviewResponse> {
    return this.api.page.updateFanpageOverview(fanPageId, data);
  }

  getFanpageContactInfo(fanPageId: string): Observable<PageAPI.PageContactInfoResponse> {
    return this.api.page.getFanpageContactInfo(fanPageId);
  }

  updateFanpageContactInfo(
    fanPageId: string,
    data: PageAPI.PageContactInfo
  ): Observable<PageAPI.PageContactInfoResponse> {
    return this.api.page.updateFanpageContactInfo(fanPageId, data);
  }

  // will provide an update once the event service has been refactored.
  getEventDetail = (eventDetailId: string) =>
    this.httpClient.get<any>(this.apiUrl + FAN_PAGES_API_ENDPOINTS.GET_EVENTS_DETAIL + eventDetailId);

  getFanpageInfo(fanpageId: string | null): Observable<PageAPI.PageInfoResponse> {
    return this.api.page.getFanpageInfo(fanpageId);
  }

  getSwitchIntoPageRole(fanpageId: string | null): Observable<PageAPI.PageInfoResponse> {
    return this.api.page.switchIntoPageRole(fanpageId);
  }

  getFollowerRoles(fanpageId: string | null): Observable<PageAPI.PageMemberInfoResponse> {
    return this.api.page.getFollowerRoles(fanpageId);
  }

  updateFollowerRole(fanpageId: string | null, data: PageAPI.UpdateUserRole): Observable<PageAPI.UpdateUserResponse> {
    return this.api.page.promoteUser(fanpageId, data);
  }

  getFanpageCategory(pageNum = 0, pageSize = 10, textSearch?: string | null): Observable<PageAPI.PageCategoryResponse> {
    return this.api.page.getFanpageCategory(pageNum, pageSize, textSearch);
  }

  deleteFanpage(fanpageId: string | null, body: PageAPI.PageStatus): Observable<PageAPI.PageResponse> {
    return this.api.page.deletePage(fanpageId, body);
  }

  updateFanpageStatus(fanpageId: string | null, data: PageAPI.PageStatus): Observable<PageAPI.PageResponse> {
    return this.api.page.updateFanpageStatus(fanpageId, data);
  }

  removeRole(fanpageId: string | null, data: PageAPI.UpdateUserRole): Observable<PageAPI.UpdateUserResponse> {
    return this.api.page.removeUserRole(fanpageId, data);
  }

  searchPostFanpageReview(fanpagepId: string, textSearch: string): Observable<PageAPI.PagePostResponse> {
    return this.api.page.searchPendingPosts(fanpagepId, textSearch);
  }

  approveFanpagePendingPost(fanpagepId: string, postIdsList: string[]): Observable<PageAPI.PagePostResponse> {
    return this.api.page.approvePendingPost(fanpagepId, postIdsList);
  }

  declineFanpagePendingPost(fanpagepId: string, postIdsList: string[]): Observable<PageAPI.PagePostResponse> {
    return this.api.page.declinePendingPost(fanpagepId, postIdsList);
  }

  getFanpageStatsInsights(
    fanpageId: string,
    startTime: number,
    endTime: number
  ): Observable<StatsApiModels.FanpageStatsInsights[]> {
    return this.api.statistics.getFanpageStatsInsights(fanpageId, startTime, endTime).pipe(
      map((res: any) => {
        if (!res.success) {
          return res;
        }
        return res.data;
      })
    );
  }

  getFanpageTopPerfPost(fanpageId: string): Observable<TopPerfPost> {
    return this.api.post.getFanpageTopPerfPost(fanpageId).pipe(
      map((res: TopPerfPostResponse) => {
        const nullData = {} as TopPerfPost;
        return res.data ? res.data : nullData;
      })
    );
  }

  getFanpagePostList(
    fanpageId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListPaging> {
    return this.api.post.getFanpagePostList(fanpageId, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  getFanpageStatsPostList(
    fanpageId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListPaging> {
    return this.api.post.getFanpageStatsPostList(fanpageId, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  getFanpagePendingPosts(
    fanpageId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListPaging> {
    return this.api.post.getFanpagePendingPosts(fanpageId, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  searchFanpagePostList(
    fanpageId: string,
    status: string[],
    searchKey: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.asc
  ): Observable<PostListPaging> {
    return this.api.post.searchFanpagePostList(fanpageId, status, searchKey, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  getFanpageDetailPost(postId: string): Observable<DetailPost> {
    return this.api.post.getFanpageDetailPost(postId).pipe(
      map((res: DetailPost) => {
        return res;
      })
    );
  }

  getFanpageSearchDetailPost(
    fanpageId: string,
    params: ParamsSearch,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc,
    status = GROUP_OF_POST_STATUSES.timeline
  ): Observable<PostListPaging> {
    return this.api.post.getFanpageSearchDetailPost(fanpageId, params, pageSize, pageNum, sortByDate, status).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  transferFanpageOwnership(fanpageId: string, userId: string, newRole: string): Observable<boolean> {
    return this.api.page.transferFanpageOwnership(fanpageId, userId, newRole).pipe(
      map((res: PageAPI.TransferOwnershipResponse) => {
        return res.success;
      })
    );
  }

  cancelTransferOwnershipRequest(fanpageId: string): Observable<boolean> {
    return this.api.page.cancelTransferOwnershipRequest(fanpageId).pipe(
      map((res: PageAPI.TransferOwnershipResponse) => {
        return res.success;
      })
    );
  }

  acceptTransferOwnershipRequest(fanpageId: string): Observable<boolean> {
    return this.api.page.acceptTransferOwnershipRequest(fanpageId).pipe(
      map((res: PageAPI.TransferOwnershipResponse) => {
        return res.success;
      })
    );
  }

  declineTransferOwnershipRequest(fanpageId: string): Observable<boolean> {
    return this.api.page.declineTransferOwnershipRequest(fanpageId).pipe(
      map((res: PageAPI.TransferOwnershipResponse) => {
        return res.success;
      })
    );
  }

  getFanpageRoleInvitations(): Observable<PageAPI.FanpageRoleInvitation[]> {
    return this.api.page.getFanpageRoleInvitations().pipe(
      map((res: PageAPI.FanpageRoleInvitationResponse) => {
        return res && res.success && res.data ? res.data : [];
      })
    );
  }

  autoApprovePost(fanpageId: string, isEnable: boolean): Observable<any> {
    return this.api.page.autoApprovePost(fanpageId, isEnable).pipe(
      map((res: PageAPI.AutoApprovePostState) => {
        return res;
      })
    );
  }

  getAllCategories(): Observable<PageAPI.PageCategoryResponse> {
    return this.api.page.getAllCategories();
  }

  getTranslatedCategories(): void {
    this.api.page.getAllCategories().subscribe(res => {
      res.data.data.forEach(category => {
        const categoryName = CATEGORY_LIST.get(category.id);
        category.category_name = categoryName
          ? this.translateService.getTranslation(`CATEGORIES.${categoryName}`)
          : category.category_name;
      });
      this.translatedCategoryList = res.data.data;
    });
  }

  createRange(number: number): number[] {
    return new Array(number);
  }
}
