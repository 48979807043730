import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, map, retry } from 'rxjs';
import { FeedsEngine } from './api.feed-engine.models';

export class FeedsEngineApi {
  private apiUrl: string = environment.baseURL;
  constructor(public readonly httpClient: HttpClient) {}

  getFeedsEngine(pageNum?: number, pageSize?: number, isVideo?: boolean): Observable<FeedsEngine[]> {
    const video = isVideo ? `&isVideo=${isVideo}` : ''
    return this.httpClient
      .get<FeedsEngine[]>(this.apiUrl + `/feeds-engine/feeds-engine/me?pageNum=${pageNum}&pageSize=${pageSize}${video}`)
      .pipe(
        retry(1),
        map((res: any) => {
          return res.data;
        })
      );
  }

  postInteractionHub(params: any) {
    return this.httpClient.post<any>(this.apiUrl + `/interaction-hub/interaction`, params).pipe(
      retry(1),
      map((res: any) => {
        return res;
      })
    );
  }

  adsEnginePanel(pageNum: number, size: number, isPanel: boolean) {
    const endpoint = isPanel ? '/ads/panel1' : '/ads/panel2';
    return this.httpClient.get<any>(`${this.apiUrl}${endpoint}?pageNum=${pageNum}&size=${size}`).pipe(
      retry(1),
      map((res: any) => res.data)
    );
  }

  adsEngineSponsored() {
    return this.httpClient.get<any>(`${this.apiUrl}/ads/sponsored`).pipe(
      retry(1),
      map((res: any) => res.data)
    );
  }
}
