<div
  class="bg-[url('/assets/images/bg-sendcoin.png')] bg-cover bg-center bg-no-repeat shadow-sm h-full md:min-h-[280px] rounded-lg max-h-[30rem] overflow-hidden">
  <div class="pt-6 px-6 pb-2 max-md:px-4">
    <div class="flex mb-2 flex-col md:flex-row md:items-center md:justify-between h-8">
      <span class="max-md:mt-3 float-left md:text-center text-sm font-semibold text-palette-gray-700 order-1">
        {{ 'COIN.SUGGESTION' | translate }}
      </span>
      <div class="float-right relative md:order-1">
        <div class="absolute inset-y-0 left-0 flex items-center pl-2">
          <i class="sctr-icon-search-lg text-xls text-palette-gray-400"></i>
        </div>
        <input
          [(ngModel)]="searchText"
          (input)="onSearchFriends($event)"
          class="w-full md:w-[320px] px-8 text-sm h-8 text-palette-gray-900 border border-palette-gray-300 rounded-md bg-palette-gray-50 focus:outline-none focus:border-black"
          placeholder="{{ 'COIN.SEARCH' | translate }}" />
      </div>
    </div>
    <div
      class="max-md:mt-10 gap-x-2 text-center self-center justify-center items-center pb-4 md:grid md:grid-cols-5 gap-2 md:flex-wrap h-full overscroll-contain overflow-x-hidden"
      (scroll)="onScroll($event)"
      [ngClass]="sentCoinList.content.length >= friendListLength ? (showMoreStatus ? 'md:max-h-[26rem] ' : 'md:max-h-[12.5rem] overflow-hidden') : ''"
      *ngIf="sentCoinList.content.lenght !== 0">
      <div *ngFor="let item of sentCoinList.content; let index = index">
        <people-card
          (actionsSentCoinEmit)="sendCoin($event)"
          [customData]="item"
          [type]="CURRENT_SCREEN.PROFILE_TIMELINE">
        </people-card>
      </div>
    </div>
    <div class="flex gap-[7px]" *ngIf="(loadingFriend$|async)">
      <loading-people-card [profileCoin]="true" *ngFor="let item of [].constructor(loadingSkeletons)">
      </loading-people-card>
    </div>
    <personal-profile-no-friends
      class="flex justify-center items-center md:h-[207px]"
      *ngIf="sentCoinList && sentCoinList?.content?.length === 0 && !(loadingFriend$ | async)">
    </personal-profile-no-friends>
  </div>
</div>
<div
  *ngIf="sentCoinList.content.length >= friendListLength"
  class="p-button-text text-palette-blue-700 mt-2 cursor-pointer"
  (click)="handleViewMoreUserSentCoin()">
  {{ (showMoreStatus ? 'COMMON.SHOW_LESS' : 'COMMON.SHOW_MORE') | translate }}
</div>
<coin-sending [isSendToFriends]="true" [(visible)]="visiblePopup" [userToSendCoin]="coinReceiver" [currCoin]="currCoin">
</coin-sending>
