import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { SearchAPI } from '@app/lib/api/search/api.search.model';
import { SearchFanpages, SearchGroups, SearchPost, SearchUsers } from '@app/modules/main/states/search/search.state';
import { SearchBagdes, SearchTours } from '@app/shared/models/search.model';
import { environment } from '@env/environment';
import { Observable, map, retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  baseUrl = environment.baseURL;

  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getGlobalSearch(searchText: string): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchGlobalResponse>> {
    return this.api.search.getGlobalSearch(searchText).pipe(
      map(response => {
        return response;
      })
    );
  }

  getSuggestSearch(searchText: string): Observable<
    SearchAPI.SingleDataResponse<{
      result: SearchAPI.SearchSuggestionResult[];
    }>
  > {
    return this.api.search.getSuggestSearch(searchText).pipe(
      map(response => {
        return response;
      })
    );
  }

  getSearchPlaces(searchText: string): Observable<SearchAPI.MultipleDataResponse<SearchBagdes>> {
    return this.api.search.getSearchPlaces(searchText);
  }

  getHistorySearch(paging_state = ''): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchHistoryResponse>> {
    return this.api.search.getSearchHistory(paging_state).pipe(
      map(response => {
        return response;
      })
    );
  }

  createSearchHistory(
    data: SearchAPI.RequestSearchHistory
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.ItemSearchHistory>> {
    return this.api.search.createSearchHistory(data).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteSearchHistory(
    data: SearchAPI.RequestDeleteSearchHistory
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.ItemSearchHistory>> {
    return this.api.search.deleteSearchHistory(data).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteAllSearchHistory(): Observable<SearchAPI.SingleDataResponse<{}>> {
    return this.api.search.deleteAllSearchHistory().pipe(
      map(response => {
        return response;
      })
    );
  }

  getSearchTours(searchText: string): Observable<SearchAPI.MultipleDataResponse<SearchTours>> {
    return this.api.search.getSearchTours(searchText);
  }

  getSearchUsers(searchText: string, pageNumber: number): Observable<SearchAPI.SingleDataResponse<SearchUsers>> {
    return this.api.search.getSearchUsers(searchText, pageNumber).pipe(
      retry(1),
      map(res => {
        const newResponse = {
          ...res,
          data: {
            totalPages: this.handleGetTotalPages(res.data.total_element, res.data.page_size),
            userList: res.data?.data,
            currentPage: res.data?.page
          }
        };
        return newResponse;
      })
    );
  }

  getSearchPost(searchText: string, pageNumber: number): Observable<SearchAPI.SingleDataResponse<SearchPost>> {
    return this.api.search.getSearchPost(searchText, pageNumber).pipe(
      retry(1),
      map(res => {
        const newResponse = {
          ...res,
          data: {
            totalPages: this.handleGetTotalPages(res.data.total_element, res.data.page_size),
            postList: res.data?.data,
            currentPage: res.data?.page
          }
        };
        return newResponse;
      })
    );
  }

  getSearchGroup(searchText: string, pageNumber: number): Observable<SearchAPI.SingleDataResponse<SearchGroups>> {
    return this.api.search.getSearchGroup(searchText, pageNumber).pipe(
      retry(1),
      map(res => {
        const newResponse = {
          ...res,
          data: {
            totalPages: this.handleGetTotalPages(res.data.total_element, res.data.page_size),
            groupList: res.data?.data,
            currentPage: res.data?.page
          }
        };
        return newResponse;
      })
    );
  }

  getSearchFanpage(searchText: string, pageNumber: number): Observable<SearchAPI.SingleDataResponse<SearchFanpages>> {
    return this.api.search.getSearchFanpage(searchText, pageNumber).pipe(
      retry(1),
      map(res => {
        const newResponse = {
          ...res,
          data: {
            totalPages: this.handleGetTotalPages(res.data.total_element, res.data.page_size),
            fanpageList: res.data?.data,
            currentPage: res.data?.page
          }
        };
        return newResponse;
      })
    );
  }

  handleGetTotalPages(total_element: number, page_size: number) {
    return total_element / page_size <= 1 ? 0 : Math.ceil(total_element / page_size) - 1;
  }
}
