import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Output() datePicker = new EventEmitter();
  @Input() minStartDate: Date;
  @Input() selectedDate: Date | null;
  date: Date; // Ngày tháng năm được chọn
  inputDate: string = ''; // Ngày tháng năm nhập trong ô input

  ngOnInit(): void {
    if (this.selectedDate) {
      this.date = this.selectedDate;
      this.inputDate = '';
      this.datePicker.emit(this.selectedDate);
    }
  }

  onDateSelect(event: any) {
    this.date = event;
    this.datePicker.emit(this.date);
  }

  selectDate() {
    const inputDateParts = this.inputDate.split('/');
    if (inputDateParts.length === 3) {
      const day = +inputDateParts[0];
      const month = +inputDateParts[1] - 1;
      const year = +inputDateParts[2];
      const selectedDate = new Date(year, month, day);

      if (!isNaN(selectedDate.getTime())) {
        this.date = selectedDate;
      }
    }
  }

  selectToday() {
    this.date = new Date();
    this.inputDate = '';
    this.datePicker.emit(this.date);
  }
}
