<div class="flex">
  <ng-container *ngIf="loading && onLoadingData(customData)">
    <loading-people-card></loading-people-card>
  </ng-container>
  <div
    class="max-md:items-center bg-palette-base-white p-3 max-md:mb-3 shadow-sm rounded-lg"
    [ngClass]="
      type !== CURRENT_SCREEN.NEWS_FEED
        ? 'w-full md:w-[133px] max-md:justify-between max-md:flex'
        : 'flex flex-col w-[160px] items-center border rounded-lg border-palette-gray-100 relative'
    ">
    <button
      *ngIf="type === CURRENT_SCREEN.NEWS_FEED"
      class="absolute top-1 right-1 rounded-full p-1 leading-4 hover:bg-palette-gray-300 btn-close"
      (click)="type === CURRENT_SCREEN.NEWS_FEED ? onEmitNewsFeedAction(customData, '') : ''">
      <i class="sctr-icon-x-close leading-4"></i>
    </button>
    <div
      class="justify-center"
      [ngClass]="type === CURRENT_SCREEN.NEWS_FEED ? 'flex flex-col' : 'max-md:flex max-md:items-center'">
      <div class="flex justify-center items-center md:pb-4">
        <a [routerLink]="navigateToProfile(customData)">
          <img
            class="rounded-full md:border-[3px] md:border-white md:shadow-md"
            [ngClass]="
              type === CURRENT_SCREEN.NEWS_FEED ? 'w-[100px] h-[100px]' : 'w-[50px] h-[50px] md:w-[100px] md:h-[100px]'
            "
            onerror="this.src='assets/images/default_user_avatar.png'"
            [src]="getAvatarUrl(customData.avatar_thumbnail_url!)"
            alt="Image" />
        </a>
      </div>
      <div class="flex flex-col max-md:items-start max-md:ml-3">
        <a [routerLink]="navigateToProfile(customData)">
          <div
            *ngIf="customData?.full_name"
            class="text-center text-base font-semibold overflow-hidden truncate text-palette-gray-900"
            [ngClass]="[type === CURRENT_SCREEN.NEWS_FEED ? 'max-w-[142px]' : 'md:pb-4']">
            {{ customData.full_name }}
          </div>
        </a>
        <p
          *ngIf="type === CURRENT_SCREEN.NEWS_FEED"
          class="text-xs leading-[18px] font-normal text-center pb-4 cursor-default">
          <span>{{ customData.number_of_mutual_friend }}</span>
          {{
            customData.number_of_mutual_friend! > 1
              ? ('COMMON.MUTUAL_FRIENDS' | translate)
              : ('COMMON.MUTUAL_FRIEND' | translate)
          }}
        </p>
      </div>
    </div>
    <ng-container
      [ngTemplateOutlet]="type === CURRENT_SCREEN.NEWS_FEED ? newsFeedAction : sentCoinAction"></ng-container>
  </div>
</div>

<ng-template #newsFeedAction>
  <ng-container *ngFor="let button of friendButton">
    <button
      *ngIf="customData.relationship_status?.includes(button.relationshipStatus)"
      [ngClass]="
        customData.loadingStatus ? 'bg-palette-base-white border-palette-gray-300 text-palette-gray-500' : button.color
      "
      class="h-[26px] font-bold text-xs leading-[18px] rounded-md whitespace-nowrap px-2 flex justify-center items-center"
      [disabled]="customData.loadingStatus"
      (click)="onEmitNewsFeedAction(customData, button.relationshipStatus)">
      <span *ngIf="customData.loadingStatus" class="pi-spin pi sctr-icon-loading-02 mr-1"></span>
      {{ button.text | translate }}
    </button>
  </ng-container>
</ng-template>
<ng-template #sentCoinAction>
  <button
    class="bg-palette-blue-50 text-palette-blue-700 h-[26px] font-bold text-xs leading-[18px] rounded-md whitespace-nowrap px-2"
    [ngClass]="[type === CURRENT_SCREEN.NEWS_FEED ? 'mt-4' : '']"
    (click)="onEmitSentCoinAction(customData, true)">
    {{ (type === CURRENT_SCREEN.NEWS_FEED ? 'COMMON.ADD_FRIEND' : 'COIN.SEND_NOW') | translate }}
  </button>
</ng-template>
