import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { FAN_PAGES_API_ENDPOINTS } from '../page/api.page.constants';
import { FollowerApiModels } from './api.follower.model';

export class FollowerApi {
  private apiUrl: string = environment.baseURL;
  followerPath = '/follower/followers';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  followFanpage(typeService: string, typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.http
      .put<FollowerApiModels.FanpageResponse>(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeService}/${typeId}`,
        {}
      )
      .pipe(
        retry(1),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  unblockFollower(
    typeService: string,
    typeId: string | null,
    data: any
  ): Observable<FollowerApiModels.FanpageResponse> {
    const param = { list_user_id: data };
    const endpoint = `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}/${typeService}/${typeId}${FAN_PAGES_API_ENDPOINTS.PUT_UNBLOCK_FOLLOWERS}`;
    return this.http.put(endpoint, param).pipe(
      map((res: any) => res),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateStatus(
    typeService: string,
    typeId: string | null,
    typeStatus: any
  ): Observable<FollowerApiModels.FanpageResponse> {
    const param = { type_status: typeStatus };
    const endpoint = `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}/${typeService}/${typeId}/status`;
    return this.http.put<FollowerApiModels.FanpageResponse>(endpoint, param).pipe(
      retry(1),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  blockFollower(
    typeService: string,
    typeId: string | null,
    userId: string | null
  ): Observable<FollowerApiModels.FanpageResponse> {
    return this.http
      .put(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeService}/${typeId}${FAN_PAGES_API_ENDPOINTS.PUT_BLOCK_FOLLOWERS}/${userId}`,
        {}
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  removeFollower(typeService: string, typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.http
      .delete(`${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeService}/${typeId}/remove-follower`)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  declineInvation(typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.http
      .put(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeId}${FAN_PAGES_API_ENDPOINTS.PUT_DECLINE_INVITED}`,
        {}
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  acceptInvation(typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.http
      .put(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeId}${FAN_PAGES_API_ENDPOINTS.PUT_ACCEPT_INVITED}`,
        {}
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  inviteFriendsToFanpage(typeId: string | null, listUserId: any) {
    const param = { list_user_id: listUserId };
    return this.http.post(`${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeId}/invite-friends`, param).pipe(
      map((res: any) => res),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getInviteFriendList(
    typeId: string | null,
    searchKeyword: string,
    page = 0
  ): Observable<FollowerApiModels.ListFriendsResponse> {
    const params = `text=${encodeURI(searchKeyword)}&page=${page}`;
    return this.http
      .get(`${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeId}/invite-friends-list?${params}`)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  unFollowFanpage(typeService: string, typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.http
      .delete<FollowerApiModels.FanpageResponse>(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeService}/${typeId}/unfollow`
      )
      .pipe(
        retry(1),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFollowersList(
    typeService: string,
    typeId: string | null,
    pageNum: number,
    textSearch: string,
    pageSize: number,
    userStatus: string
  ) {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('textSearch', textSearch ?? '')
      .set('typeService', typeService)
      .set('userStatus', userStatus);

    return this.http
      .get(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeId}${FAN_PAGES_API_ENDPOINTS.GET_SEARCH_FOLLOWERS}`,
        { params }
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFollowingList(pageNum = 0, pageSize = 10, typeService: string, sort?: string | null) {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('typeService', typeService)
      .set('sort', sort ?? '');

    return this.http.get(`${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWING}`, { params }).pipe(
      map((res: any) => res),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getBlockList(
    typeService: string,
    typeId: string | null,
    textSearch?: string | null,
    pageNum = 0,
    pageSize = 10
  ): Observable<any> {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('textSearch', textSearch ?? '')
      .set('typeService', typeService)
      .set('userStatus', 'BLOCK');

    return this.http
      .get(
        `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}${typeId}${FAN_PAGES_API_ENDPOINTS.GET_SEARCH_BLOCKERS}`,
        { params }
      )
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCheckFollowing(userProfileId: string, userId: string): Observable<any> {
    const baseUrl = `${this.apiUrl}${FAN_PAGES_API_ENDPOINTS.GET_FOLLOWER}/member/follower/${userProfileId}+${userId}+user`;
    return this.http.get(baseUrl).pipe(
      map((res: any) => res),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFanpageAgeAndGenderStats(fanpageId: string): Observable<FollowerApiModels.AgeAndGenderStatsResponse> {
    return this.http
      .get<FollowerApiModels.AgeAndGenderStatsResponse>(`${this.apiUrl}${this.followerPath}/${fanpageId}/stats`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
