<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [style]="{ 'width': '440px' }"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="onCloseDialog()"
  (onShow)="onShowDialog()"
  class="custom-scroll"
  styleClass="border border-palette-gray-200 rounded-xl bg-white invite-friends-dialog"
  contentStyleClass="border-0 pl-6 pr-1 py-0 overflow-visible">
  <ng-template pTemplate="header" class="rounded-t-xl">
    <span class="text-lg text-palette-gray-900 font-bold">{{ title | translate }}</span>
  </ng-template>
  <div class="flex flex-col gap-4 relative">
    <span class="p-input-icon-left h-10 w-full pr-5">
      <i class="sctr-icon-search-lg text-lg text-palette-gray-500 leading-none"></i>
      <input
        type="text"
        pInputText
        [(ngModel)]="searchKeyword"
        placeholder="{{ 'INVITE_FRIENDS.SEARCH' | translate }}"
        class="w-full py-2 pr-3.5 rounded-lg border border-palette-gray-300 text-palette-gray-500 focus:ring-0 focus:border-palette-gray-300"
        #searchFriends
        (keyup)="onSearch(searchFriends.value)" />
    </span>
    <div class="flex flex-col gap-4">
      <div class="flex flex-col w-full gap-3 checkbox-invite">
        <div class="flex gap-2 items-center pr-5">
          <p-checkbox
            [binary]="true"
            checkboxIcon="sctr-icon-minus text-xs font-bold text-branding-primary-600"
            [(ngModel)]="multipleChanges"
            (click)="onMultipleChanges()"></p-checkbox>
          <span class="text-palette-gray-500"
            >{{ 'INVITE_FRIENDS.SELECTED' | translate }} ({{ selectedFriends.length }})</span
          >
        </div>
        <ng-container *ngTemplateOutlet="selectedFriendItems"></ng-container>
      </div>
      <div class="pr-5">
        <hr class="bg-palette-gray-200" />
      </div>
      <div class="flex flex-col gap-3">
        <span class="text-sm text-palette-gray-700 font-bold">{{ 'INVITE_FRIENDS.SUGGESTIONS' | translate }}</span>
        <ng-container *ngIf="isLoading; else showFriendList">
          <div class="flex flex-col gap-2">
            <ng-container [ngTemplateOutlet]="loadingItem" [ngTemplateOutletContext]="{ widthSkeleton: '8rem' }">
            </ng-container>
            <ng-container [ngTemplateOutlet]="loadingItem" [ngTemplateOutletContext]="{ widthSkeleton: '14rem' }">
            </ng-container>
            <ng-container [ngTemplateOutlet]="loadingItem" [ngTemplateOutletContext]="{ widthSkeleton: '10rem' }">
            </ng-container>
          </div>
        </ng-container>
        <ng-template #showFriendList>
          <ng-container *ngIf="friendList">
            <div
              *ngIf="friendList.length; else noAvailableFriends"
              class="max-h-56 overflow-y-scroll pr-3"
              scroll
              (scrolledToBottom)="onScrolledToBottom()">
              <ng-container *ngTemplateOutlet="friendItems"></ng-container>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-3 items-center justify-end">
      <button
        [disabled]="invitingFriends"
        class="cta-button border-palette-gray-300 bg-white hover:bg-palette-gray-200 text-palette-gray-700 disabled:bg-white disabled:text-palette-gray-300"
        type="submit"
        (click)="onCloseDialog()">
        {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
      </button>
      <button
        (click)="onSendInviteFriend()"
        class="cta-button text-white bg-branding-primary-600 border-branding-primary-600 hover:bg-branding-primary-700 disabled:bg-branding-primary-200 disabled:border-branding-primary-200"
        [disabled]="!selectedFriends.length || invitingFriends">
        <i *ngIf="invitingFriends" class="sctr-icon-loading-02 animate-spin text-xl/5"></i>
        {{ 'INVITE_FRIENDS.SEND_INVITES' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<ng-template #loadingItem let-widthSkeleton="widthSkeleton">
  <div class="flex gap-2 items-center">
    <p-skeleton shape="circle" size="32px"></p-skeleton>
    <p-skeleton [width]="widthSkeleton ? widthSkeleton : '12rem'" height="1rem"></p-skeleton>
  </div>
</ng-template>

<ng-template #selectedFriendItems>
  <div *ngIf="selectedFriends.length" class="overflow-y-scroll max-h-20">
    <div class="flex gap-2 flex-wrap pr-3">
      <p-chip
        *ngFor="let friend of selectedFriends"
        [title]="friend.full_name"
        [label]="utilityService.shortenName(friend.first_name || friend.full_name, 18)"
        [image]="getImgUrl(friend.avatar_thumbnail_url)"
        [removable]="true"
        styleClass="rounded-md h-7 border border-palette-gray-300 text-sm px-0 text-palette-gray-700"
        removeIcon="sctr-icon-x-close mr-2 text-palette-gray-400"
        (click)="onRemoveSeletedFriend(friend)">
      </p-chip>
    </div>
  </div>
</ng-template>

<ng-template #friendItems>
  <div class="flex flex-col gap-2">
    <ng-container *ngFor="let friend of friendList; let i = index">
      <div class="flex justify-between items-center gap-2 checkbox-invite">
        <div class="flex gap-2 items-center">
          <img [src]="getImgUrl(friend.avatar_thumbnail_url)" alt="" class="h-8 w-8 rounded-full" />
          <span class="text-sm text-palette-gray-700 line-clamp-1" title="{{ friend.full_name }}">{{
            friend.full_name
          }}</span>
        </div>
        <p-checkbox
          [binary]="true"
          [(ngModel)]="friendCheckboxes[i]"
          checkboxIcon="hover:sctr-icon-minus sctr-icon-check text-xs font-bold text-branding-primary-600"
          (onChange)="onSelectedFriend(friend, i)"></p-checkbox>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadingMore" [ngTemplateOutlet]="loadingItem"> </ng-container>
  </div>
</ng-template>

<ng-template #noAvailableFriends>
  <div class="w-full pr-5 flex flex-col justify-center items-center gap-2">
    <div
      class="h-14 w-14 bg-palette-gray-100 border-8 border-palette-gray-50 rounded-full flex items-center justify-center">
      <i class="sctr-icon-users-01 text-2xl font-medium text-palette-gray-600"></i>
    </div>
    <span>{{ 'FRIEND.NO_FRIENDS' | translate }}</span>
  </div>
</ng-template>
