import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { LoginService } from '@app/core/services/login.service';
import { OTP_EXPIRATION_TIME } from '@app/shared/constant';

@Component({
  selector: 'otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
  @Input() isAuthDialogStyle = false;
  @Input() isVerifyCodeFailed = false;
  @Output() isVerifyCodeFailedChange = new EventEmitter();
  @Output() verifyCodeEvent = new EventEmitter();
  @Output() OTPCode = new EventEmitter();
  @Output() resendCode = new EventEmitter();
  countdown = OTP_EXPIRATION_TIME;
  digits = ['', '', '', '', '', ''];
  OTPDigits = ['', '', '', '', '', ''];
  @ViewChildren('OTPDigit') inputDigitEls: QueryList<ElementRef<HTMLInputElement>>;

  constructor(public readonly loginService: LoginService) {}

  ngOnInit(): void {
    this.startCountDown();
    this.digits = ['', '', '', '', '', ''];
    this.OTPDigits = ['', '', '', '', '', ''];
  }

  startCountDown(): void {
    this.loginService.startCountDown(OTP_EXPIRATION_TIME).subscribe(value => {
      this.countdown = value;
    });
  }

  onResendCode(): void {
    this.startCountDown();
    this.resendCode.emit(true);
  }

  onInputChange(event: any, index: number): void {
    const ev = event as KeyboardEvent;
    const digitInput = this.inputDigitEls.get(index)?.nativeElement;
    const keyCode = ev.key;
    if (digitInput) {
      if (ev.metaKey && keyCode === 'v') {
        return;
      }

      if (['Backspace', 'Tab'].indexOf(keyCode) >= 0) {
        // backspace should delete the current value
        if (keyCode === 'Backspace' && !digitInput.value) {
          const curEl = this.inputDigitEls.get(index)?.nativeElement;
          if (curEl && this.OTPDigits[index]) {
            curEl.value = '';
            this.OTPDigits[index] = '';
            this.emitCode();
          } else {
            if (index > 0) {
              const prevEl = this.inputDigitEls.get(index - 1)?.nativeElement;
              if (prevEl) {
                prevEl.value = '';
                this.OTPDigits[index - 1] = '';
                this.emitCode();
                prevEl?.focus();
              }
            }
          }
        }
        return;
      }
      event.preventDefault();

      if (Number.isNaN(Number(keyCode))) {
        const curEl = this.inputDigitEls.get(index)?.nativeElement;
        if (curEl && keyCode.length === 1) {
          curEl.value = '';
          this.OTPDigits[index] = '';
          this.emitCode();
        }
        return;
      }

      digitInput.value = keyCode;
      this.OTPDigits[index] = digitInput.value;
      this.emitCode();
      this.inputDigitEls.get(index + 1)?.nativeElement.focus();
    }
  }

  onPaste(event: ClipboardEvent): void {
    const numbers = (event.clipboardData?.getData('text') ?? '').slice(0, this.digits.length);
    if (/\d{6}/.test(numbers)) {
      this.digits = numbers.split('');
      this.OTPDigits = this.digits;
      this.emitCode();
    } else {
      event.preventDefault();
    }
  }

  resetError(): void {
    if (this.isVerifyCodeFailed) {
      this.isVerifyCodeFailedChange.emit(false);
    }
  }

  emitCode(): void {
    this.OTPCode.emit(this.OTPDigits.join(''));
    this.resetError();
  }
}
