import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '@app/core/services/translation.service';
import { PostListPaging } from '@app/lib/api/post/api.post.model';
import {
  PAGE_SIZE_OPTIONS,
  POST_REVIEW_ACTIONS,
  POST_REVIEW_CONTEXTS,
  SEARCH_DEBOUNCE_TIME,
  TIMELINE_OBJECTS
} from '@app/shared/constant';
import { Post } from '@app/shared/models/post';
import { ConfirmationService } from 'primeng/api';
import { Subject, debounceTime } from 'rxjs';
import { Paging } from '../paginator/paginator.component';

interface PostReview {
  postId: string;
  isSelected: boolean;
}

@Component({
  selector: 'review-pending-posts',
  templateUrl: './review-pending-posts.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./review-pending-posts.component.scss']
})
export class ReviewPendingPostsComponent {
  @Input() pendingPostsPaging: PostListPaging;
  @Input() objectType = TIMELINE_OBJECTS.fanpage;
  @Input() isLoading = false;
  @Input() customTitlePedingPost: string;
  @Input() hideSearchBox = false;
  @Input() isPostReview = true;
  @Output() approvePosts = new EventEmitter();
  @Output() declinePosts = new EventEmitter();
  @Output() searchPosts = new EventEmitter();
  @Output() pageSize = new EventEmitter();
  @Output() pagingDetail = new EventEmitter();
  paging: Paging = {
    page: 0,
    pageSize: 10,
    totalElement: 0
  };
  pendingPosts: Post[] = [];
  searchPendingPost = new Subject<string>();
  checkBoxValue: boolean = false;
  postListReview: PostReview[] = [];
  selectedPost = 0;
  showPendingPostDialog: boolean;
  POST_REVIEW_ACTIONS = POST_REVIEW_ACTIONS;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  postReviewConfirmation = POST_REVIEW_CONTEXTS.approve;
  reviewedPostIds: string[] = [];
  isApprove: boolean;
  isModerate = false;

  constructor(
    private translationService: TranslationService,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.searchPendingPost.pipe(debounceTime(SEARCH_DEBOUNCE_TIME)).subscribe(value => {
      this.searchPosts.emit(value);
    });
    this.isModerate = this.activatedRoute.snapshot.queryParams['admin'] === 'true';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['pendingPostsPaging']) {
      this.selectedPost = 0;
      this.checkBoxValue = false;
      if (this.pendingPostsPaging && this.pendingPostsPaging.data) {
        this.paging = {
          page: this.pendingPostsPaging.page,
          pageSize: this.pendingPostsPaging.pageSize,
          totalElement: this.pendingPostsPaging.totalElement
        };
        this.pendingPosts = this.pendingPostsPaging.data;
        this.prepareReviewPendingPosts(this.pendingPosts);
      }
    }
  }

  prepareReviewPendingPosts(pendingPosts: Post[]): void {
    const postListReview: PostReview[] = [];
    pendingPosts.forEach(post => postListReview.push({ postId: post.id, isSelected: false }));
    this.postListReview = postListReview;
  }

  translatePostConfirmation(contexts: any) {
    const customContextMessage = `${contexts.message}_${this.objectType.toUpperCase()}`;
    const translateData = {
      ...contexts,
      title: this.translationService.getTranslation(contexts.title),
      message: this.translationService.getTranslation(customContextMessage),
      buttonTitle: this.translationService.getTranslation(contexts.buttonTitle)
    };
    this.postReviewConfirmation = translateData;
  }

  onConfirmReviewedPost(postReview: PostReview, isApprove: boolean): void {
    this.showPendingPostDialog = true;
    this.reviewedPostIds = [postReview.postId];
    this.isApprove = isApprove;
    this.translatePostConfirmation(isApprove ? POST_REVIEW_CONTEXTS.approve : POST_REVIEW_CONTEXTS.decline);
    this.confirmationService.confirm({
      message: this.postReviewConfirmation.message,
      header: this.postReviewConfirmation.title,
      accept: () => {
        isApprove ? this.approvePosts.emit([postReview.postId]) : this.declinePosts.emit([postReview.postId]);
      }
    });
  }

  onConfirmReviewedAllPosts(typeAction: string): void {
    this.showPendingPostDialog = true;
    const reviewedPostIdList = this.postListReview.reduce((acc: string[], post) => {
      if (post.isSelected) {
        acc.push(post.postId);
      }
      return acc;
    }, []);
    this.reviewedPostIds = reviewedPostIdList;
    this.isApprove = typeAction === POST_REVIEW_ACTIONS.approveAll;
    this.translatePostConfirmation(
      typeAction === POST_REVIEW_ACTIONS.approveAll ? POST_REVIEW_CONTEXTS.approveAll : POST_REVIEW_CONTEXTS.declineAll
    );
    this.confirmationService.confirm({
      message: this.postReviewConfirmation.message,
      header: this.postReviewConfirmation.title,
      accept: () => {
        typeAction === POST_REVIEW_ACTIONS.approveAll
          ? this.approvePosts.emit(reviewedPostIdList)
          : this.declinePosts.emit(reviewedPostIdList);
      }
    });
  }

  onSelectedAllPosts(checkEvent: any): void {
    this.postListReview = this.postListReview.map(reviewPendingPost => ({
      ...reviewPendingPost,
      isSelected: checkEvent.checked
    }));
    this.countSelectedPost();
  }

  updateSelectedPost(postDetailsReview: PostReview, value: boolean): void {
    this.postListReview = this.postListReview.map(reviewPendingPost =>
      postDetailsReview.postId === reviewPendingPost.postId
        ? {
            postId: reviewPendingPost.postId,
            isSelected: value
          }
        : reviewPendingPost
    );
  }

  onSelectedPost(postDetailsReview: PostReview, value: boolean): void {
    this.updateSelectedPost(postDetailsReview, value);
    this.countSelectedPost();
  }

  countSelectedPost(): void {
    let countSelectedPost = 0;
    this.postListReview.forEach(reviewPendingPost => {
      if (reviewPendingPost.isSelected) {
        countSelectedPost++;
      }
    });
    this.selectedPost = countSelectedPost;
    this.checkBoxValue = this.selectedPost !== 0;
  }

  onSearchPendingPost(keyword: string): void {
    this.searchPendingPost.next(keyword);
  }

  confirmedAction(): void {
    this.isLoading = true;
    this.isApprove ? this.approvePosts.emit(this.reviewedPostIds) : this.declinePosts.emit(this.reviewedPostIds);
    this.showPendingPostDialog = false;
  }

  onChangedPageSize(size: number): void {
    this.pageSize.emit(size);
  }

  onPageChange(event: any): void {
    this.pagingDetail.emit(event);
  }
}
