<div class="otp-form">
  <div class="flex justify-between mb-3 text-sm w-full items-center">
    <span class="font-medium text-palette-gray-700">{{ 'SIGNIN_SIGNUP.SIGNUP.VERIFICATION_CODE' | translate }}</span>
    <span class="font-medium text-palette-gray-700" *ngIf="countdown !== 0; else resendCode"
      >{{ 'SIGNIN_SIGNUP.FORGOT_PASSWORD.RESEND_CODE_IN' | translate }} <b>{{ countdown | countdownFormat }}</b></span
    >
    <ng-template #resendCode>
      <span class="font-semibold text-branding-primary-700 cursor-pointer" (click)="onResendCode()"
        >{{ 'SIGNIN_SIGNUP.SIGNUP.RESEND_CODE' | translate }}
      </span>
    </ng-template>
  </div>
  <div class="w-full grid grid-cols-6 gap-2 md:gap-6">
    <input
      *ngFor="let digit of digits; index as index"
      #OTPDigit
      type="text"
      maxlength="1"
      inputmode="numeric"
      placeholder="-"
      class="h-12 border border-palette-gray-300 rounded-lg text-palette-gray-900 placeholder:text-palette-gray-500 text-center focus-visible:outline-branding-primary-200"
      [value]="digit"
      (keyup)="onInputChange($event, index)"
      (paste)="onPaste($event)" />
  </div>
  <span *ngIf="isVerifyCodeFailed" class="text-sm text-system-error-600 font-normal">{{
    'SIGNIN_SIGNUP.ERROR_MESSAGES.OTP_IS_INCORRECT' | translate
  }}</span>
</div>
