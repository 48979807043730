import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '@app/core/services/group.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { getDetailGroup, modalMemberGroupPending } from '@app/lib/api/group/api.group.model';
import {
  OBJECT_TYPE,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  REPORT_CONTENT,
  ROLES,
  SORT,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { fetchPendingPost, loadMemberPendingGroup } from '../../../store/actions';
import { GroupInterfaceState } from '../../../store/reducer';
import { selectInfoDetailGroup, selectMemberPendingGroup, selectPendingPost } from '../../../store/selectors';
import { GroupPost } from '../group-timeline/group-timeline.component';
import { PostPendingProps } from './pending-posts/group-pending-posts.component';
import { ReportContentComponent } from './report-content/report-content.component';

export const GROUP_SETTINGS = {
  generalInformation: { id: 0, title: 'GENERAL_INFORMATION', icon: 'sctr-icon-info-circle' },
  pendingPosts: { id: 1, title: 'PENDING_POSTS', icon: 'sctr-icon-notification-text' },
  memberRequests: { id: 2, title: 'MEMBER_REQUESTS', icon: 'sctr-icon-users-edit' },
  reportedContents: { id: 3, title: 'REPORTED_CONTENTS', icon: 'sctr-icon-flag-03' },
  groupRules: { id: 4, title: 'GROUP_RULES', icon: 'sctr-icon-shield-tick' },
  groupStatistics: { id: 5, title: 'GROUP_STATISTICS', icon: 'sctr-icon-bar-chart-square-02' }
};

@Component({
  selector: 'group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss']
})
export class GroupSettingsComponent implements OnInit {
  @Input() activeIndex: number = 0;
  @Input() postLists: any[];
  @Output() managePostPending = new EventEmitter();
  @Output() isBackToTimeline = new EventEmitter();
  @ViewChild(ReportContentComponent) reportContent: ReportContentComponent;
  memberPendingGroup$: Observable<modalMemberGroupPending[] | null>;
  infoDetailGroup$: Observable<getDetailGroup | null>;
  groupSettings = GROUP_SETTINGS;
  postPendingList: any[];
  reportDataPost: any[];
  isActiveGroupSetting = false;
  selectedSettingTitle = '';
  dataReportContent: any[];
  settingPostPending: any[];
  groupId: string | null;
  isShowRoleSetting = false;
  dataGroupAllRequestInvited: any[] = [];
  isLoading = false;
  isLoadingReportPost = false;

  constructor(
    private store: Store<GroupInterfaceState>,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private translationService: TranslationService,
    private toastMessageService: ToastMessageService,
    private router: Router
  ) {
    this.memberPendingGroup$ = this.store.pipe(select(selectMemberPendingGroup));
    this.infoDetailGroup$ = this.store.pipe(select(selectInfoDetailGroup));
    this.store.pipe(select(selectPendingPost)).subscribe((item: any) => {
      this.settingPostPending = item;
    });
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToAnchor('topSetting');
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('id');
    });
    if (this.groupId) {
      this.store.dispatch(loadMemberPendingGroup({ groupId: this.groupId }));
      const params: GroupPost = {
        pageSize: PAGE_SIZE_DEFAULT,
        pageNum: PAGE_NUM_DEFAULT,
        sortByDate: SORT.desc
      };
      this.store.dispatch(fetchPendingPost({ groupID: this.groupId, params }));
      // this.groupService.getGroupReportContentPost(this.groupId).subscribe((res: any) => {
      //   this.dataReportContent = res.data;
      // });

      let dataPayload: any = {
        categories: [],
        status: ['PENDING'],
        objectType: OBJECT_TYPE.post,
        objectParentId: this.groupId
      };
      this.groupService.PostFilterGroupReportCategories(dataPayload).subscribe((res: any) => {
        this.dataReportContent = res?.data;
      });
      this.isLoading = true;
      this.groupService.getGroupDetail(this.groupId).subscribe((res: any) => {
        this.isShowRoleSetting = res.data.role === ROLES.MOD;
        this.isLoading = false;
      });
    }
    this.postPendingList = this.mappingPostsPendingData(this.postLists);

    this.groupService.getGroupAllRequestInvitedUsers(this.groupId).subscribe((res: any) => {
      if (res && res.data) {
        this.dataGroupAllRequestInvited = res.data;
      }
    });
  }

  handlePostAction(event: any) {
    let param = event;
    this.isLoadingReportPost = true;
    this.groupService.postGroupRejectPending(param).subscribe((res: any) => {
      if (res && res.data.length) {
        const successMessage =
          param.status === REPORT_CONTENT.reject
            ? 'GROUP.SETTING.POST_REJECTED_SUCCESSFULLY'
            : 'GROUP.SETTING.POST_APPROVED_SUCCESSFULLY';

        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.translationService.getTranslation(successMessage)
        );

        if (this.groupId) {
          let dataPayload: any = {
            categories: [],
            status: ['PENDING'],
            objectType: OBJECT_TYPE.post,
            objectParentId: this.groupId
          };
          this.groupService.PostFilterGroupReportCategories(dataPayload).subscribe((res: any) => {
            this.isLoadingReportPost = false;
            this.dataReportContent = res.data;
          });
        }
      }
      this.reportContent.selectedPost = 0;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['postLists']) {
      this.postPendingList = this.mappingPostsPendingData(this.postLists);
    }
  }

  selectedSetting(settingId: number, settingTitle: string): void {
    this.activeIndex = settingId;
    this.selectedSettingTitle = settingTitle;
    this.isActiveGroupSetting = true;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { title: settingTitle },
      queryParamsHandling: 'preserve',
      replaceUrl: true
    });
  }

  onBackToGroupSettings(): void {
    this.isActiveGroupSetting = false;
  }

  onBackToGroup(): void {
    this.isBackToTimeline.emit(true);
  }

  PendingPostAction(action: { selected: string; listPost: PostPendingProps[] }) {
    this.managePostPending.emit(action);
  }

  mappingPostsPendingData(data: any[]) {
    return data?.map(g => {
      const mediaList = g.media_urls?.media?.map((m: any) => m.original) || [];
      return {
        id: g.id,
        name: g.user_object ? `${g.user_object.first_name} ${g?.user_object.last_name}` : '',
        caption: g ? g.content : '',
        avatar: g?.user_object.avatar_thumbnail_url ? g.user_object.avatar_thumbnail_url : '',
        images: this.getImages(mediaList as string[]),
        time: this.getTimeAgo(g?.posted_date as string)
      };
    });
  }
  getImages(media: string[]) {
    const baseImgUrl = environment.baseURL;
    const mediaListLength = media.length;
    for (let i = 0; i < mediaListLength; i++) {
      media[i] = baseImgUrl + '/storage/files/thumbnail/' + media[i];
    }
    return media;
  }

  getTimeAgo(timestamp: string) {
    const now = new Date().valueOf();
    const time = new Date(timestamp).valueOf();
    const differenceInSeconds = Math.floor((now - time) / 1000);

    if (differenceInSeconds < 60) {
      return differenceInSeconds + ' seconds ago';
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return minutes + ' minutes ago ';
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return hours + ' hours ago';
    } else {
      const days = Math.floor(differenceInSeconds / 86400);
      return days + ' days ago';
    }
  }

  declineAll(post: { selected: string; listPost: PostPendingProps[] }) {
    this.managePostPending.emit(post);
  }

  approveAll(post: { selected: string; listPost: PostPendingProps[] }) {
    this.managePostPending.emit(post);
  }

  handleSearchReportContent(event: string) {
    if (this.groupId) {
      let param = {
        content: event,
        categories: [],
        status: 'ALL',
        objectType: 'Post',
        objectParentId: this.groupId
      };
      this.groupService.groupReportContent(param).subscribe((res: any) => {
        if (res && res.data) {
          this.dataReportContent = res.data;
        }
      });
    }
  }
}
