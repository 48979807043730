import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createPostDialogStatusEnum } from '@app/core/enums/app.enum';
import { CommonService } from '@app/core/services/common.service';
import { selectFanpageInfo } from '@app/modules/main/fanpage/store/selector';
import { selectMemberGroup } from '@app/modules/main/group/store/selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { FriendList } from '@app/shared/models/friend';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Post } from '@shared/models/post';
import { PostDialogComponent } from './post-dialog/post-dialog.component';

@Component({
  selector: 'create-post-dialog',
  templateUrl: './create-post-dialog.component.html',
  styleUrls: ['./create-post-dialog.component.scss']
})
export class CreatePostDialogComponent {
  @ViewChild(PostDialogComponent) postDialog: PostDialogComponent;
  @Input() createPostItem: any[];
  @Input() loading = false;
  @Input() isLoggedUser: boolean;
  @Input() isGroupPost = false;
  @Input() isFanpagePost = false;
  @Input() currentPage: string;
  @Input() groupPrivacy: string;
  @Input() groupId: string;
  @Input() fanpageId: string;
  @Input() fanpageInfo: any;
  @Input() isImmediatelyShownPost: boolean;
  @Input() customClass: string;
  @Output() onCreatePost: Event;
  @Output() itemClicked: EventEmitter<string> = new EventEmitter();
  @Output() postData: EventEmitter<string> = new EventEmitter();
  @Output() progressCall: EventEmitter<any> = new EventEmitter<any>();
  @Output() privacyPost: EventEmitter<string> = new EventEmitter();

  @Input() isGroupStyle: boolean = false;
  userInfo$ = this.store.select(selectUserInfo);
  fanpageInfo$ = this.store.select(selectFanpageInfo);
  membersGroup$ = this.store.select(selectMemberGroup);
  posts: Post[] = [];
  showCreatePostDialog = false;
  status = createPostDialogStatusEnum;
  friendList: FriendList[];
  baseUrl = environment.baseURL;
  isOpenPopupLogin = false;
  isModerate = false;

  constructor(
    private store: Store,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isModerate = this.activatedRoute.snapshot.queryParams['admin'] === 'true';
  }

  toggleCreatePostDialog() {
    this.postDialog.openDialog();
  }

  toggleAction(value: string) {
    if (this.postDialog.loading) {
      this.postDialog.openDialog();
    } else {
      switch (value) {
        case this.status.photo:
          this.postDialog.openDialog();
          this.postDialog.showUploadSection = true;
          this.postDialog.showStatusTag = false;
          break;
        case this.status.status:
          this.postDialog.openDialog();
          this.postDialog.showUploadSection = false;
          this.postDialog.showStatusTag = true;
          break;
        case this.status.checkin:
          this.postDialog.openDialog();
          this.postDialog.isPendingCheckIn = true;
          break;
        default:
          break;
      }
    }
  }

  getPostData(data: any) {
    if (data) {
      this.postData.emit(data);
    }
  }

  callProgress(data: any) {
    if (data) {
      this.progressCall.emit(data);
    }
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  openPopup(event: any) {
    this.isOpenPopupLogin = event;
  }
}
