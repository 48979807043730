import { Component, Input } from '@angular/core';
import { FriendActions } from '@app/modules/main/states/friend/friend.actions';
import { selectLoadingFriendState } from '@app/modules/main/states/friend/friend.selectors';
import { CURRENT_SCREEN, QUANTITY_FRIENDS_TO_SHOW } from '@app/shared/constant';
import { Store } from '@ngrx/store';
import { debounce } from 'lodash-es';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'coin-gifting',
  templateUrl: './coin-gifting-component.html',
  styleUrls: ['./coin-gifting-component.scss']
})
export class CoinGiftingComponent {
  @Input() sentCoinList: any;
  coinReceiver: any;
  visiblePopup = false;
  showMoreStatus = false;
  noData = false;
  isFirstClick = false;
  searchText = '';
  indexUpdate: number;
  CURRENT_SCREEN = CURRENT_SCREEN;
  private searchTextChanged = new Subject<string>();
  @Input() currCoin: number;
  pageNum = 1;
  total_records = 0;
  loadingFriend$ = this.store.select(selectLoadingFriendState);
  loadingFriendLazy = false;
  loadingSkeletons: number;
  friendListLength = 5;
  friendsToShow = QUANTITY_FRIENDS_TO_SHOW;
  constructor(private store: Store) {}

  ngOnInit() {
    this.searchTextChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(text => {
      this.store.dispatch(FriendActions.onSearchFriendsState({ searchText: text }));
    });
  }

  ngOnChanges(): void {
    this.isHaveData();
    this.loadingSkeletons = this.friendListLength - (this.sentCoinList.content.length % this.friendListLength);
  }

  sendCoin(event: any) {
    this.visiblePopup = event.showPopup;
    this.coinReceiver = event.coinReceiver;
  }

  handleViewMoreUserSentCoin() {
    this.showMoreStatus = !this.showMoreStatus;
    if (!this.isFirstClick) {
      this.getFriendsSuggest();
      this.isFirstClick = true;
    }
  }

  onSearchFriends(event: Event) {
    const textSearch = (event.target as HTMLInputElement).value;
    this.searchTextChanged.next(textSearch);
  }

  getFriendsSuggest() {
    this.store.dispatch(FriendActions.onGetListFriendState({ pageNum: this.pageNum, pageSize: 5 }));
    this.pageNum++;
    this.loadingFriend$.subscribe(loading => {
      if (loading === false) {
        this.loadingFriendLazy = false;
      }
    });
  }

  isHaveData() {
    this.noData = this.sentCoinList.is_last_page === true;
  }

  onScroll = debounce((event: any) => {
    const container = event.target;
    const buffer = 100;

    if (!this.noData && !this.loadingFriendLazy) {
      if (container.scrollHeight - container.scrollTop < container.clientHeight + buffer) {
        this.loadingFriendLazy = true;
        this.getFriendsSuggest();
      }
    }
  }, 200);
}
