<div class="flex px-4 pt-4">
  <div class="flex flex-1 space-x-3">
    <ng-container *ngIf="!isShowActualPoster && (data.page_object || data.group_object); else showUserProfile">
      <a
        *ngIf="data.page_object"
        (click)="trackingAdsEvent(adsAction.profile, $event)"
        [href]="handleGenerateURL(false)">
        <profile-avatar
          [type]="avatarType.ringWithBorder"
          [size]="avatarSize.small"
          [thumbnailURL]="data.page_object.avatar"
          [name]="data.page_object.page_name"
          [onerrorImage]="''"></profile-avatar>
      </a>
      <a
        *ngIf="data.group_object"
        (click)="trackingAdsEvent(adsAction.profile, $event)"
        [href]="handleGenerateURL(false)">
        <profile-avatar
          [type]="avatarType.ringWithBorder"
          [size]="avatarSize.small"
          [initialsAvatarColor]="'bg-palette-amber-50 text-palette-amber-600'"
          [thumbnailURL]="data.group_object.icon_image_uri || ''"
          [name]="data.group_object.group_name || ''"
          [onerrorImage]="''"></profile-avatar>
      </a>
    </ng-container>
    <ng-template #showUserProfile>
      <a [href]="handleGenerateURL(false)">
        <profile-avatar
          [type]="avatarType.ringWithBorder"
          [size]="avatarSize.small"
          [thumbnailURL]="data.user_object.avatar_thumbnail_url || ''"
          [name]="data.user_object.full_name || ''"
          [onerrorImage]="''"></profile-avatar>
      </a>
    </ng-template>
    <div class="flex flex-1 flex-col">
      <div class="flex items-center gap-1.5">
        <div class="text-wrap w-auto leading-4">
          <a
            [href]="handleGenerateURL(false)"
            (click)="trackingAdsEvent(adsAction.profile, $event)"
            class="inline-flex text-base text-balance text-palette-gray-700 font-semibold cursor-pointer max-w-[15rem]">
            <span class="truncate hover:underline">{{
              !isShowActualPoster && (data.page_object || data.group_object)
                ? data.page_object
                  ? data.page_object.page_name
                  : data.group_object?.group_name || ''
                : userInfo
                ? userInfo.full_name
                : ''
            }}</span>
          </a>
          <span
            class="inline-flex translate-y-[3px] justify-center items-center relative w-[18px] h-[18px] mx-0.5"
            *ngIf="
              !(data.group_object && !isPostGroup) &&
              ((!(data.page_object && !isShowActualPoster) && data.user_object?.is_verified_user) ||
                data.page_object?.is_verified)
            ">
            <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
            <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/4"></i>
          </span>
          <ng-container *ngIf="data.post_type === POST_TYPE.avatar">
            <span class="text-palette-gray-600 text-sm font-medium ml-1.5">
              <ng-container [ngSwitch]="data.user_gender">
                <span *ngSwitchCase="'FEMALE'">{{ 'POST.HAS_UPDATED_HER_AVATAR' | translate }}</span>
                <span *ngSwitchCase="'MALE'">{{ 'POST.HAS_UPDATED_HIS_AVATAR' | translate }}</span>
                <span *ngSwitchDefault>{{ 'POST.HAS_UPDATED_THEIR_AVATAR' | translate }}</span>
              </ng-container>
            </span>
          </ng-container>
          <ng-container *ngIf="data.post_type === POST_TYPE.cover">
            <span class="text-palette-gray-600 text-sm font-medium ml-1.5">
              <ng-container [ngSwitch]="data.user_gender">
                <span *ngSwitchCase="'FEMALE'">{{ 'POST.HAS_UPDATED_HER_COVER' | translate }}</span>
                <span *ngSwitchCase="'MALE'">{{ 'POST.HAS_UPDATED_HIS_COVER' | translate }}</span>
                <span *ngSwitchDefault>{{ 'POST.HAS_UPDATED_THEIR_COVER' | translate }}</span>
              </ng-container>
            </span>
          </ng-container>
          <ng-container
            *ngIf="data.post_pattern === POST_PATTERN.share && data.parent?.post_pattern === POST_PATTERN.album">
            <span class="text-palette-gray-600 text-sm font-medium ml-1.5">
              {{ 'POST.SHARED_AN_ALBUM' | translate }}
            </span>
          </ng-container>
          <ng-container *ngIf="data.post_type === POST_TYPE.livestream">
            <div class="inline text-sm text-palette-gray-500 font-medium">
              {{ '&nbsp;&#x2022; ' + ('POST.STREAMING' | translate) }}
            </div>
          </ng-container>
          <ng-container *ngIf="data.post_feeling">
            <div class="text-sm text-palette-gray-500 font-normal items-center inline emoji">
              {{ '&nbsp;' + ('POST.IS_FEELING' | translate) }} {{ data.post_feeling | statusIcons }}
              <span class="text-base text-palette-gray-700 font-semibold">{{
                translateStatus(data.post_feeling) | lowercase
              }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="data.friend_tags && data.friend_tags[0]?.user_object">
            <div class="inline text-sm text-palette-gray-500 font-normal">
              {{ '&nbsp;' + ((data.post_feeling ? 'POST.WITH' : 'POST.IS_WITH') | translate) }}
              <ng-container
                [ngTemplateOutlet]="friendTagged"
                [ngTemplateOutletContext]="{ friendTag: data.friend_tags[0] }">
              </ng-container>

              <ng-container *ngIf="data.friend_tags.length >= 2">
                <span class="lowercase">{{ 'COMMON.AND' | translate }}</span>
                <ng-container
                  *ngIf="data.friend_tags.length === 2"
                  [ngTemplateOutlet]="friendTagged"
                  [ngTemplateOutletContext]="{ friendTag: data.friend_tags[1] }">
                </ng-container>
                <span
                  *ngIf="data.friend_tags.length > 2"
                  [pTooltip]="setTooltipFriendTag(data.friend_tags)"
                  tooltipPosition="top"
                  [escape]="false"
                  (click)="dialogTagFriendsVisible = true"
                  [hideDelay]="500"
                  class="hover:cursor-pointer hover:underline hover:decoration-1 text-palette-gray-700 font-semibold text-base">
                  {{ data.friend_tags.length - 1 + '&nbsp;' + ('POST.OTHER_PEOPLE' | translate) }}
                </span>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="data.check_in && data.check_in.object_data_check_in">
            <span class="text-sm leading-normal text-palette-gray-500 font-normal">
              {{
                '&nbsp;' +
                  ((data.friend_tags || data.post_feeling ? 'POST.CHECK_IN.AT' : 'POST.CHECK_IN.IS_AT') | translate) +
                  '&nbsp;'
              }}
            </span>
            <span
              (click)="navigateToFanPage(data.check_in.object_id)"
              class="text-base text-palette-gray-700 font-semibold cursor-pointer hover:underline hover:decoration-1 max-w-full break-words"
              >{{ data.check_in.object_data_check_in.page_name }}</span
            >
          </ng-container>
          <ng-container *ngIf="data.shareType">
            <span class="text-sm text-palette-gray-600 font-medium inline">
              {{ data.shareType === shareType.group ? ('POST.SHARE_TO_GROUP' | translate) : '' }}
            </span>
            <a
              (click)="handleBlockNavigationWithAdminView($event)"
              class="text-base text-palette-gray-700 font-semibold cursor-pointer max-w-[200px] truncate inline"
              [href]="handleGenerateURL(false)">
              {{ data.shareType === shareType.group ? data.shareToGroupInfo?.group_name : '' }}
            </a>
          </ng-container>
          <ng-container *ngIf="data.post_pattern === POST_PATTERN.link">
            <span class="text-sm text-palette-gray-600 font-medium inline">
              {{ 'POST.SHARE_LINK_TYPE' | translate: { type: data.share_link_type } | lowercase }}
            </span>
          </ng-container>
          <ng-container *ngIf="albumFilesCount">
            <span class="text-sm text-palette-gray-600 font-medium inline">
              {{ 'POST.HAS_ADDED_MEDIA_TO_ALBUM' | translate: { fileCount: albumFilesCount } }}
            </span>
            <span
              class="text-palette-gray-600 font-semibold text-base emoji cursor-pointer"
              (click)="navigateToLibrary(data)"
              >{{ data.library_object?.name }}</span
            >
          </ng-container>
          <div
            *ngIf="(isNewsfeed || isWatchScreen) && data.page_object"
            class="text-sm inline-flex items-center gap-1 mx-1 text-branding-primary-600 font-semibold cursor-pointer">
            <span class="px-[2px] text-[#667085]">&#x2022;</span>
            <p-button
              [disabled]="onHandleFollowAction"
              checkAuthentication
              (openLoginPopup)="isOpenPopupLogin = $event"
              (onClick)="
                !isLoggedIn ? null : !isFollowFanpage ? onFollow(data.page_object) : onUnfollow(data.page_object)
              "
              class="custom-btn"
              [styleClass]="'hover:bg-palette-base-white font-semibold p-0 border-none'">
              <span
                class="hover:underline"
                [ngClass]="!isFollowFanpage ? 'text-branding-primary-600 ' : 'text-palette-gray-400'">
                {{ (!isFollowFanpage ? 'COMMON.FOLLOW' : 'COMMON.FOLLOWING') | translate }}
              </span>
            </p-button>
            <ng-container *ngIf="onHandleFollowAction">
              <loading-spinner [styleClass]="'w-4 h-4'"></loading-spinner>
            </ng-container>
          </div>
        </div>
        <div *ngIf="isPinnedPost" class="h-full flex">
          <i class="sctr-icon-solid-pin-02 text-base/4 inline-block mt-1"></i>
        </div>
      </div>
      <div class="flex items-center space-x-2 py-0.5">
        <div *ngIf="data?.group_id && userInfo && !isPostGroup" class="flex items-center gap-0.5">
          <div
            class="inline-flex text-xs font-semibold text-palette-gray-500 cursor-pointer max-w-[8rem]"
            (click)="navigateToProfile()">
            <span class="truncate hover:underline">{{ userInfo ? userInfo.full_name : '' }}</span>
          </div>
          <div class="flex justify-center items-center relative w-[18px] h-[18px]" *ngIf="userInfo.is_verified_user">
            <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
            <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/4"></i>
          </div>
        </div>
        <span
          class="text-palette-gray-500 text-xs font-semibold"
          *ngIf="data?.post_category && data?.post_category === POST_TYPE.suggestion">
          <span class="pr-1" *ngIf="data?.group_id && userInfo && !isPostGroup">&#x2022;</span>
          {{ 'HOME.SUGGESTED_FOR_YOU' | translate }}
        </span>
        <i *ngIf="postPrivacy" class="{{ postPrivacy.icon }} text-base/[0px] text-palette-gray-500" class="w-4"></i>
        <a
          *ngIf="!data.ads_id"
          (click)="handleBlockNavigationWithAdminView($event)"
          class="text-xs font-semibold text-palette-gray-500 cursor-pointer hover:underline"
          [pTooltip]="(data.posted_date | dateFormatPipe: true) || ''"
          [tooltipStyleClass]="'max-w-none'"
          [href]="'/post/' + this.data.id">
          {{ getFromNow() }}
        </a>
        <span *ngIf="data.ads_id" class="text-palette-gray-500 font-medium text-xs">{{
          'COMMON.SPONSORED' | translate
        }}</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="isShowConfig && !isSharedPost && !isModerate && !loadingEditPost"
    (click)="toggle()"
    checkAuthentication
    (openLoginPopup)="isOpenPopupLogin = $event"
    class="w-8 h-8 flex items-center justify-center rounded-full cursor-pointer hover:bg-slate-200">
    <span class="sctr-icon-dots-horizontal"></span>
  </div>

  <div class="w-8 h-8 flex items-center justify-center rounded-full" *ngIf="loadingEditPost">
    <p-progressSpinner
      styleClass="w-8 h-8"
      strokeWidth="4"
      fill="var(--surface-ground)"
      animationDuration=".5s"></p-progressSpinner>
  </div>
</div>

<p-overlay
  *ngIf="overlayVisible"
  [(visible)]="overlayVisible"
  [contentStyleClass]="'px-4 shadow-2 border-round float-right'"
  [styleClass]="isShowDetailPost ? (isPostDetail ? 'z-10, top-[135px]' : 'z-10, top-[65px]') : 'z-10'">
  <p-listbox
    [options]="postOptions"
    class="p-0"
    [styleClass]="'w-max py-1 rounded-lg border-[#EAECF0] border option-menu'"
    [listStyleClass]="'py-0.5'">
    <ng-template let-item pTemplate="item">
      <div
        class="flex py-[11px] px-4 items-center gap-2 w-full min-w-[128px]"
        (click)="handlerAction(item.action, data.id, data)">
        <span class="{{ item.icon }}"></span>
        <div class="text-sm">{{ item.label | translate }}</div>
      </div>
    </ng-template>
  </p-listbox>
</p-overlay>

<post-dialog
  [isUploadFileGroup]="isGroupStyle"
  [sharedPost]="data.parent"
  [userInfo]="userInfo"
  [friendList]="friendList"
  [isGroupPost]="isPostGroup"
  [groupId]="groupId"
  [fanpageInfo]="fanpageInfo"
  [groupPrivacy]="groupPrivacy"
  [(isEditPost)]="isEditPost"
  [postEdit]="data"
  [messageRanges]="dataInit.message_ranges ? dataInit.message_ranges : []"
  [contentComment]="formattedContent()"
  (postData)="getPostData($event)"></post-dialog>

<lib-report-popup
  [(isLoading)]="isLoadingReport"
  [reportTarget]="getReportTarget()"
  [reportVisibility]="isShowReport"
  [reportCategories]="reportCategories$ | async"
  (closeModal)="handleCloseModal($event)"
  (confirmReport)="handlePostReport($event)"></lib-report-popup>
<edit-privacy
  *ngIf="isEditPrivacy"
  [isShowEditPrivacy]="isEditPrivacy"
  (closeModal)="handleCloseEditPrivacy($event)"
  (emitPrivacy)="handleEditPrivacy($event)"
  [post_privacy]="data.post_privacy"
  [isEditPostFail]="(isEditPostFail$ | async) || false"
  [isEditPostSuccess]="(isEditPostSuccess$ | async) || false"
  [postUpdated]="(postUpdated$ | async) || {}"></edit-privacy>
<book-mark-category
  [(showBookMarkCategory)]="showBookMarkCategory"
  [postId]="data.id"
  (bookmarkAdded)="onBookmarkAdded()"
  (isBookMarked)="onBookMarked($event)"></book-mark-category>

<div *ngIf="isOpenPopupLogin">
  <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
</div>

<p-confirmDialog
  *ngIf="dialogConfirmVisible"
  [closable]="false"
  [visible]="dialogConfirmVisible"
  class="confirmDialog"
  message="{{ 'POST.HIDE_POST_MESSAGE' | translate }}"
  header="{{ 'POST.HIDE_POST' | translate }}">
  <ng-template pTemplate="footer">
    <button
      pButton
      label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
      (click)="dialogConfirmVisible = false"
      class="text-palette-base-black bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200"></button>
    <button
      pButton
      label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
      (click)="accept(data.id)"
      class="text-palette-base-white bg-palette-red-600 border-palette-red-300"></button>
  </ng-template>
</p-confirmDialog>

<p-confirmDialog
  *ngIf="isShowDialogRemoveTag"
  [closable]="false"
  [visible]="isShowDialogRemoveTag"
  class="confirmDialog"
  message="{{ 'POST.REMOVE_TAG_MESSAGE' | translate }}"
  header="{{ 'POST.REMOVE_TAG' | translate }}">
  <ng-template pTemplate="footer">
    <button
      pButton
      label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
      (click)="isShowDialogRemoveTag = false"
      class="text-palette-base-black bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200"></button>
    <button
      pButton
      [loading]="isLoadingConfirm"
      label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
      (click)="handleRemoveTag()"
      class="text-palette-base-white bg-palette-red-600 border-palette-red-300"></button>
  </ng-template>
</p-confirmDialog>

<p-dialog
  [styleClass]="'w-[480px] h-[440px] border-none rounded-full'"
  [(visible)]="dialogTagFriendsVisible"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  [modal]="true"
  header="{{ 'POST.TAG_FRIENDS' | translate }}">
  <div class="flex flex-col gap-4">
    <div *ngFor="let item of data.friend_tags; let isFirst = first">
      <div
        *ngIf="!isFirst"
        (click)="navigateToProfileFriends(item)"
        class="flex cursor-pointer items-center justify-start gap-2">
        <div class="rounded-full ring-2 ring-white h-[36px] w-[36px]">
          <div class="rounded-full p-[2px] ring-2 ring-orange-500">
            <div class="relative h-[32px] w-[32px]">
              <div class="rounded-full ring-2 ring-transparent">
                <img
                  *ngIf="item.user_object.avatar_thumbnail_url"
                  class="rounded-full w-full h-full"
                  onerror="this.src='assets/images/default_user_avatar.png'"
                  [src]="getImgUrl(item.user_object.avatar_thumbnail_url)" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-base font-medium">{{ item.user_object ? item.user_object.full_name : '' }}</div>
      </div>
    </div>
  </div>
</p-dialog>

<ng-template #friendTagged let-friendTag="friendTag">
  <ng-template
    [ngIf]="friendTag.status === verificationStatus.pending && friendTag.user_id !== currentUserId"
    [ngIfElse]="approvedStatus">
    <span class="text-palette-gray-700 font-semibold text-base break-words">
      {{ friendTag && friendTag.user_object ? friendTag.user_object.full_name : '' }}
    </span>
  </ng-template>
  <ng-template #approvedStatus>
    <span
      (click)="goInfoDetail(friendTag ? friendTag.user_id : '')"
      class="hover:cursor-pointer hover:underline hover:decoration-1 text-palette-gray-700 font-semibold text-base break-words">
      {{ friendTag && friendTag.user_object ? friendTag.user_object.full_name : '' }}
    </span>
  </ng-template>
</ng-template>
