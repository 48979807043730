import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { LAYOUT_TYPE, MAX_CAPTION_LENGTH, MAX_POST_LENGTH, MEDIA_TYPE, POST_PATTERN } from '@app/shared';
import { Media, MediaInfo } from '@app/shared/models/post';
import { environment } from '@env/environment';
import * as moment from 'moment';

@Component({
  selector: 'post-report',
  templateUrl: './post-report.component.html',
  styleUrls: ['./post-report.component.scss']
})
export class PostReportComponent {
  @Input() checked: boolean | undefined = false;
  @Input() post: any;
  @Input() selectedPost: any;
  @Output() onAction = new EventEmitter();
  @Output() selectedChange = new EventEmitter();
  captionLength = 50;
  showFullText = false;
  checkBoxValue: boolean;
  MAX_POST_LENGTH = MAX_POST_LENGTH;
  MAX_CAPTION_LENGTH = MAX_CAPTION_LENGTH;
  baseUrl: string = environment.baseURL;
  formattedDateTime: string;
  mediaList: Media[] = [];
  MEDIA_TYPE = MEDIA_TYPE;
  POST_PATTERN = POST_PATTERN;
  LAYOUT_TYPE = LAYOUT_TYPE;
  displayFullImage = false;
  activeIndex: number;
  isClickedPlay = false;
  mediaData: MediaInfo;
  reporters: any[];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checked']) {
      if (this.checked !== undefined) {
        this.checkBoxValue = this.checked;
      }
    }
  }

  constructor(private commonService: CommonService) {}

  formatDateTime(dateTimeString: string): string {
    const formattedDate = moment(dateTimeString).format('DD/MM/YYYY HH:mm');
    return formattedDate;
  }
  ngOnInit() {
    this.checkBoxValue = false;
    if (this.post.object.posted_date) {
      this.formattedDateTime = this.formatDateTime(this.post.object.posted_date);
    }

    this.mediaData = this.post.object.media_urls;
    this.mediaList = this.mediaData ? this.mediaData.media : [];
    if (this.mediaData) {
      this.mediaList = this.mediaData.media;
    }
  }

  onSelect(selected: string, listPost: any[], id: string) {
    let action = { selected, listPost, id };
    this.onAction.emit(action);
  }

  setTruncatedCaption(caption: string): string {
    const words = caption.split(' ');
    if (words.length > this.captionLength) {
      return words.slice(0, this.captionLength).join(' ') + '...';
    } else {
      return caption;
    }
  }
  onSelectedChange(checked: boolean, post: any) {
    let status: { checked: boolean; post: any } = { checked: checked, post: post };
    this.selectedChange.emit(status);
  }

  onExpandText() {
    this.showFullText = !this.showFullText;
  }

  isValidUrl = (urlString: string) => {
    if (urlString && (urlString.includes('http:') || urlString.includes('https:'))) {
      return true;
    } else {
      return false;
    }
  };

  clickImage(i: number) {
    this.displayFullImage = true;
    this.activeIndex = i;
  }

  checkPlayClicked(event: any) {
    this.isClickedPlay = event;
  }

  clickVideo(i: any) {
    if (!this.isClickedPlay) {
      this.clickImage(i);
    } else {
      this.isClickedPlay = false;
    }
  }

  isLandscapeImg(item: any) {
    const img = new Image();
    img.src = this.baseUrl + '/storage/files/thumbnail/' + item.original;
    return img.width > img.height;
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }
  calculateRows(text: string): number {
    const lineCount = (text.match(/\n/g) || []).length + 1;
    const minRows = 0;
    return Math.max(lineCount, minRows);
  }
}
