import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { PLATFORMS, TOAST_MESSAGE_LIFETIME_DURATIONS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { environment } from '@env/environment';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'share-on-platforms',
  templateUrl: './share-on-platforms.component.html',
  styleUrls: ['./share-on-platforms.component.scss']
})
export class ShareOnPlatformsComponent {
  @Input() isShowShareDialog = false;
  @Input() image: string;
  @Input() shareURI: string;
  @Input() imageDataURL: string;
  @Output() emitOnCloseShareDialog = new EventEmitter();
  copied = false;
  dialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'w-fit qr-dialog'
  };
  platforms = PLATFORMS;

  constructor(
    private clipboardService: ClipboardService,
    private meta: Meta,
    private translationService: TranslationService,
    private toastMessageService: ToastMessageService
  ) {}

  onCloseDialog() {
    this.emitOnCloseShareDialog.emit(false);
  }

  shareOnPlatforms(platforms: string) {
    const userName = JSON.parse(localStorage.getItem('user_profile') || '{}').full_name;

    switch (platforms) {
      case 'Facebook':
        this.meta.addTags([
          { property: 'og:url', content: this.shareURI },
          { property: 'og:type', content: 'article' },
          { property: 'og:title', content: userName + ' QR' },
          { property: 'og:description', content: this.translationService.getTranslation('SHARE.SCAN_QR') },
          { property: 'og:image', content: this.imageDataURL }
        ]);
        const shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=$' + encodeURIComponent(this.shareURI);
        window.open(shareUrl, '_blank');
        break;
      case 'Mail':
        const mailUrl =
          'mailto:?subject=' +
          this.translationService.getTranslation('SHARE.JOIN') +
          '&amp' +
          environment.APP_NAME +
          '&amp;body=' +
          this.translationService.getTranslation('SHARE.SCAN_QR') +
          '&amp;' +
          this.shareURI;
        window.open(mailUrl, '_blank');
        break;
      case 'Twitter':
        this.meta.addTags([
          { property: 'twitter:card', content: 'summary_large_image' },
          { property: 'twitter:title', content: userName + ' QR' },
          { property: 'twitter:description', content: this.translationService.getTranslation('SHARE.SCAN_QR') },
          { property: 'twitter:image', content: this.imageDataURL },
          { property: 'twitter:url', content: this.shareURI }
        ]);
        let text = encodeURIComponent(this.translationService.getTranslation('SHARE.SCAN_QR'));
        let url = encodeURIComponent(this.shareURI);
        let hashtags = environment.APP_NAME.replaceAll(' ', '');
        let twitterShareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`;
        window.open(twitterShareUrl, '_blank');
        break;
    }
  }

  copyContent() {
    this.clipboardService.copyFromContent(this.shareURI);
    this.copied = true;
    this.toastMessageService.addToastMessage(
      TOAST_MESSAGE_SEVERITY_LEVELS.success,
      'COIN.INVITE_CODE_COPIED',
      TOAST_MESSAGE_LIFETIME_DURATIONS.short
    );
  }
}
