import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'login',
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('@modules/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app-connect',
    loadChildren: () => import('@modules/app-connect/app-connect.module').then(m => m.AppConnectModule)
  },
  {
    path: '**',
    loadChildren: () => import('@modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    title: 'Page Not Found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
