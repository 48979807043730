<p-dialog
  header="{{ 'COIN.POPUP.SEND_COINS' | translate }}"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [style]="{ width: '400px' }"
  (onHide)="closeDialog()"
  [dismissableMask]="true"
  [styleClass]="'border-0'">
  <div class="p-6">
    <p class="text-base font-medium leading-6 tracking-normal text-left mb-1.5">
      {{ 'COIN.POPUP.SENDING' | translate }}
      <ng-container *ngIf="!isStep2; else elseBlock">
        <span>{{ 'COIN.COINS' | translate }}</span>
      </ng-container>
      <ng-template #elseBlock>
        <span class="text-palette-red-500 mr-1.5">{{ coinToSend }}</span>
        <img src="assets/icons/coin.svg" alt="Gift.svg" class="inline mr-1.5" />
      </ng-template>
      {{ 'COIN.POPUP.TO' | translate }}
    </p>
    <p-card *ngIf="postToSendCoin || userToSendCoin" [styleClass]="'rounded-lg'">
      <ng-container *ngIf="!showAvatarDefault(); else defaultAvatar">
        <img class="inline-block rounded-full w-12 h-12 mr-3" [src]="getAvatar()" alt="avatar" />
      </ng-container>
      <ng-template #defaultAvatar>
        <p-avatar
          *ngIf="postToSendCoin && postToSendCoin.page_object"
          styleClass="rounded-full w-12 h-12 mr-3 bg-palette-blue-50 text-palette-blue-600 font-bold text-xs"
          [label]="(postToSendCoin.page_object.page_name | abbreviate: 'fanpage') || ''"></p-avatar>
      </ng-template>
      <div class="inline-block max-w-[260px]">
        <span class="block text-base font-semibold truncate">{{ getNameObjectToSend() }}</span>
        <span class="block text-xs leading-[18px] font-medium">{{ 'COMMON.DEFAULT_BADGE' | translate }}</span>
      </div>
    </p-card>
    <p *ngIf="!isStep2" class="text-right mt-3">
      <span class="mr-3 text-palette-gray-500">{{ 'COIN.POPUP.AVAILABLE_COINS' | translate }} </span>
      <span class="mr-1.5 text-palette-red-500 text-base font-semibold">{{
        showAvailableCoin() | number: '1.0-0'
      }}</span>
      <span class="inline"><img class="inline" src="assets/icons/coin.svg" /></span>
    </p>
    <p *ngIf="!isStep2 && currCoin - coinToSend < 0" class="text-right m-2 text-palette-red-500 text-xs">
      {{ 'COIN.POPUP.DO_NOT_ENOUGH_COIN' | translate }}
    </p>
  </div>

  <div *ngIf="!isStep2; else step1" class="p-6 pt-0 gap-6 flex flex-col">
    <div class="gap-3 flex justify-between">
      <p-button
        [disabled]="coinToSend <= 0 || currCoin === 0"
        class="rounded-lg bg-palette-blue-50"
        [styleClass]="'p-button-text focus:shadow-none hover:bg-transparent py-3 px-5 border-0'"
        (click)="handleReduceCoinToSend()">
        <i class="pi pi-minus text-palette-blue-700 text-[8px] leading-6 font-semibold"></i>
      </p-button>
      <div class="flex flex-col items-center flex-1">
        <input
          [value]="coinToSend"
          [(ngModel)]="coinToSend"
          placeholder="{{ 'COIN.INPUT_YOUR_COIN' | translate }}"
          [type]="'number'"
          (keypress)="onKeyPress($event)"
          inputmode="numeric"
          class="text-center outline-none p-3 border-transparent inputCoin text-base font-semibold text-palette-gray-700 max-w-[229px]" />
        <div *ngIf="coinToSend" class="w-10 h-[1px] bg-palette-gray-700 top-98"></div>
      </div>
      <p-button
        class="rounded-lg bg-palette-blue-50"
        [styleClass]="'p-button-text focus:shadow-none hover:bg-transparent py-3 px-5 border-0'"
        [disabled]="currCoin - coinToSend < 0 || currCoin === 0"
        (onClick)="handleIncreaseCoinToSend()">
        <i class="pi pi-plus text-palette-blue-700 text-[8px] leading-6 font-semibold"></i>
      </p-button>
    </div>
    <p-slider [(ngModel)]="coinToSend" [disabled]="currCoin === 0" [max]="currCoin" [min]="0"></p-slider>
    <div class="grid grid-cols-4 gap-3">
      <p-button
        *ngFor="let item of [5, 10, 15, 20]"
        class="rounded-lg bg-palette-blue-50"
        [styleClass]="'p-button-text border-2 focus:border-palette-blue-700 focus:shadow-none'"
        (onClick)="handleSetCoin(item)"
        [disabled]="item > currCoin"
        [class.pointer-events-none]="item > currCoin">
        <p class="flex justify-center">
          <img class="inline mr-2" src="assets/icons/coin.svg" />
          <span class="font-bold text-palette-blue-700 w-5">{{ item }}</span>
        </p>
      </p-button>
    </div>
  </div>
  <ng-template #step1>
    <ng-template [ngIf]="isThirdPartySignIn" [ngIfElse]="passwordConfirm">
      <form #myForm="ngForm" (ngSubmit)="onSumbitWithOtp()">
        <div class="p-6 pt-0 pb-14 flex flex-col">
          <p class="text-sm font-medium">
            {{ 'SIGNIN_SIGNUP.OTP_CODE_SENT_VIA_EMAIL_DESCRIPTION' | translate: { email: hideEmailPartially() } }}
          </p>
          <div class="w-full">
            <otp-input
              (OTPCode)="OTPCode = $event"
              [(isVerifyCodeFailed)]="isVerifyCodeFailed"
              (resendCode)="resendCode()"></otp-input>
          </div>
          <span *ngIf="incorrectPassword" class="font-semibold text-sm text-system-error-700">{{
            'COIN.POPUP.INCORRECT_PASSWORD' | translate
          }}</span>
        </div>
        <ng-container
          *ngIf="isStep2"
          [ngTemplateOutlet]="myTemplate"
          [ngTemplateOutletContext]="{
            arg1: 'COIN.POPUP.BACK',
            arg2: 'COMMON.BUTTON_ACTION.CONFIRM'
          }"></ng-container>
      </form>
    </ng-template>

    <ng-template #passwordConfirm>
      <form #myForm="ngForm" (ngSubmit)="onSubmitWithPassword()">
        <div class="p-6 pt-0 pb-14 gap-1.5 flex flex-col">
          <p class="text-sm font-medium">{{ 'COIN.POPUP.CONFIRM_PASS' | translate }}</p>
          <p-password
            required
            name="password"
            #passwordFrom="ngModel"
            [(ngModel)]="password"
            [toggleMask]="true"
            [feedback]="false"
            class="inputPassword"
            [class.ng-invalid]="passwordFrom.invalid && myForm.submitted"
            [class.ng-dirty]="passwordFrom.invalid && myForm.submitted"></p-password>
          <span *ngIf="incorrectPassword" class="font-semibold text-sm text-system-error-700">{{
            'COIN.POPUP.INCORRECT_PASSWORD' | translate
          }}</span>
        </div>
        <ng-container
          *ngIf="isStep2"
          [ngTemplateOutlet]="myTemplate"
          [ngTemplateOutletContext]="{
            arg1: 'COIN.POPUP.BACK',
            arg2: 'COMMON.BUTTON_ACTION.CONFIRM'
          }"></ng-container>
      </form>
    </ng-template>
  </ng-template>

  <ng-template #myTemplate let-arg1="arg1" let-arg2="arg2">
    <div class="p-6 pt-0 flex gap-3 justify-end">
      <p-button
        class="btnAction btnCancel"
        [disabled]="isLoading"
        (onClick)="handleBackDown()"
        [styleClass]="'p-button-text focus:shadow-none hover:bg-transparent mr-0 py-2.5 pr-[18px] pl-[18px]'">
        <span class="font-semibold text-base text-palette-gray-600">
          {{ arg1 | translate }}
        </span>
      </p-button>
      <p-button
        label="{{ arg2 | translate }}"
        [type]="isStep2 ? 'submit' : 'button'"
        [disabled]="isLoading || handleDisableButton()"
        [icon]="isLoading && isStep2 ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
        [styleClass]="'p-button-info focus:shadow-none  mr-0 py-2.5 pr-[18px] pl-[18px] bg-palette-blue-600'"
        class="btnAction"
        [class.pointer-events-none]="!coinToSend"
        (onClick)="!isStep2 && coinToSend > 0 ? handleNext() : null">
      </p-button>
    </div>
  </ng-template>

  <ng-container
    *ngIf="!isStep2"
    [ngTemplateOutlet]="myTemplate"
    [ngTemplateOutletContext]="{
      arg1: 'COMMON.BUTTON_ACTION.CANCEL',
      arg2: 'COIN.POPUP.NEXT'
    }"></ng-container>
</p-dialog>
