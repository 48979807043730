<div
  *ngIf="isVisible"
  class="md:hidden fixed w-full h-full bottom-0 right-0 bg-palette-gray-900/40 flex flex-col justify-end z-[1000]">
  <div
    class="bg-palette-base-white px-4 pb-[34px] pt-1.5 shadow-[0_-20px_24px_-4px_rgba(16,24,40,0.08)] flex flex-col gap-4 rounded-t-xl relative">
    <div class="w-full flex justify-center">
      <div class="w-9 h-[5px] bg-[#3C3C43]/30 rounded-full"></div>
    </div>
    <div class="flex flex-col gap-1">
      <ng-container [ngTemplateOutlet]="dialogHeader"></ng-container>
      <ng-container
        *ngIf="!(isPasswordVerified && isPasswordVerified); else verifyOTPMobile"
        [ngTemplateOutlet]="verifyPasswordContent"
        [ngTemplateOutletContext]="{
          id: 'mobile'
        }"></ng-container>
      <ng-template #verifyOTPMobile>
        <ng-container [ngTemplateOutlet]="verifyOTPContent"></ng-container>
      </ng-template>
    </div>
    <div class="w-full grid grid-cols-2 gap-3 pt-5">
      <ng-container
        [ngTemplateOutlet]="CTAButtons"
        [ngTemplateOutletContext]="{
          isWidthFull: true
        }"></ng-container>
    </div>
  </div>
</div>

<div class="hidden md:block">
  <p-dialog
    [(visible)]="isVisible"
    (visibleChange)="onCloseAuthDialog(!$event)"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [modal]="true"
    closeIcon="sctr-icon-x-close text-2xl text-palette-gray-500 z-[2000]"
    styleClass="w-[500px] rounded-xl border border-palette-gray-200 shadow-sm p-0"
    contentStyleClass="py-0 px-6"
    maskStyleClass="bg-palette-gray-900/40">
    <ng-template pTemplate="header">
      <div class="w-full">
        <ng-container [ngTemplateOutlet]="dialogHeader"></ng-container>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <ng-container
        *ngIf="!(isPasswordVerified && isPasswordVerified); else verifyOTPWeb"
        [ngTemplateOutlet]="verifyPasswordContent"
        [ngTemplateOutletContext]="{
          id: 'web'
        }"></ng-container>
      <ng-template #verifyOTPWeb>
        <ng-container [ngTemplateOutlet]="verifyOTPContent"></ng-container>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex gap-3 justify-end items-center">
        <ng-container
          [ngTemplateOutlet]="CTAButtons"
          [ngTemplateOutletContext]="{
            isWidthFull: false
          }"></ng-container>
      </div>
    </ng-template>
  </p-dialog>
</div>

<ng-template #dialogHeader>
  <span class="text-lg text-palette-gray-900 font-semibold">{{
    (isBlockedResend
      ? 'SIGNIN_SIGNUP.BLOCKED_RESEND_OTP'
      : !(isPasswordVerified && isPasswordVerified)
      ? 'SIGNIN_SIGNUP.ENTER_PASSWORD'
      : 'SIGNIN_SIGNUP.FORGOT_PASSWORD.VERIFICATION'
    ) | translate
  }}</span>
</ng-template>

<ng-template #CTAButtons let-isWidthFull="isWidthFull">
  <ng-container *ngIf="!isBlockedResend; else closeDialogAutomatically">
    <button
      class="cta-button {{
        isWidthFull ? 'w-full' : ''
      }} bg-palette-base-white text-palette-gray-700 hover:bg-palette-gray-200 border border-palette-gray-300"
      type="button"
      (click)="onCloseAuthDialog(true)">
      {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
    </button>
    <button
      class="cta-button {{ isWidthFull ? 'w-full' : '' }} {{
        isDisableConfirmBtn()
          ? 'text-palette-base-white bg-branding-primary-200'
          : 'text-palette-base-white bg-branding-primary-600 hover:bg-branding-primary-700'
      }} cta-button disabled:bg-branding-primary-200 disabled:border-branding-primary-200"
      [disabled]="isDisableConfirmBtn() || isLoading"
      type="submit"
      (click)="onClickConfirmBtn()">
      <i *ngIf="isLoading" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
      {{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}
    </button>
  </ng-container>
</ng-template>

<ng-template #verifyPasswordContent let-id="id">
  <div class="flex flex-col gap-5" *ngIf="userInfo$ | async as userInfo">
    <span class="text-sm text-system-gray-600 font-normal">{{
      'SIGNIN_SIGNUP.ENTER_PASSWORD_DESCRIPTION' | translate
    }}</span>
    <div class="w-full flex flex-col gap-4">
      <div class="flex gap-3 items-center">
        <img
          [src]="getImgUrl(userInfo && userInfo.avatar_thumbnail_url)"
          alt="User avatar"
          class="h-10 w-10 rounded-full border border-palette-gray-200" />
        <span class="text-sm font-semibold text-system-gray-700">{{ userInfo.full_name }}</span>
      </div>
      <span class="w-full flex flex-col gap-[6px] h-fit">
        <label htmlFor="auth-confirm-password-{{ id }}" class="text-sm text-system-gray-700 font-medium"
          >{{ 'SIGNIN_SIGNUP.PASSWORD' | translate }}
        </label>
        <div class="relative">
          <input type="text" style="visibility: hidden" />
          <input
            pInputText
            autofocus
            class="h-11 w-full rounded-lg border focus:ring-0 font-inter text-palette-gray-900 placeholder:text-palette-gray-500 {{
              !password.pristine && (password.hasError('required') || password.hasError('incorrect-password'))
                ? 'border-system-error-300'
                : 'border-palette-gray-300'
            }}"
            id="auth-confirm-password-{{ id }}"
            [formControl]="password"
            placeholder="••••••••"
            [type]="showPassword ? 'text' : 'password'" />
          <img
            *ngIf="password.value"
            [src]="showPassword ? 'assets/icons/eye-closed.svg' : 'assets/icons/eye.svg'"
            alt="eye icon"
            class="text-xl/5 text-palette-gray-500 cursor-pointer absolute top-[35px] right-3"
            (click)="showPassword = !showPassword" />
        </div>
        <span
          *ngIf="!password.pristine && password.hasError('required')"
          class="text-sm text-system-error-600 font-normal"
          >{{ 'SIGNIN_SIGNUP.ERROR_MESSAGES.REQUIRED' | translate }}</span
        >
        <span
          *ngIf="!password.pristine && password.hasError('incorrect-password')"
          class="text-sm text-system-error-600 font-normal"
          >{{ 'SIGNIN_SIGNUP.ERROR_MESSAGES.INCORRECT_PASSWORD' | translate }}</span
        >
      </span>
    </div>
  </div>
</ng-template>

<ng-template #verifyOTPContent let-id="id">
  <ng-container *ngIf="!isBlockedResend; else blockedResendOTP">
    <div class="flex flex-col gap-5" *ngIf="userInfo$ | async as userInfo">
      <span class="text-sm text-system-gray-600 font-normal" *ngIf="email.length; else sentToPhoneDescription">{{
        'SIGNIN_SIGNUP.OTP_CODE_SENT_VIA_EMAIL_DESCRIPTION' | translate: { email: hideEmailPartially() }
      }}</span>
      <ng-template #sentToPhoneDescription>
        <span class="text-sm text-system-gray-600 font-normal">{{
          'SIGNIN_SIGNUP.OTP_CODE_SENT_VIA_PHONE_DESCRIPTION' | translate: { phoneNumber: hidePhonePartially() }
        }}</span>
      </ng-template>
      <div class="w-full">
        <otp-input
          (OTPCode)="OTPCode = $event"
          [(isVerifyCodeFailed)]="isVerifyCodeFailed"
          (resendCode)="resendCode()"></otp-input>
      </div>
    </div>
  </ng-container>
  <ng-template #blockedResendOTP>
    <span class="text-sm text-system-error-600 font-normal">{{
      'SIGNIN_SIGNUP.ERROR_MESSAGES.BLOCKED_RESEND_OTP' | translate
    }}</span>
  </ng-template>
</ng-template>

<ng-template #closeDialogAutomatically>
  <span class="text-sm text-system-gray-600 font-normal">{{
    'SIGNIN_SIGNUP.ERROR_MESSAGES.CLOSE_DIALOG_COUNTDOWN' | translate: { countdown: countdown }
  }}</span>
</ng-template>
