import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FanPageInterfaceState } from './reducer';

const fanPage = createFeatureSelector<FanPageInterfaceState>('fanpages');

export const selectCategory = createSelector(fanPage, state => state.category);
export const selectFollowingFanpage = createSelector(fanPage, state => state.followingFanpageList);
export const selectMyFanpage = createSelector(fanPage, state => state.myFanpageList);
export const selectSuggestedFanpage = createSelector(fanPage, state => state.suggestedFanpageList);
export const selectIsLoadingFollowingFanpages = createSelector(fanPage, state => state.isLoadingFollowingFanpages);
export const selectIsLoadingMyFanpages = createSelector(fanPage, state => state.isLoadingMyFanpages);
export const selectIsLoadingSuggestedFanpages = createSelector(fanPage, state => state.isLoadingSuggestedFanpages);
export const selectAllCategories = createSelector(fanPage, state => state.allCategories);
