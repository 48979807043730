import { Component } from '@angular/core';
import { GroupService } from '@app/core/services/group.service';
import { PostsService } from '@app/core/services/posts.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { PostListPaging } from '@app/lib/api/post/api.post.model';
import { filterPendingPost } from '@app/modules/main/group/store/actions';
import { selectInfoDetailGroup } from '@app/modules/main/group/store/selectors';
import { GROUP_OF_POST_STATUSES, SORT, TIMELINE_OBJECTS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { Store, select } from '@ngrx/store';

export interface PostPendingProps {
  id: string;
  name: string;
  caption: string;
  avatar: string;
  images: string[];
  time: string;
}

export interface PostProps {
  memberName: string;
  memberAvatar: string;
  activeTime: number;
  shortenedCaption: string;
  caption: string;
  images: string[];
  comment: number;
  share: number;
  like: number;
}

@Component({
  selector: 'group-pending-posts',
  templateUrl: './group-pending-posts.component.html',
  styleUrls: ['./group-pending-posts.component.scss']
})
export class GroupPendingPostsComponent {
  pendingPostsPaging: PostListPaging;
  isLoading = false;
  groupId: string | null;
  timelineObject = TIMELINE_OBJECTS.group;
  searchKey = '';

  constructor(
    private groupService: GroupService,
    private postsService: PostsService,
    private toastMessageService: ToastMessageService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectInfoDetailGroup)).subscribe(res => {
      if (res) {
        this.groupId = res.data.group_id;
      }
      this.getPendingPosts();
    });
  }

  getPendingPosts(pageSize = 10, pageNum = 0, sortByDate = SORT.asc): void {
    if (this.groupId) {
      this.isLoading = true;
      this.groupService.getGroupPendingPosts(this.groupId, pageSize, pageNum, sortByDate).subscribe({
        next: res => {
          this.pendingPostsPaging = res;
          this.isLoading = false;
        }
      });
    }
  }

  onSearchPosts(searchKey: string, pageSize = 10, pageNum = 0, sortByDate = SORT.asc): void {
    this.searchKey = searchKey;
    if (this.groupId) {
      this.isLoading = true;
      this.groupService
        .searchGroupPostList(this.groupId, GROUP_OF_POST_STATUSES.pending, searchKey, pageSize, pageNum, sortByDate)
        .subscribe({
          next: res => {
            this.pendingPostsPaging = res;
            this.isLoading = false;
          }
        });
    }
  }

  onReviewPosts(postIds: string[], isApproved: boolean): void {
    this.isLoading = true;
    if (this.groupId) {
      this.postsService.reviewPendingPosts(this.groupId, postIds, isApproved).subscribe({
        next: res => {
          const reviewAction = isApproved ? 'APPROVED' : 'DECLINED';
          const result = res ? 'SUCCESSFUL' : 'FAILED';
          const isMultiPosts = postIds.length > 1 ? 'S' : '';
          const message = `POST_REVIEW.MESSAGE.${reviewAction}_POST${isMultiPosts}_${result}`;
          if (res) {
            this.filterReviewedPost(postIds);
          }
          this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, message);
          this.isLoading = false;
        },
        error: () => (this.isLoading = false)
      });
    }
  }

  filterReviewedPost(postIds: string[]): void {
    this.pendingPostsPaging = {
      ...this.pendingPostsPaging,
      data: this.pendingPostsPaging.data.filter(reviewedPost => !postIds.includes(reviewedPost.id)),
      totalElement: this.pendingPostsPaging.totalElement - postIds.length
    };
    this.store.dispatch(filterPendingPost({ postIds: postIds }));
  }

  onChangedPageSize(pageSize: number): void {
    this.searchKey = '';
    this.getPendingPosts(pageSize);
  }

  onPageChange(event: any): void {
    const pageNum = event.page;
    const pageSize = event.rows;
    this.onSearchPosts(this.searchKey, pageSize, pageNum);
  }
}
