import { PostRequest } from '@app/lib/api/post/api.post.model';
import { FileWithPreview } from '@app/shared/components/create-post-dialog/post-dialog/post-dialog.component';
import { Post } from '@app/shared/models/post';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CreatePostActions = createActionGroup({
  source: 'Create Post',
  events: {
    'On Upload Post': props<{
      formData: FormData;
      currentPage: string;
      dataCreate: any;
      isPostShare: boolean;
      isImmediatelyShownPost: boolean;
      localLink: FileWithPreview[];
    }>(),
    'On Create Post': props<{ post: PostRequest; isSharePost: boolean }>()
  }
});

export const CreatePostApiActions = createActionGroup({
  source: ' Create Post Api',
  events: {
    'On Load Create Post Success': props<{ post: Post }>(),
    'On Load Create Post Fail': props<{ message: string }>(),
    'On Reset Data Create Post': emptyProps(),
    'On Set Status Post': props<{ isCreatingPost: boolean }>(),
    'On Set Loading Create Post': props<{ isLoadingCreatePost: boolean }>()
  }
});
