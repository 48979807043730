<!-- // TODO: temporarily update ads display behavior -->
<!-- <div *ngIf="!imgPanel1?.length; else image1">
  <div class="w-full aspect-[8/3]"><p-skeleton height="100%"></p-skeleton></div>
</div>
<ng-template #image1>
  <div
    class="w-full aspect-[8/3]"
    [ngClass]="{ 'cursor-pointer': !!dataPanel1?.destination_url }"
    (click)="handleNavigate(dataPanel1, adsCategory.adsPanel1)">
    <img
      (load)="onImageLoad(dataPanel1, adsCategory.adsPanel1)"
      defaultImage
      class="w-full object-cover rounded max-h-[155px]"
      [src]="baseUrl + imgPanel1" />
  </div>
</ng-template> -->
<div *ngIf="!imgPanel2?.length; else image2">
  <div class="w-full aspect-square"><p-skeleton height="100%"></p-skeleton></div>
</div>
<ng-template #image2>
  <!-- (click)="handleNavigate(dataPanel2, adsCategory.adsPanel2)" -->
  <!-- [ngClass]="{ 'cursor-pointer': !!dataPanel2?.destination_url }" -->
  <a
    class="w-full aspect-square cursor-pointer"
    href="https://shop.soctrip.com/shopping/Ve-buoi-bieu-dien-Arrival-from-Sweden-Am-nhac-cua-ABBA-The-Music-of-ABBA-/6f5cd390-6e9d-11ef-827f-839a0089691d/6f5cd390?utm_medium=Banner&utm_source=soctrip&utm_campaign=cpc&utm_content=social&utm_term=sep"
    target="_blank">
    <img
      (load)="onImageLoad(dataPanel2, adsCategory.adsPanel2)"
      defaultImage
      class="w-full max-h-full object-cover rounded"
      [src]="baseUrl + imgPanel2" />
  </a>
</ng-template>
<div class="flex flex-col gap-3">
  <div
    *ngIf="!hideSponsorSection && (dataSponsored1?.id || dataSponsored2?.id || isLoadingSponsored)"
    class="text-lg font-semibold">
    {{ 'HOME.SPONSORED_BY' | translate }}
  </div>
  <div class="flex flex-col gap-3">
    <ng-container
      *ngIf="dataSponsored1?.id || isLoadingSponsored"
      [ngTemplateOutlet]="sponsored"
      [ngTemplateOutletContext]="{ data: dataSponsored1, isLoading: isLoadingSponsored }">
    </ng-container>
    <ng-container
      *ngIf="dataSponsored2?.id || isLoadingSponsored"
      [ngTemplateOutlet]="sponsored"
      [ngTemplateOutletContext]="{ data: dataSponsored2, isLoading: isLoadingSponsored }">
    </ng-container>
  </div>
  <div class="flex flex-col gap-3">
    <div class="aboutus flex gap-[6px] items-center">
      <div class="text-sm cursor-pointer" [routerLink]="'/help-center/about-us'">{{ 'HOME.ABOUT_US' | translate }}</div>
      <i class="pi pi-circle-fill text-[5px] text-[#667085]"></i>
      <div class="text-sm cursor-pointer" [routerLink]="'/help-center/term'">{{ 'HOME.TERMS' | translate }}</div>
      <i class="pi pi-circle-fill text-[5px] text-[#667085]"></i>
      <div class="text-sm cursor-pointer" [routerLink]="'/help-center/privacy'">
        {{ 'COMMON.PRIVACY.PRIVACY' | translate }}
      </div>
    </div>
    <div class="flex gap-4">
      <div class="flex gap-1">
        <i class="sctr-icon-globe-01 text-xs mt-1"></i>
        <div class="text-sm cursor-pointer" (click)="showLanguageSetting()">
          {{ currentLanguage | translate }}
        </div>
      </div>
      <div class="text-sm">
        {{ 'COPYRIGHT_TEXT' | translate: { currentYear: currentYear, companyName: companyName } }}
      </div>
    </div>
  </div>
</div>

<ng-template #sponsored let-data="data" let-isLoading="isLoading">
  <ng-container *ngIf="!isLoading">
    <div
      class="flex gap-3"
      [ngClass]="{ 'cursor-pointer': !!data.destination_url }"
      *ngIf="data"
      (click)="handleNavigate(data, adsCategory.adsSponsoredBy)">
      <div class="w-28 min-w-[112px] h-28 rounded-md bg-palette-gray-50 overflow-clip">
        <img
          (load)="onImageLoad(data, adsCategory.adsSponsoredBy)"
          defaultImage
          class="w-full h-full max-w-[112px] aspect-square"
          [src]="baseUrl + data.imageUrl" />
      </div>
      <div class="gap-1">
        <h1 class="text-palette-gray-900 font-semibold text-base line-clamp-2" [title]="data?.title | translate">
          {{ data?.title | translate }}
        </h1>
        <span class="text-palette-gray-500 font-normal text-sm line-clamp-3" [title]="data?.description | translate">
          {{ data?.description | translate }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <loading-skeleton></loading-skeleton>
  </ng-container>
</ng-template>
