<div class="flex w-full relative calendar-event">
  <p-calendar
    styleClass="h-11"
    [ngModel]="date"
    [selectOtherMonths]="true"
    (onSelect)="onDateSelect($event)"
    appendTo="body"
    dateFormat="{{ 'COMMON.CALENDER_FORMAT' | translate }}"
    [minDate]="minStartDate"
    class="absolute w-full custom-calendar"
    [readonlyInput]="true"
    placeholder="{{ 'EVENT.DATE' | translate }}">
    <ng-template pTemplate="header" class="h-0 w-full">
      <div class="flex gap-3 relative z-[9999] top-[60px] px-4">
        <input
          class="w-full pl-3.5"
          type="text"
          pInputText
          [(ngModel)]="inputDate"
          (keyup.enter)="selectDate()"
          placeholder="{{ 'COMMON.DATE_TIME.ENTER_DATE' | translate }}" />
        <button
          class="flex-shrink-0 justify-center items-center rounded-lg py-2.5 px-4 text-palette-gray-700 border border-palette-gray-300 text-sm font-semibold"
          (click)="selectToday()">
          {{ 'COMMON.DATE_TIME.TODAY' | translate }}
        </button>
      </div>
    </ng-template>
  </p-calendar>
  <span class="cursor-pointer absolute left-[14px] top-[9px]"><i class="sctr-icon-calendar text-lg"></i></span>
</div>
