import { Pipe, PipeTransform } from '@angular/core';
import { POST_TABLE_STYLE } from '../constant';

@Pipe({
  name: 'abbreviate'
})
export class AbbreviateNamePipe implements PipeTransform {
  transform(value: string, type?: string): string {
    if (!value || !value.trim()) {
      return type && type === POST_TABLE_STYLE.fanpage ? 'FP' : 'G';
    }

    const words = value.trim().split(' ');

    if (words.length === 1) {
      return words[0][0].toLocaleUpperCase();
    } else if (words.length > 1) {
      return `${this.getFirstCharacter(words[0])}${this.getFirstCharacter(words[1])}`;
    } else {
      return '';
    }
  }

  getFirstCharacter(str: string): string {
    // Get the code point of the first character
    let codePoint = str.codePointAt(0) || 0;
    // Convert the code point to a string
    const firstChar = String.fromCodePoint(codePoint);
    return firstChar.toLocaleUpperCase();
  }
}
