<p-dialog
  [(visible)]="isDialogVisible"
  [styleClass]="'w-[792px] h-[690px] rounded-xl overflow-hidden custom-quick-summary'"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  (onHide)="onHide()">
  <ng-template pTemplate="header">
    <span class="text-lg font-semibold text-palette-gray-900">{{
      'QUICK_ACCESS.CUSTOMIZE_QUICK_ACCESS' | translate
    }}</span>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex">
      <div class="w-1/2">
        <div class="mb-2 text-palette-gray-700 font-semibold text-base">
          {{ 'QUICK_ACCESS.CHOOSE_MENUS' | translate }}
        </div>
        <div class="w-full p-[6px] h-[488px] flex flex-col gap-5 quick-access border rounded-lg">
          <ng-container *ngFor="let item of listQuickAccess">
            <div class="ml-[10px] gap-2 flex items-center justify-between">
              <div class="gap-2 flex items-center">
                <ng-container
                  [ngTemplateOutlet]="quickAccessInfo"
                  [ngTemplateOutletContext]="{
                    accessItem: item
                  }"></ng-container>
              </div>
              <i
                class="sctr-icon-solid-plus-circle text-xl text-palette-emerald-500 cursor-pointer"
                (click)="addToDisplayList(item)"></i>
            </div>
          </ng-container>
        </div>
      </div>
      <p-divider class="mx-6" layout="vertical"></p-divider>
      <div class="w-1/2">
        <div class="mb-2 text-palette-gray-700 font-semibold text-base">
          {{ 'QUICK_ACCESS.DISPLAY_MENUS' | translate }}
        </div>
        <div class="w-full p-[6px] flex flex-col gap-5 h-[488px] border rounded-lg quick-access">
          <ng-container *ngFor="let item of listDisplayQuickAccess">
            <div class="ml-[10px] gap-2 flex items-center justify-between">
              <div class="gap-2 flex items-center">
                <ng-container
                  [ngTemplateOutlet]="quickAccessInfo"
                  [ngTemplateOutletContext]="{
                    accessItem: item
                  }"></ng-container>
              </div>
              <div *ngIf="listDisplayQuickAccess.length > 1"
                class="cursor-pointer text-palette-gray-700 flex justify-center items-center text-[10px] font-semibold h-[18px] pb-[1px] w-[18px] rounded-full bg-palette-gray-300"
                (click)="removeFromDisplayList(item)">
                —
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      [styleClass]="
        'px-5 rounded-lg border-palette-gray-300 hover:bg-palette-gray-200 y-3 text-palette-gray-900 font-semibold text-sm'
      "
      [label]="'COMMON.BUTTON_ACTION.CANCEL' | translate"
      (onClick)="onHide()"
      styleClass="p-button-text"></p-button>
    <p-button
      (onClick)="applyDisplayQuickAccess()"
      [styleClass]="
        'px-[20px] rounded-lg bg-palette-blue-600 hover:bg-palette-blue-700 border-palette-gray-300 y-3 text-palette-base-white font-semibold text-sm'
      "
      [label]="'COMMON.BUTTON_ACTION.CONFIRM' | translate"
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<ng-template #quickAccessInfo let-item="accessItem">
  <div *ngIf="!['ENTITY.GROUPS', 'ENTITY.LIVESTREAMS'].includes(item.name); else imgTag" class="{{ item.color }}">
    <i class="{{ item.icon }} text-xl"></i>
  </div>
  <ng-template #imgTag>
    <img [src]="item.image" class="w-5 h-5" />
  </ng-template>
  <div>{{ item.name | translate }}</div>
</ng-template>
