import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, ViewContainerRef } from '@angular/core';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { ApiClient } from '@app/lib/api/api-client';
import { ExistMediaRequest, MediaApiModels } from '@app/lib/api/media/api.media.models';
import { StorageApiModels } from '@app/lib/api/storage/api.storage.models';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import {
  FILE_TYPES,
  LIBRARY_TARGET_TYPES,
  MEDIA_FILE_FORMAT_LIMITS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  isUpdateVolume: boolean = false;
  baseURL = environment.baseURL;
  private api: ApiClient;
  userInfo$ = this.store.select(selectUserInfo);
  private progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public downloadPercent$: Observable<number> = this.progressSubject.asObservable();
  isDownloading = false;
  volumeValue: BehaviorSubject<number> = new BehaviorSubject<number>(
    JSON.parse(localStorage.getItem('muted-state') || 'true') ? 0 : 1
  );
  volumeState = 0;
  previousVolume = 0;
  isScaleVideo = false;
  unScaleVideo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  originalScale = '';
  viewContainerRef: ViewContainerRef;
  isShowChatBox = false;

  constructor(
    private toastMessageService: ToastMessageService,
    private http: HttpClient,
    private store: Store
  ) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  handleErrorResponse(res: any, customMessage?: string): any {
    if (!res.success) {
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.error,
        customMessage ? customMessage : res.error.message
      );
    }
  }

  getAlbumList(libraryId: string, targetType: number): Observable<MediaApiModels.Album[]> {
    let targetID = libraryId;
    if (targetType === LIBRARY_TARGET_TYPES.user) {
      this.userInfo$.subscribe(res => {
        if (res && res.id) {
          targetID = res.id === libraryId ? '' : libraryId;
        }
      });
    }
    return this.api.media.getAlbumList(targetID, targetType).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  createAlbum(albumName: string, libraryId: string, albumDescription?: string): Observable<MediaApiModels.Album> {
    return this.api.media.createAlbum(albumName, libraryId, albumDescription).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateAlbum(
    albumId: string,
    albumName: string,
    libraryId: string,
    targetType: number
  ): Observable<MediaApiModels.Album> {
    const body = libraryId
      ? { name: `${albumName.trim()}`, target_id: `${libraryId}` }
      : { name: `${albumName.trim()}` };
    return this.api.media.updateAlbum(albumId, body, targetType).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateAlbumPostId(
    albumId: string,
    postId: string,
    libraryId: string,
    targetType: number
  ): Observable<MediaApiModels.Album> {
    const body = libraryId
      ? { post_id: `${postId.trim()}`, target_id: `${libraryId}` }
      : { post_id: `${postId.trim()}` };
    return this.api.media.updateAlbum(albumId, body, targetType).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteAlbum(albumId: string, libraryId: string, targetType: number): Observable<string> {
    return this.api.media.deleteAlbum(albumId, libraryId, targetType).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return albumId;
      })
    );
  }

  getMediaList(albumId: string, libraryId: string, targetType: number): Observable<MediaApiModels.AlbumWithMedia> {
    let targetID = libraryId;
    if (targetType === LIBRARY_TARGET_TYPES.user) {
      this.userInfo$.subscribe(res => {
        if (res && res.id) {
          targetID = res.id === libraryId ? '' : libraryId;
        }
      });
    }
    return this.api.media.getMediaList(albumId, targetID, targetType).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getImages(libraryId: string, targetType: number): Observable<MediaApiModels.Media[]> {
    let targetID = libraryId;
    if (targetType === LIBRARY_TARGET_TYPES.user) {
      this.userInfo$.subscribe(res => {
        if (res && res.id) {
          targetID = res.id === libraryId ? '' : libraryId;
        }
      });
    }
    return this.api.media.getRecentImages(targetID, targetType).pipe(
      map((res: any) => {
        return res.data.content;
      })
    );
  }

  uploadFilesToAlbum(
    albumId: string,
    files: FormData,
    libraryId: string
  ): Observable<MediaApiModels.UploadedMediaList> {
    return this.api.media.uploadFilesToAlbum(albumId, files, libraryId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res, 'ERROR.LIBRARY.UPLOAD_FILE_FAILED');
        if (res.success) {
          const UploadedMediaList = {
            albumId: albumId,
            libraryId: libraryId,
            uploadedFiles: res.data
          };
          return UploadedMediaList;
        }
        return res.data;
      })
    );
  }

  uploadFileToAlbum(
    albumId: string,
    file: FormData,
    fileDescription: string,
    libraryId: string
  ): Observable<MediaApiModels.Media> {
    return this.api.media.uploadFileToAlbum(albumId, file, fileDescription, libraryId).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateMediaDescription(mediaId: string, description: string): Observable<StorageApiModels.FileInfo> {
    return this.api.storage.updateMediaDescription(mediaId, description).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  updateDownloadProgress(newData: number): void {
    this.progressSubject.next(newData);
  }

  downloadAlbum(albumId: string, libraryId: string, targetType: number): void {
    if (!this.isDownloading) {
      this.isDownloading = true;
      let targetID = libraryId;
      if (targetType === LIBRARY_TARGET_TYPES.user) {
        this.userInfo$.subscribe(res => {
          if (res && res.id) {
            targetID = res.id === libraryId ? '' : libraryId;
          }
        });
      }
      this.api.media.getMediaList(albumId, targetID, targetType).subscribe({
        next: res => {
          const albumName = res.data?.name || 'Downloaded_Album';
          const files = res.data?.files || [];
          let fileIdList: any = [];
          let totalSize = 0;
          if (files.length) {
            this.updateDownloadProgress(0);
            for (let file of files) {
              fileIdList = fileIdList.concat(file.id);
              totalSize = totalSize + (file.size ? file.size : 0);
            }
            const downloadBody = { files: fileIdList, name: albumName };
            this.api.storage.downloadFiles(downloadBody).subscribe(event => {
              if (event.type === HttpEventType.DownloadProgress) {
                const progressPercent = Math.round((100 * event.loaded) / totalSize);
                this.updateDownloadProgress(progressPercent);
              } else if (event instanceof HttpResponse) {
                this.updateDownloadProgress(100);
                this.isDownloading = false;
                const res: Blob = event.body;
                const blob = new Blob([res], { type: 'application/zip' });
                const fileURL = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = fileURL;
                link.download = downloadBody.name || 'Downloaded file';
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(fileURL);
                document.body.removeChild(link);
              }
            });
          } else {
            this.updateDownloadProgress(100);
            this.isDownloading = false;
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.warn,
              'ERROR.LIBRARY.NO_FILES_AVAILABLE_FOR_DOWNLOAD'
            );
          }
        },
        error: () => {
          this.updateDownloadProgress(100);
          this.isDownloading = false;
        }
      });
    } else {
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.warn,
        'LIBRARY.INFO_MESSAGES.WAIT_UNTIL_CURRENT_DOWNLOAD_FINISHES'
      );
    }
  }

  downloadMedia(media: MediaApiModels.Media): void {
    if (!this.isDownloading) {
      this.isDownloading = true;
      const totalSize = media.size | 0;
      this.api.media.downloadMedia(media).subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          const progressPercent = Math.round((100 * event.loaded) / totalSize);
          this.updateDownloadProgress(progressPercent);
        } else if (event instanceof HttpResponse) {
          this.updateDownloadProgress(100);
          this.isDownloading = false;
          const res: Blob = event.body;
          const blob = new Blob([res], { type: media.type });
          const fileURL = URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = fileURL;
          link.download = media.original_name || 'Downloaded file';
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(link);
        }
      });
    } else {
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.warn,
        'LIBRARY.INFO_MESSAGES.WAIT_UNTIL_CURRENT_DOWNLOAD_FINISHES'
      );
    }
  }

  deleteMediaList(mediaListId: string[], albumId: string, libraryId: string, targetType: number): Observable<string[]> {
    return this.api.media.deleteMediaList(mediaListId, albumId, libraryId, targetType).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        if (res.success) {
          return mediaListId;
        }
        return res.data;
      })
    );
  }

  uploadFile(formData: FormData): Observable<any> {
    return this.api.storage.uploadFile(formData);
  }

  uploadFilePanel(blob: Blob): Observable<any> {
    return this.api.storage.uploadFilePanel(blob);
  }

  getFileSizeLimit(fileType: string): number {
    const fileSizeLimit = environment.FILE_SIZE_LIMITS;
    switch (true) {
      case MEDIA_FILE_FORMAT_LIMITS.image.includes(fileType):
        return fileSizeLimit.image;
      case MEDIA_FILE_FORMAT_LIMITS.video.includes(fileType):
        return fileSizeLimit.video;
      case MEDIA_FILE_FORMAT_LIMITS.pdf.includes(fileType):
        return fileSizeLimit.pdf;
      default:
        return fileSizeLimit.default;
    }
  }

  uploadFileValidation(
    file: File,
    fileLimits = Object.values(MEDIA_FILE_FORMAT_LIMITS).reduce((acc, fileFormats) => acc.concat(fileFormats), []),
    rejectFileTypeMessage = 'ERROR.LIBRARY.INVALID_FILE_TYPE',
    rejectFileSizeMessage = 'ERROR.LIBRARY.EXCEED_FILE_SIZE_LIMIT'
  ): boolean {
    const fileSize = file.size;
    const fileType = file.type;
    if (!fileLimits.includes(fileType)) {
      this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, rejectFileTypeMessage);
      return false;
    } else if (fileSize > this.getFileSizeLimit(fileType)) {
      this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, rejectFileSizeMessage);
      return false;
    }
    return true;
  }

  countFilesByTypes(files: any): { image: number; video: number } {
    let numberOfPhotos = 0;
    let numberOfVideos = 0;
    if (files) {
      for (let file of files) {
        if ((file?.type || '').toLowerCase().includes(FILE_TYPES.image)) {
          numberOfPhotos++;
        }
        if ((file?.type || '').toLowerCase().includes(FILE_TYPES.video)) {
          numberOfVideos++;
        }
      }
      return { image: numberOfPhotos, video: numberOfVideos };
    }
    return { image: 0, video: 0 };
  }

  getAllLibraries(targetId: string, targetType: string, pageNum: number, pageSize: number, previewImageSize: number): Observable<any> {
    return this.api.media.getAllLibraries(targetId, targetType, pageNum, pageSize, previewImageSize);
  }

  getLibraryFiles(id: string, pageNum: number, pageSize: number): Observable<any> {
    return this.api.media.getLibraryFiles(id, pageNum, pageSize);
  }

  updateExistCover(body: ExistMediaRequest): Observable<any> {
    return this.api.media.updateExistCover(body);
  }

  updateExistAvatar(body: ExistMediaRequest): Observable<any> {
    return this.api.media.updateExistAvatar(body);
  }
}
