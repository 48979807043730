import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { EventImg } from '@app/shared/components/media/media.component';
import { MAX_TRUNCATED_TEXT_HEIGHT, MEDIA_TYPE } from '@app/shared/constant';
import { FILE_TYPE_URL, LinkInfo, Media, checkUrl } from '@app/shared/models/post';

@Component({
  selector: 'post-content-link',
  templateUrl: './post-content-link.component.html',
  styleUrls: ['./post-content-link.component.scss']
})
export class PostContentLinkComponent implements AfterViewInit, OnInit {
  @Input() data: LinkInfo;
  @Input() shareLinkType: string;
  @Input() shareLink = '';
  mediaList: Media[] = [];
  descriptionHeight = 0;
  activeIndex: number;
  displayFullImage = false;
  isShowAllContent = false;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  MEDIA_TYPE = MEDIA_TYPE;

  constructor(
    private el: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data.array_images) {
      this.data.array_images.forEach(item => {
        this.mediaList.push({
          type: MEDIA_TYPE.image,
          thumbnail: item,
          original: item
        });
      });
    }
  }

  ngAfterViewInit(): void {
    this.descriptionHeight = this.el.nativeElement.querySelector('.post-description')?.offsetHeight | 0;
    this.cdRef.detectChanges();
  }

  navigatePostOriginal() {
    if (!this.shareLink.startsWith('https://')) {
      this.shareLink = 'https://' + this.shareLink;
    }
    window.open(this.shareLink, '_blank');
  }

  validUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    return checkUrl(urlString, style);
  }

  clickImage(event: EventImg) {
    this.displayFullImage = true;
    this.activeIndex = event.i;
  }

  onExpandText() {
    this.isShowAllContent = !this.isShowAllContent;
  }
}
