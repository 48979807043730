import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl = environment.baseURL;

  constructor(private translateService: TranslateService) {}

  removeLoginBanner() {
    const loginBanner = document.getElementsByTagName('login-banner');
    loginBanner[0].parentNode?.removeChild(loginBanner[0]);
  }

  getDateFormatByLang() {
    const lang = this.translateService.currentLang || 'en';
    const dateFormats: any = {
      'en': 'MM/dd/yyyy',
      'vi': 'dd/MM/yyyy'
    };

    return dateFormats[lang.toLowerCase()];
  }

  checkStringDate(dateString: string) {
    // Regular expressions to match DD/MM/yyyy and MM/dd/yyyy formats
    const ddmmyyyyRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const mmddyyyyRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    return ddmmyyyyRegex.test(dateString) || mmddyyyyRegex.test(dateString);
  }

  convertStringToDate(dateString: string) {
    const format = this.getDateFormatByLang();
    let day, month, year;
    const parts = dateString.split('/');
    if (parts !== null) {
      switch (format) {
        case 'dd/MM/yyyy':
          day = +parts[0];
          month = +parts[1];
          year = +parts[2];
          break;
        case 'MM/dd/yyyy':
        default:
          day = +parts[1];
          month = +parts[0];
          year = +parts[2];
          break;
      }
      return new Date(year, month - 1, day);
    }
    return null;
  }

  getImageUrl(id: string | undefined): string {
    if (!id) {
      return '';
    }
    const isPath = id.startsWith('/');
    const webPath = isPath ? '' : '/storage/files/web/';
    const addWebp = isPath ? '' : '.webp';

    return `${this.apiUrl}${webPath}${id}${addWebp}`;
  }
}
