import { Component, HostListener } from '@angular/core';
import { environment } from '@env/environment';
import { AppConnectMessageType } from './core/enums/app-connect.enum';
import { AuthService } from './core/services/auth.service';
import { BillingService } from './core/services/billing.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  show: any;
  isEmptyLayout: boolean;
  isInitTranslate = false;

  constructor(
    private authService: AuthService,
    private billingService: BillingService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.googleAnalyticsService.initialize();
    // Set currency and language for anonymous when init
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
    if (!isLoggedIn) {
      const currency = localStorage.getItem('currency-conversation-data');
      const language = localStorage.getItem('unauthLang');
      if (!currency || !language) {
        this.authService.getDeviceInfo().subscribe((res) => {
          if (!currency) {
            this.billingService.getExchangeRateFromUSD(res.currency).subscribe((r) => {
              if (res) {
                const currencyData = {
                  rate: r,
                  currency: res.currency,
                  expiry: new Date().getTime() + 48 * 60 * 60 * 1000,
                  hash: res.currency + r + new Date().getTime() + 48 * 60 * 60 * 1000
                };
                localStorage.setItem('currency-conversation-data', JSON.stringify(currencyData));
              }
            });
          }
          if (!language) localStorage.setItem('unauthLang', res.language_code.toLowerCase());
        });
      }
    }
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (event.origin === environment.CONTENT_APPROVAL_URL) {
      switch (event.data.type) {
        case AppConnectMessageType.ADD_OWNER_USER_ID:
          if (!event.data.ownerUserId || !event.data.tokenToLoadIframe) {
            event.source.postMessage(
              {
                type: AppConnectMessageType.ADD_OWNER_USER_ID_FAIL
              },
              { targetOrigin: event.origin }
            );
          } else {
            sessionStorage.setItem(environment.OWNER_USER_ID, event.data.ownerUserId);
            sessionStorage.setItem(
              environment.TOKEN_KEY,
              JSON.stringify({ accessToken: event.data.tokenToLoadIframe })
            );
            event.source.postMessage(
              {
                type: AppConnectMessageType.ADD_OWNER_USER_ID_SUCCESS
              },
              { targetOrigin: event.origin }
            );
          }
          break;
        default:
          break;
      }
    }
  }
}
