<div class="hidden md:block">
  <div class="flex flex-col gap-2 bg-palette-gray-200">
    <ng-container [ngTemplateOutlet]="About"></ng-container>
    <ng-container [ngTemplateOutlet]="ContactInformation"></ng-container>
  </div>
</div>

<div class="block md:hidden w-full h-full absolute top-0 z-20 bg-palette-base-white pt-14">
  <ng-container class="w-full h-full">
    <ng-container *ngIf="!isActiveGroupInfo">
      <div
        class="w-full h-14 rounded-b-2xl shadow-[0_1px_3px_0_rgba(16,24,40,0.1)] p-2 flex justify-between items-center text-lg font-semibold text-palette-gray-800 bg-palette-base-white fixed top-0 z-50">
        <div class="h-10 w-10 cursor-pointer flex items-center justify-center" (click)="onBackToGroupSettings()">
          <i class="sctr-icon-chevron-left text-xl"></i>
        </div>
        <span> {{ 'GROUP.GENERAL_INFORMATION' | translate }} </span>
        <div class="h-10 w-10"></div>
      </div>
      <div class="menuItems w-full flex flex-col rounded-md border-palette-gray-200">
        <div class="py-3 md:py-6 px-0 md:px-4 flex flex-col gap-1" *ngIf="activeIndex >= 0">
          <ng-container
            [ngTemplateOutlet]="menuItem"
            [ngTemplateOutletContext]="{
              groupSettingOption: groupInfoSettingOption.about
            }"></ng-container>
          <ng-container
            [ngTemplateOutlet]="menuItem"
            [ngTemplateOutletContext]="{
              groupSettingOption: groupInfoSettingOption.contactInformation
            }"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container class="w-full h-full">
    <ng-container *ngIf="isActiveGroupInfo" class="mt-16">
      <div
        class="w-full h-14 rounded-b-2xl shadow-[0_1px_3px_0_rgba(16,24,40,0.1)] p-2 flex justify-between items-center text-lg font-semibold text-palette-gray-800 mb-1 bg-palette-base-white fixed top-0 z-50">
        <div class="h-10 w-10 cursor-pointer flex items-center justify-center" (click)="onBackToGroupInfoSettings()">
          <i class="sctr-icon-chevron-left text-xl"></i>
        </div>
        <span>
          {{ 'GROUP.' + selectedGroupInfoSetting | translate }}
        </span>
        <div class="h-10 w-10"></div>
      </div>
      <div class="mb-4 flex flex-col gap-2 bg-white">
        <ng-container *ngIf="activeIndex === groupInfoSettingOption.about.id" [ngTemplateOutlet]="About"></ng-container>
        <ng-container
          *ngIf="activeIndex === groupInfoSettingOption.contactInformation.id"
          [ngTemplateOutlet]="ContactInformation"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #menuItem let-groupSettingOption="groupSettingOption">
  <button
    class="h-12 py-2.5 px-[14px] gap-x-2 text-base font-semibold flex items-center justify-between text-palette-gray-700"
    (click)="selectedSetting(groupSettingOption.id, groupSettingOption.title)">
    <div class="flex items-center gap-x-2">
      <i class="{{ groupSettingOption.icon }} text-xl"></i>
      {{ 'GROUP.' + groupSettingOption.title | translate }}
    </div>
    <div class="flex items-center text-center gap-1 text-palette-gray-600">
      <i class="block md:hidden sctr-icon-chevron-right text-xl"></i>
    </div>
  </button>
</ng-template>

<ng-template #About>
  <p-card
    class="remove-default-padding-pcard"
    styleClass="p-4 rounded-md shadow-none bg-white md:shadow-sm md:border border-palette-gray-200">
    <div class="hidden md:block">
      <span class="text-lg font-semibold flex pb-4 text-palette-gray-900">{{ 'GROUP.ABOUT' | translate }}</span>
    </div>
    <div class="flex flex-col gap-4" *ngIf="groupInfo">
      <div class="flex md:items-center gap-2 flex-col md:flex-row">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'GROUP.GROUP_NAME' | translate }}</span>
        <div class="flex flex-col gap-2 w-full">
          <input
            type="text"
            pInputText
            [(ngModel)]="group_name"
            required
            (input)="onInputChange()"
            class="w-full rounded-lg text-palette-gray-900" />
          <div *ngIf="!group_name" class="text-red-500">{{ 'GROUP.WARNINGS.PLEASE_ENTER_GROUP_NAME' | translate }}</div>
        </div>
      </div>
      <div class="flex md:items-start gap-2 flex-col md:flex-row">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'GROUP.DESCRIPTION' | translate }}</span>
        <textarea
          pInputTextarea
          type="text"
          [(ngModel)]="description"
          (input)="onInputChange()"
          [placeholder]="'GROUP.PLEASE_ENTER_THE_DESCRIPTION' | translate"
          class="h-40 w-full rounded-lg text-palette-gray-900">
        </textarea>
      </div>
      <div class="flex md:items-center gap-2 flex-col md:flex-row">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'COMMON.PRIVACY.PRIVACY' | translate }}</span>
        <p-dropdown
          [options]="groupPrivacyOptions"
          [(ngModel)]="selectedPrivacy"
          optionLabel="label"
          [ngModelOptions]="{ standalone: true }"
          class="w-full"
          styleClass="rounded-lg w-full text-base font-medium text-palette-gray-900"
          [disabled]="groupInfo.privacy === GROUP_PRIVACY_CONTEXTS.private.value ? true : false"
          (onChange)="onPrivacyChange($event.value)">
          <ng-template let-option pTemplate="selectedItem">
            <div class="flex w-full items-center gap-2">
              <i class="{{ option.icon }} text-palette-gray-500"></i>
              <span class="text-palette-gray-900 font-normal font-inter">{{
                'PRIVACY.' + option.label.toUpperCase() | translate
              }}</span>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex items-center justify-between w-full">
              <div class="flex w-full items-center gap-2">
                <i class="{{ option.icon }} text-palette-gray-500"></i>
                <span class="text-palette-gray-900 font-normal font-inter">{{
                  'PRIVACY.' + option.label.toUpperCase() | translate
                }}</span>
              </div>
              <i
                *ngIf="selectedPrivacy.value === option.value"
                id="check-icon"
                class="sctr-icon-check text-palette-blue-600"></i>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="flex md:items-center gap-2 flex-col md:flex-row custom-multiselect">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'GROUP.LOCATION' | translate }}</span>
        <p-multiSelect
          #multiSelectLocation
          class="w-full max-w-[674px]"
          styleClass="rounded-lg w-full text-base font-medium text-palette-gray-900 h-10"
          appendTo="body"
          [options]="locationItems"
          [optionDisabled]="'district_disable'"
          [placeholder]="'ADVERTISING.GROUP.SELECT_LOCATIONS' | translate"
          [filterBy]="handleFilterBy(locationTypes)"
          [showToggleAll]="false"
          [emptyMessage]="'COMMON.NO_RESULTS_FOUND' | translate"
          [emptyFilterMessage]="'COMMON.NO_RESULTS_FOUND' | translate"
          (onChange)="handleLocationSelected($event)"
          (input)="onInputChange()">
          <ng-template let-value pTemplate="selectedItems">
            <ng-container *ngIf="selectedLocations && selectedLocations.length">
              <div class="inline-flex align-items-center gap-2 pl-1 first:pl-0" *ngFor="let item of selectedLocations">
                <ng-container *ngIf="item.district_name">{{ item.district_name }},</ng-container>
                <ng-container *ngIf="item.province_name">{{ item.province_name }},</ng-container>
                <ng-container *ngIf="item.country_name && !item.province_name">{{ item.country_name }}</ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="!selectedLocations || !selectedLocations.length">
              {{ 'ADVERTISING.GROUP.SELECT_LOCATIONS' | translate }}
            </ng-container>
            <button *ngIf="selectedLocations.length || selectedLocations">
              <i class="sctr-icon-x-close" (click)="clearSelectedLocations()"></i>
            </button>
          </ng-template>
          <ng-template let-location pTemplate="item">
            <ng-container *ngIf="location">
              <div [ngSwitch]="locationTypes" class="flex align-items-center gap-2">
                <div *ngSwitchCase="'Country'">{{ location.country_name }}</div>
                <div *ngSwitchCase="'Province'">{{ location.province_name }}</div>
                <div *ngSwitchCase="'District'">{{ location.district_name }}</div>
              </div>
            </ng-container>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <p-footer
      class="w-full fixed bottom-0 left-0 md:relative md:flex flex gap-3 justify-between md:justify-end p-4 md:p-0 pb-8 md:pb-0 bg-white">
      <div class="w-[calc((100%-12px)/2)] md:w-fit">
        <p-button
          (click)="onBackToGroupInfoSettings(); handleAboutCancel()"
          [disabled]="!groupDataChangedAbout"
          styleClass="cancel-btn w-full h-11 px-[18px] py-[10px] rounded-lg font-semibold text-base text-palette-gray-700 border border-palette-gray-300 bg-white hover:bg-palette-gray-100"
          label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"></p-button>
      </div>
      <div class="w-[calc((100%-12px)/2)] md:w-fit">
        <p-button
          (click)="handleAboutSave()"
          [disabled]="isInfoChanged()"
          [loading]="isLoadingAboutSave"
          loadingIcon="pi-spin pi sctr-icon-loading-02"
          styleClass="save-btn w-full h-11 px-[18px] py-[10px] rounded-lg font-semibold text-base text-white border border-branding-primary-600 bg-branding-primary-600 hover:bg-branding-primary-700"
          label="{{ 'COMMON.BUTTON_ACTION.SAVE' | translate }}"></p-button>
      </div>
    </p-footer>
  </p-card>
</ng-template>

<ng-template #ContactInformation>
  <p-card
    class="remove-default-padding-pcard"
    styleClass="p-4 rounded-md shadow-none bg-white md:shadow-sm md:border border-palette-gray-200">
    <div class="hidden md:block">
      <span class="text-lg font-semibold flex pb-4 text-palette-gray-900">{{
        'GROUP.CONTACT_INFORMATION' | translate
      }}</span>
    </div>
    <div class="flex flex-col gap-4" *ngIf="groupContactInfo">
      <div class="flex md:items-center gap-2 flex-col md:flex-row">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'GROUP.EMAIL' | translate }}</span>
        <div class="flex flex-col gap-2 w-full">
          <input
            pInputText
            type="text"
            [(ngModel)]="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            placeholder="{{ 'GROUP.PLEASE_ENTER_A_VALID_EMAIL' | translate }}"
            (input)="onInputChangeContact()"
            class="w-full rounded-lg text-palette-gray-900" />
          <div *ngIf="email && !isValidEmail(email)" class="text-red-500">
            {{ 'ERROR.COMMON.INVALID_EMAIL' | translate }}
          </div>
        </div>
      </div>
      <div class="flex md:items-center gap-2 flex-col md:flex-row">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'GROUP.PHONE' | translate }}</span>
        <div class="flex flex-col w-full">
          <div
            class="flex gap-2 justify-between items-center rounded-lg border border-solid w-full h-[42px]"
            [ngClass]="number_phone && !isValidPhone(number_phone) ? ' border-red-500' : 'border-palette-gray-300'">
            <div class="custom-phone-dropdown">
              <p-dropdown
                [options]="countries"
                [(ngModel)]="selectedCountry"
                [emptyFilterMessage]="'COMMON.NO_RESULTS_FOUND' | translate"
                (onChange)="onCountryDropdownChange($event)"
                [ngModelOptions]="{ standalone: true }"
                optionLabel="code"
                [filter]="true"
                filterBy="name"
                appendTo="body"
                placeholder="US (+1)">
                <ng-template pTemplate="selectedItem">
                  <div
                    *ngIf="selectedCountry && this.area_code !== ''"
                    class="flex gap-2 justify-center items-center text-gray-900 text-base font-normal">
                    <div>{{ selectedCountry.code }} {{ '(' + this.area_code + ')' }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex gap-2 items-center">
                    <span>{{ country.name }}</span>
                    <span>({{ country.dial_code }})</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="flex flex-col w-full">
              <input
                pInputText
                type="tel"
                [(ngModel)]="number_phone"
                required
                pattern="[0-9]{9,10}"
                placeholder="{{ 'GROUP.PLEASE_ENTER_A_NUMBER_PHONE' | translate }}"
                (input)="onInputChangeContact()"
                #phone="ngModel"
                class="border-none w-full rounded-r-lg rounded-l-none text-gray-900 text-base font-normal focus:ring-0" />
            </div>
          </div>
          <div *ngIf="number_phone && !isValidPhone(number_phone)" class="text-red-500 mt-1">
            {{ 'ERROR.COMMON.INVALID_PHONE_NUMBER' | translate }}
          </div>
        </div>
      </div>
      <div class="flex md:items-center gap-2 flex-col md:flex-row">
        <span class="w-60 text-sm font-medium text-palette-gray-500">{{ 'GROUP.WEB_ADDRESS' | translate }}</span>
        <div class="flex flex-col w-full">
          <input
            pInputText
            type="text"
            [(ngModel)]="address"
            [placeholder]="'GROUP.PLEASE_ENTER_WEBSITE_ADDRESS' | translate"
            pattern="^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\w .-]*)*\/?$"
            (input)="onInputChangeContact()"
            class="w-full rounded-lg text-palette-gray-900" />
          <div *ngIf="address && !isValidAddress(address)" class="text-red-500 mt-1">
            {{ 'ERROR.COMMON.INVALID_WEB_ADDRESS' | translate }}
          </div>
        </div>
      </div>
    </div>
    <p-footer
      class="w-full fixed bottom-0 left-0 md:relative md:flex flex gap-3 justify-between md:justify-end p-4 md:p-0 pb-8 md:pb-0 bg-white">
      <div class="w-[calc((100%-12px)/2)] md:w-fit">
        <p-button
          (click)="onBackToGroupInfoSettings(); handleContactCancel()"
          [disabled]="!groupDataChangedContact"
          styleClass="cancel-btn w-full h-11 px-[18px] py-[10px] rounded-lg font-semibold text-base text-palette-gray-700 border border-palette-gray-300 bg-white hover:bg-palette-gray-100"
          label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"></p-button>
      </div>
      <div class="w-[calc((100%-12px)/2)] md:w-fit">
        <p-button
          (onClick)="handleContactSave()"
          [disabled]="!groupDataChangedContact"
          [loading]="isLoadingContactSave"
          loadingIcon="pi-spin pi sctr-icon-loading-02"
          styleClass="save-btn w-full h-11 px-[18px] py-[10px] rounded-lg font-semibold text-base text-white border border-branding-primary-600 bg-branding-primary-600 hover:bg-branding-primary-700"
          label="{{ 'COMMON.BUTTON_ACTION.SAVE' | translate }}"></p-button>
      </div>
    </p-footer>
  </p-card>
</ng-template>

<p-dialog
  header="{{ 'GROUP.SETTING.CONFIRMATION.CHANGE_GROUP_PRIVACY' | translate }}"
  [(visible)]="isShowModal"
  [draggable]="false"
  [resizable]="false"
  [style]="{ 'width': '480px', 'border-radius': '12px' }"
  [modal]="true"
  class="custom-dialog">
  <div class="flex">
    <span class="text-sm font-normal text-palette-gray-600">{{
      'GROUP.SETTING.CONFIRMATION.CHANGE_TO_A_PRIVATE_GROUP' | translate
    }}</span>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit"
        (click)="handleCancel()">
        <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4 bg-system-error-600 text-palette-base-white"
        type="submit"
        (click)="isShowModal = false">
        {{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>
