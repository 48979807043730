import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { FollowerApiModels } from '@app/lib/api/follower/api.follower.model';
import { StatsApiModels } from '@app/lib/api/statistics/api.statistics.models';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { LocationData } from '@app/shared/components/statistics/statistics.models';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { ToastMessageService } from './toast-message.service';
@Injectable({
  providedIn: 'root'
})
export class FollowerService {
  private api: ApiClient;
  constructor(
    public readonly httpClient: HttpClient,
    private toastMessageService: ToastMessageService
  ) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  handleErrorResponse(res: any): any {
    if (!res.success) {
      this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, res.error.message);
    }
  }

  followFanpage(typeService: string, typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.followFanpage(typeService, typeId).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  unblockFollower(
    typeService: string,
    typeId: string | null,
    param: any
  ): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.unblockFollower(typeService, typeId, param).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res;
      })
    );
  }

  updateStatus(
    typeService: string,
    typeId: string | null,
    typeStatus: any
  ): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.updateStatus(typeService, typeId, typeStatus).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res;
      })
    );
  }

  blockFollower(
    typeService: string,
    typeId: string | null,
    userId: string
  ): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.blockFollower(typeService, typeId, userId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res;
      })
    );
  }

  removeFollower(typeService: string, typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.removeFollower(typeService, typeId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res;
      })
    );
  }

  declineInvation(typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.declineInvation(typeId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  acceptInvation(typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.acceptInvation(typeId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  inviteFriendsToFanpage(typeId: string | null, listUserId: any): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.inviteFriendsToFanpage(typeId, listUserId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res;
      })
    );
  }

  getInviteFriendList(
    typeId: string | null,
    searchKeyword: string,
    page = 0
  ): Observable<FollowerApiModels.ListFriendsResponse> {
    return this.api.follower.getInviteFriendList(typeId, searchKeyword, page).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  unFollowFanpage(typeService: string, typeId: string | null): Observable<FollowerApiModels.FanpageResponse> {
    return this.api.follower.unFollowFanpage(typeService, typeId).pipe(
      map(res => {
        return res;
      })
    );
  }

  getFollowersList(
    typeService = 'page',
    typeId: string | null,
    pageNum = PAGE_NUM_DEFAULT,
    textSearch = '',
    pageSize = PAGE_SIZE_DEFAULT,
    userStatus = 'ACCEPTED'
  ) {
    return this.api.follower.getFollowersList(typeService, typeId, pageNum, textSearch, pageSize, userStatus).pipe(
      map(res => {
        this.handleErrorResponse(res);
        if (res.success) {
          return { ...res.data, content: res.data.data };
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      })
    );
  }

  getFollowingList(pageNum = 0, pageSize = 10, typeService: string, sort?: string) {
    return this.api.follower.getFollowingList(pageNum, pageSize, typeService, sort).pipe(
      map(res => {
        this.handleErrorResponse(res);
        if (res.success) {
          return { ...res.data, content: res.data.data };
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      })
    );
  }

  deleteFollowUser(typeService: string, typeId: string) {
    return this.api.follower.unFollowFanpage(typeService, typeId).pipe(
      map(res => {
        this.handleErrorResponse(res);
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      })
    );
  }

  getBlockList(
    typeService: string,
    typeId: string | null,
    textSearch?: string | null,
    pageNum = 0,
    pageSize = 10
  ): Observable<FollowerApiModels.ListFriendsResponse> {
    return this.api.follower.getBlockList(typeService, typeId, textSearch, pageNum, pageSize).pipe(
      map(res => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  getCheckFollowing(userProfileId: string, userId: string): Observable<any> {
    return this.api.follower.getCheckFollowing(userProfileId, userId).pipe(
      map(res => {
        return { data: res.success && res.data !== null };
      })
    );
  }

  getFanpageAgeAndGenderStats(fanpageId: string): Observable<FollowerApiModels.AgeAndGenderStats> {
    return this.api.follower.getFanpageAgeAndGenderStats(fanpageId).pipe(
      map(res => {
        const nullData = {} as FollowerApiModels.AgeAndGenderStats;
        this.handleErrorResponse(res);
        return res.data ? res.data.stats : nullData;
      })
    );
  }

  getMemberLocationStats(objectId: string): Observable<StatsApiModels.LocationStats[]> {
    return this.api.statistics.getMemberLocationStats(objectId).pipe(
      map((res: any) => {
        if (res && !res.success) {
          return res;
        }
        return res.data;
      })
    );
  }

  sortByNumberKey(array: LocationData[], key: keyof LocationData): LocationData[] {
    return array.sort((a: any, b: any) => b[key] - a[key]);
  }

  proceedLocationData(rawData: StatsApiModels.LocationStats[]): LocationData[] {
    const rawLocationData: LocationData[] = [];
    rawData.forEach(locationData => {
      const processedData = {
        address: locationData.address,
        countryCode: locationData.country,
        userCount: locationData.number_user
      };
      rawLocationData.push(processedData);
    });
    const processedArrayFiltered: LocationData[] = rawLocationData.filter(
      processedObject => processedObject.userCount !== 0
    );
    return this.sortByNumberKey(processedArrayFiltered, 'userCount');
  }
}
