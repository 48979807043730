<div
  class="{{ customClass ? customClass : 'bg-palette-base-white shadow-sm px-4 pb-4 pt-6 rounded-md' }}"
  *ngIf="!isModerate && isLoggedUser && !loading">
  <div class="flex flex-col space-y-4">
    <div class="flex items-stretch space-x-4">
      <div class="relative p-[3px] self-start">
        <avatar *ngIf="!isFanpagePost; else showFanpageAvatar" [userInfo]="userInfo$ | async"></avatar>
        <ng-template #showFanpageAvatar>
          <div class="relative w-10 mt-1" *ngIf="fanpageInfo">
            <div class="rounded-full ring-2 ring-white w-10 h-9">
              <div class="rounded-full p-0.5 ring-2 ring-palette-orange-500">
                <div class="relative cursor-pointer w-9 h-9">
                  <div class="rounded-full ring-2 ring-transparent w-9 h-9">
                    <img
                      *ngIf="!!fanpageInfo?.avatar; else showAvatarName"
                      class="rounded-full w-full h-full"
                      onerror="this.src='assets/images/default_user_avatar.png'"
                      [src]="getImgUrl(fanpageInfo?.avatar)" />
                    <ng-template #showAvatarName>
                      <div class="rounded-full w-full h-full overflow-clip flex items-center justify-center">
                        <p-avatar
                          styleClass="w-10 h-10 bg-palette-blue-50 text-palette-blue-600 font-bold text-lg"
                          [label]="fanpageInfo?.page_name | abbreviate: 'fanpage'"></p-avatar>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="w-full">
        <div class="w-full">
          <p-button
            checkAuthentication
            (openLoginPopup)="isOpenPopupLogin = $event"
            [styleClass]="
              ' w-full flex justify-start items-center py-2.5 px-3.5 bg-transparent  border-1 border-palette-gray-300'
            "
            (click)="!isOpenPopupLogin ? toggleCreatePostDialog() : null">
            <span class="text-[13px] md:text-base font-normal text-palette-gray-500">
              {{ 'POST.SHARE_YOUR_FEEL' | translate }}
            </span>
          </p-button>
        </div>
        <div class="hidden w-full gap-1 mt-3 md:flex">
          <ng-container *ngFor="let item of createPostItem">
            <p-button
              checkAuthentication
              (openLoginPopup)="isOpenPopupLogin = $event"
              (click)="!isOpenPopupLogin ? item.action() : null"
              class="font-thin rounded-md first:ml-0"
              styleClass="p-button-secondary p-button-outlined p-button-text rounded-md px-3 py-2 text-sm bg-transparent border-0">
              <img defaultImage [src]="item.image" class="w-5 h-5" />
              <span class="pl-2 text-base font-semibold text-gray-600 whitespace-nowrap">{{
                item.label | translate
              }}</span>
            </p-button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap justify-between w-full gap-1 mt-3 md:hidden xs:justify-around md:justify-center">
    <ng-container *ngFor="let item of createPostItem" class="w-1/5 px-1">
      <p-button
        checkAuthentication
        (openLoginPopup)="isOpenPopupLogin = $event"
        (click)="!isOpenPopupLogin ? item.action() : null"
        class="flex items-center font-thin rounded-md first:ml-0"
        styleClass="p-button-secondary p-button-outlined p-button-text rounded-md px-0 xs:px-2 py-2 text-sm sm:text-base md:text-lg lg:text-base xl:text-base 2xl:text-base bg-transparent block w-full">
        <img defaultImage [src]="item.image" class="flex-shrink-0 inline-block w-5 h-5 mr-0 md:mr-2" />
        <span class="pl-2 text-xs text-gray-600 md:text-base">{{ item.label | translate }}</span>
      </p-button>
    </ng-container>
  </div>
</div>
<post-dialog
  [userInfo]="userInfo$ | async"
  [fanpageInfo]="(fanpageInfo$ | async)!"
  [friendList]="friendList"
  [loading]="loading"
  [isGroupPost]="isGroupPost"
  [isFanpagePost]="isFanpagePost"
  [groupPrivacy]="groupPrivacy"
  [groupId]="groupId"
  [isImmediatelyShownPost]="isImmediatelyShownPost"
  [currentPage]="currentPage"
  [memberGroup]="membersGroup$ | async"
  (postData)="getPostData($event)"
  (callProgress)="callProgress($event)"
  (privacyPost)="privacyPost.emit($event)"></post-dialog>

  <div *ngIf="isOpenPopupLogin">
    <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
  </div>