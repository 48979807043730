import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PostListPaging } from '@app/lib/api/post/api.post.model';
import { FILE_EXTENSION, POST_PATTERN, POST_STATUSES, POST_TABLE_STYLE } from '@app/shared/constant';
import { Post } from '@app/shared/models/post';
import { environment } from '@env/environment';

const POST_STATUS_CONTEXTS = {
  active: {
    styleClass: 'bg-palette-emerald-50 text-palette-emerald-600',
    label: 'ACTIVE',
    value: [POST_STATUSES.new, POST_STATUSES.edited]
  },
  warning: {
    styleClass: 'bg-palette-yellow-50 text-palette-yellow-600',
    label: 'WARNING',
    value: [POST_STATUSES.warning]
  }
};

const POST_TABLE_HEADERS = {
  group: [
    'ENTITY.POSTS',
    'COMMON.DATE_TIME.DATE',
    'STATUS.STATUS',
    'ENTITY.REACTIONS',
    'ENTITY.COMMENTS',
    'ENTITY.SHARES'
  ],
  fanpage: [
    'ENTITY.POSTS',
    'STATUS.STATUS',
    'ENTITY.REACHES',
    'ENTITY.REACTIONS',
    'ENTITY.COMMENTS',
    'ENTITY.SHARES'
    // 'ENTITY.COINS'
  ]
};

@Component({
  selector: 'stats-post-list',
  templateUrl: './stats-post-list.component.html',
  styleUrls: ['./stats-post-list.component.scss']
})
export class StatsPostListComponent {
  @Input() postListPaging = {} as PostListPaging;
  @Input() isLoading = false;
  @Input() tableStyle = POST_TABLE_STYLE.group;
  @Output() pageSize = new EventEmitter();
  @Output() pagingDetail = new EventEmitter();
  baseURL = environment.baseURL;
  cardTitle =
    'STATISTIC.TITLES.' + (this.tableStyle === POST_TABLE_STYLE.group ? 'POST_LIST' : 'DETAIL_POST_ANALYTICS');
  postListHeader = POST_TABLE_HEADERS.group;
  POST_TABLE_STYLE = POST_TABLE_STYLE;
  pageSizeOptions = [
    { label: 10, size: 10 },
    { label: 30, size: 30 },
    { label: 50, size: 50 }
  ];
  selectedPageSize = this.pageSizeOptions[0].size;
  POST_STATUS_CONTEXTS = POST_STATUS_CONTEXTS;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['postListPaging']) {
      if (this.tableStyle === POST_TABLE_STYLE.fanpage) {
        this.postListHeader = POST_TABLE_HEADERS.fanpage;
      }
    }
  }

  getAvatarThumbnail(post: Post): string {
    let avatarUrl = '';
    if (post.user_object && post.user_object.avatar_thumbnail_url) {
      if (post.user_object.avatar_thumbnail_url.includes('/media')) {
        avatarUrl = `${this.baseURL}${post.user_object.avatar_thumbnail_url}`;
      } else if (post.user_object.avatar_thumbnail_url.includes('/storage/files/web/')) {
        avatarUrl = `${this.baseURL}${post.user_object.avatar_thumbnail_url}`;
      } else {
        avatarUrl = `${this.baseURL}/storage/files/thumbnail/${post.user_object.avatar_thumbnail_url}${FILE_EXTENSION.image}`;
      }
    }
    return avatarUrl;
  }

  getThumbnailURLFromPost(post: Post): string {
    const mediaThumbnail =
      post.media_urls && post.media_urls.media && post.media_urls.media.length
        ? `${this.baseURL}/storage/files/thumbnail/${post.media_urls.media[0].thumbnail}${FILE_EXTENSION.image}`
        : '';
    const albumThumbnail =
      post.library_object && post.library_object.thumbnail ? this.baseURL + post.library_object.thumbnail : '';
    const thumbnailURL = albumThumbnail ? albumThumbnail : mediaThumbnail;
    return thumbnailURL;
  }

  getPostThumbnail(post: Post): string {
    if (post.post_pattern === POST_PATTERN.normal || post.post_pattern === POST_PATTERN.album) {
      return this.getThumbnailURLFromPost(post);
    } else if (post.post_pattern === POST_PATTERN.share) {
      return post.parent ? this.getThumbnailURLFromPost(post.parent) : '';
    } else {
      return '';
    }
  }

  onClickedPost(postDetails: Post): void {
    //TODO: Handle click to post
  }

  onChangedPageSize(size: number): void {
    this.pageSize.emit(size);
  }

  onPageChange(event: any): void {
    this.pagingDetail.emit(event);
  }
}
