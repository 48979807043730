import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '@app/core/services/group.service';
import { LocationService } from '@app/core/services/location.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { AddressModel, getDetailGroup } from '@app/lib/api/group/api.group.model';
import { getGroupDetail } from '@app/modules/main/group/store/actions';
import { GroupInterfaceState } from '@app/modules/main/group/store/reducer';
import { selectGroupDetail } from '@app/modules/main/group/store/selectors';
import { countryCodes } from '@app/modules/main/personal-profile/components/personal-profile-about/mock/CountryCodes';
import { UserProfileApiActions } from '@app/modules/main/states/user-profiles/user-profiles.actions';
import { selectStatusEditUserProfileSuccess } from '@app/modules/main/states/user-profiles/user-profiles.selectors';
import { GROUP_PRIVACY_CONTEXTS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { UserProfile } from '@app/shared/models/user-profiles.model';
import { validateEmail, validatePhoneNumber, validateWebsite } from '@app/shared/validators';
import { Store, select } from '@ngrx/store';
import { cloneDeep, omit } from 'lodash-es';
import { MultiSelect } from 'primeng/multiselect';

const GROUP_INFO_SETTING_OPTIONS = {
  about: { id: 1, title: 'ABOUT' },
  contactInformation: { id: 2, title: 'CONTACT_INFORMATION' }
};

interface PrivacyOption {
  label: string;
  value: string;
  icon: string;
}

@Component({
  selector: 'general-information',
  templateUrl: './general-information.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  @Input() dataInfoDetailGroup?: getDetailGroup;
  @Input() activeIndex = 0;
  @Output() isBackToGroupSettings = new EventEmitter();
  groupInfo: any;
  selectedPrivacy: PrivacyOption;
  groupPrivacyOptions = Object.values(GROUP_PRIVACY_CONTEXTS);
  GROUP_PRIVACY_CONTEXTS = GROUP_PRIVACY_CONTEXTS;
  group_name: string;
  description: string;
  location: string;
  email: string;
  number_phone: string;
  address: string;
  isAddressValid = true;
  selectedGroupInfoSetting = '';
  isActiveGroupInfo = false;
  groupInfoSettingOption = GROUP_INFO_SETTING_OPTIONS;
  groupContactInfo: any;
  groupDataChangedAbout: boolean = false;
  groupDataChangedContact: boolean = false;
  groupId: string | null;
  isShowModal: boolean = false;
  //Address
  countries: any[];
  area_code: string;
  countryCode: string;
  selectedCountry: any;
  locationItems: any[];
  @ViewChild('multiSelectLocation') multiselect: MultiSelect;
  selectedLocations: any[] = [];
  initialSelectedLocations: any[] = [];
  locationTypes = 'Country';
  formContactInfo: FormGroup;
  userProfile: UserProfile;
  visibleOthers: boolean;
  visibleContactInfo: boolean;
  visibleOverview: boolean;
  isPendingEdit: boolean;
  addressGroup: AddressModel;
  isLoadingAboutSave = false;
  isLoadingContactSave = false;

  isValidEmail(email: string): boolean {
    return validateEmail(email);
  }

  isValidPhone(phone: string): boolean {
    return validatePhoneNumber(phone);
  }

  isValidAddress(address: string): boolean {
    return validateWebsite(address);
  }

  constructor(
    private store: Store,
    private appStore: Store<GroupInterfaceState>,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private TranslationService: TranslationService,
    private toastMessageService: ToastMessageService,
    private locationService: LocationService
  ) {
    this.appStore.pipe(select(selectGroupDetail)).subscribe((groupDetailState: any) => {
      if (groupDetailState && groupDetailState.group_id) {
        this.groupInfo = groupDetailState;
        this.reloadValueForFormControl();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeItem']) {
      this.handleSetUpForm();
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('id');
    });

    if (this.groupId) {
      this.store.dispatch(getGroupDetail({ groupId: this.groupId }));
      this.groupService.getGroupContactDetail(this.groupId).subscribe(res => {
        this.groupContactInfo = res.data;
        this.reloadValueContact();
      });
    }
    this.countries = countryCodes.map(country => ({
      ...country,
      label: `${country.code} (${country.dial_code})`
    }));

    this.locationService.getCountries().subscribe(countries => {
      this.locationItems = countries;
    });

    this.store.select(selectStatusEditUserProfileSuccess).subscribe(success => {
      if (success) {
        this.visibleOthers = false;
        this.visibleContactInfo = false;
        this.visibleOverview = false;
        this.store.dispatch(UserProfileApiActions.onResetStatusUpdateUserProfile());
        this.isPendingEdit = false;
      }
    });
  }

  onInputChange() {
    if (
      this.group_name !== this.groupInfo.group_name ||
      this.description !== this.groupInfo.description ||
      this.location !== this.groupInfo.location
    ) {
      this.groupDataChangedAbout = true;
    } else {
      this.groupDataChangedAbout = false;
    }
  }

  onInputChangeContact() {
    const isPhoneValid = this.isValidPhone(this.number_phone);
    const isEmailValid = this.isValidEmail(this.email);
    const isAddressEmpty = this.isValidAddress(this.address);
    const isContactChanged =
      this.email !== this.groupContactInfo?.email ||
      this.number_phone !== this.groupContactInfo?.number_phone ||
      this.address !== this.groupContactInfo?.address;
    if (
      (isPhoneValid || !this.number_phone) &&
      (isEmailValid || !this.email) &&
      (isAddressEmpty || !this.address) &&
      isContactChanged
    ) {
      this.groupDataChangedContact = true;
    } else {
      this.groupDataChangedContact = false;
    }
  }

  handleAboutCancel() {
    this.groupDataChangedAbout = false;
    if (!this.selectedLocations) {
      this.clearSelectedLocations();
    } else {
      this.selectedLocations = [...this.initialSelectedLocations];
    }
    this.reloadValueForFormControl();
  }

  handleContactCancel() {
    this.groupDataChangedContact = false;
    this.email = this.groupContactInfo?.email;
    this.number_phone = this.groupContactInfo?.number_phone;
    this.address = this.groupContactInfo?.address;
  }

  selectedSetting(settingId: number, settingTitle: string): void {
    this.activeIndex = settingId;
    this.selectedGroupInfoSetting = settingTitle;
    this.isActiveGroupInfo = true;
  }

  onBackToGroupInfoSettings(): void {
    this.isActiveGroupInfo = false;
  }

  onBackToGroupSettings(): void {
    this.isActiveGroupInfo = false;
    this.isBackToGroupSettings.emit(true);
  }

  onPrivacyChange(event: PrivacyOption): void {
    this.groupDataChangedAbout = this.isShowModal = event.value !== GROUP_PRIVACY_CONTEXTS.public.value;
  }

  handleCancel() {
    this.selectedPrivacy = GROUP_PRIVACY_CONTEXTS.public;
    this.isShowModal = false;
  }

  reloadValueContact(): void {
    if (this.groupContactInfo) {
      this.email = this.groupContactInfo.email;
      this.area_code = this.groupContactInfo.area_code;
      this.selectedCountry = this.getCountryCodeFromDialCode(this.area_code);
      this.number_phone = this.groupContactInfo.number_phone;
      this.address = this.groupContactInfo.address;
    }
  }

  handleAboutSave(): void {
    if (this.groupDataChangedAbout) {
      this.handleSetUpForm();
      const privacy =
        this.selectedPrivacy.value === GROUP_PRIVACY_CONTEXTS.public.value
          ? GROUP_PRIVACY_CONTEXTS.public.value
          : GROUP_PRIVACY_CONTEXTS.private.value;
      let payload = {
        group_name: this.group_name,
        description: this.description,
        privacy: privacy,
        location: this.addressGroup ? this.addressGroup : null
      };
      if (this.groupId) {
        this.isLoadingAboutSave = true;
        this.groupService.putGroupAboutDetail(this.groupId, payload).subscribe((res: any) => {
          if (res.success) {
            this.groupDataChangedAbout = false;
            this.store.dispatch(getGroupDetail({ groupId: this.groupId as '' }));
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.success,
              this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_UPDATE_ABOUT_INFORMATION')
            );
          }
          this.isLoadingAboutSave = false;
        });
      }
    }
  }

  handleContactSave() {
    if (this.groupDataChangedContact) {
      let payload = {
        email: this.email,
        area_code: this.area_code,
        number_phone: this.number_phone,
        address: this.address
      };
      if (this.groupId) {
        this.isLoadingContactSave = true;
        this.groupService.putGroupContactDetail(this.groupId, payload).subscribe((res: any) => {
          if (res.success) {
            this.isLoadingContactSave = false;
            this.groupDataChangedContact = false;
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.success,
              this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_UPDATE_GROUP_CONTACT')
            );
          }
        });
      }
    }
  }

  reloadValueForFormControl(): void {
    if (this.groupInfo) {
      this.group_name = this.groupInfo.group_name;
      this.description = this.groupInfo.description;
      this.selectedPrivacy =
        this.groupInfo.privacy === GROUP_PRIVACY_CONTEXTS.public.value
          ? GROUP_PRIVACY_CONTEXTS.public
          : GROUP_PRIVACY_CONTEXTS.private;
      this.location = this.groupInfo.location;

      if ((!this.selectedLocations || !this.selectedLocations.length) && this.groupInfo.location) {
        let { country, province, district } = this.groupInfo.location;
        this.selectedLocations = [];
        if (district) {
          this.selectedLocations?.push({
            district_id: district.id,
            district_name: district.name,
            code: district.code,
            code_name: district.code_name
          });
        }
        if (province) {
          this.selectedLocations?.push({
            province_id: province.id,
            province_name: province.name,
            code: province.code,
            code_name: province.code_name
          });
        }
        if (country) {
          this.selectedLocations?.push({
            country_id: country.id,
            country_name: country.name,
            code: country.code,
            code_name: country.code_name
          });
        }
      }
      if (this.initialSelectedLocations.length === 0) {
        this.initialSelectedLocations = [...this.selectedLocations];
      }
    }
  }

  onCountryDropdownChange(event: any): void {
    this.selectedCountry = event.value;
    this.area_code = this.selectedCountry.dial_code;
    this.countryCode = this.selectedCountry.code;
  }

  getCountryCodeFromDialCode(areaCode: string): object {
    let countryCode: any;
    this.countries?.forEach((country: any) => {
      if (areaCode && areaCode.startsWith(country.dial_code)) {
        countryCode = country;
      }
    });
    return countryCode;
  }

  isInfoChanged(): boolean {
    const isPrivacyNotChanged = this.groupInfo && this.groupInfo.privacy !== this.selectedPrivacy.value;
    return !(this.groupDataChangedAbout || isPrivacyNotChanged);
  }

  handleLocationSelected(item: any) {
    localStorage.setItem('selectedLocations', JSON.stringify(this.selectedLocations));
    this.multiselect.resetFilter();
    const newValue = [...item.value];
    this.selectedLocations = newValue.reverse();

    if (this.locationTypes === 'Country' && this.selectedLocations.length === 1) {
      this.locationTypes = 'Province';
      this.updateLocationItems('Province', item.itemValue.country_id);
    } else if (this.locationTypes === 'Province' && this.selectedLocations.length === 2) {
      this.locationTypes = 'District';
      this.updateLocationItems('District', item.itemValue.province_id);
    }
    this.updateAddressState();
    this.groupDataChangedAbout = true;
  }

  updateLocationItems(type: string, id: any) {
    if (type === 'Province') {
      this.locationService.getProvinces(id).subscribe(provinces => {
        this.locationItems = cloneDeep(provinces);
      });
    } else if (type === 'District') {
      this.locationService.getDistricts(id).subscribe(districts => {
        this.locationItems = cloneDeep(districts);
      });
    }
  }

  updateAddressState() {
    if (this.selectedLocations && this.selectedLocations.length === 3) {
      const last = this.selectedLocations[this.selectedLocations.length - 1];
      this.locationItems = this.locationItems.map(i => (i === last ? i : { ...i, district_disable: true }));
      this.multiselect.overlayVisible = false;
    } else if (this.selectedLocations && this.selectedLocations.length === 2) {
      this.locationItems = this.locationItems.map(i => omit(i, 'district_disable'));
    }
  }

  handleFilterBy(locationTypes?: string): string {
    switch (locationTypes) {
      case 'Province':
        return 'province_name';
      case 'District':
        return 'district_name';
    }
    return 'country_name';
  }

  clearSelectedLocations() {
    this.locationTypes = 'Country';
    this.handleFilterBy(this.locationTypes);
    localStorage.removeItem('selectedLocations');
    this.selectedLocations = [];
    this.multiselect.value = this.selectedLocations;
    this.locationService.getCountries().subscribe(countries => {
      this.locationItems = countries;
    });
    this.groupDataChangedAbout = true;
  }

  handleSetUpForm(): void {
    if (this.selectedLocations && this.selectedLocations.length > 0) {
      this.addressGroup = { country: null, province: null, district: null };
      this.selectedLocations.forEach(location => {
        if (location.district_id) {
          this.addressGroup.district = {
            id: location.district_id,
            name: location.district_name,
            code: location.code,
            code_name: location.code_name
          };
        } else if (location.province_id) {
          this.addressGroup.province = {
            id: location.province_id,
            name: location.province_name,
            code: location.code,
            code_name: location.code_name
          };
        } else if (location.country_id) {
          this.addressGroup.country = {
            id: location.country_id,
            name: location.country_name,
            code: location.code,
            code_name: location.code_name
          };
        }
      });
    }
  }
}
