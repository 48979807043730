<div class="grid flex-column text-sm class-element theme-bg {{
    data?.theme_object && applyThemeStyles(data?.theme_object)
  }} content-center custom-background" id="{{ data?.id }}">
  <div class="px-4 pt-1 pb-3 overflowWrap {{ applyFontStyles(data?.theme_object) }}" *ngIf="data"
    [ngClass]="{ 'text-center theme-applied px-[10%]': data?.theme_object }">
    <ng-container [ngTemplateOutlet]="postContent" [ngTemplateOutletContext]="{
        isViewMore: !isShowAllContent && contentHeight > MAX_TRUNCATED_TEXT_HEIGHT && !data?.theme_object,
        isTranslate: isTranslate
      }">
    </ng-container>
  </div>

  <div class="relative" *ngIf="
      ((mediaList.length > 0 &&
        (data.post_pattern === POST_PATTERN.normal || data.post_pattern === POST_PATTERN.album) &&
        data.post_type !== POST_TYPE.livestream) ||
        (mediaList.length > 0 && data.ads_id)) &&
      !isPostView
    ">
    <media [isSharedPost]="isSharedPost" [mediaList]="mediaList" (clickImage)="clickImage($event)"
      [isAutoPlay]="isWatchScreen" [isScale]="isShowScaleView" (scaleVideo)="handleScaleVideo($event)"
      (unScaleVideo)="detachVideo()"></media>
    <div *ngIf="data.ads_id"
      class="flex w-[calc(100%-4px)] cursor-pointer max-h-14 gap-2.5 items-center justify-between px-4 py-3 m-0.5 absolute bottom-0 left-0 bg-palette-gray-900/40 class-ads"
      id="{{ data.ads_id }}" (click)="handleNavigateAdLink(data.advertising_object.ad?.destination_url, data.ads_id)">
      <span class="max-w-full overflow-hidden text-base font-semibold text-palette-base-white line-clamp-1 select-none">
        {{ data?.advertising_object?.ad?.title }}
      </span>
      <button *ngIf="data?.advertising_object?.ad?.button_name"
        class="px-3 h-9 max-w-[40%] bg-palette-base-white rounded-lg text-palette-gray-900 font-semibold text-sm truncate hover:bg-palette-gray-100 transition-colors">
        {{ data?.advertising_object?.ad?.button_name }}
      </button>
    </div>
  </div>

  <div [hidden]="data.post_type !== POST_TYPE.livestream" class="relative cursor-pointer">
    <iframe #iframeEl allow="camera; microphone; fullscreen; display-capture" allowfullscreen
      class="w-full aspect-video" frameborder="0"></iframe>
    <div class="absolute flex flex-row gap-2 top-4 left-4">
      <div class="items-center px-2 py-1 text-xs font-medium bg-system-error-500 rounded-2xl text-palette-base-white">
        {{ 'HOME.LIVE' | translate }}
      </div>
    </div>
    <div class="absolute top-0 left-0 w-full h-full" (click)="goToLivestreamDetail()"></div>
  </div>

  <div *ngIf="data.post_pattern === POST_PATTERN.share && data.parent && data.parent?.user_object">
    <post [isSharedPost]="true" [post]="data.parent"></post>
  </div>

  <div *ngIf="data.post_pattern === POST_PATTERN.share && (!data.parent || !data.parent?.user_object)"
    class="m-4 border border-palette-gray-100">
    <content-not-available [isShowReturnBtn]="false"></content-not-available>
  </div>

  <div *ngIf="data.post_pattern === POST_PATTERN.link && data.object_data">
    <post-content-link [data]="data.object_data" [shareLinkType]="data.share_link_type || ''"
      [shareLink]="data.share_link || ''"></post-content-link>
  </div>

  <div *ngIf="data.preview_data && !data.media_urls">
    <post-content-preview [data]="data.preview_data"></post-content-preview>
  </div>
</div>

<div class="hidden bg-gradient-to-r bg-gradient-to-b bg-gradient-to-br bg-gradient-to-bl">
  <div
    class="bg-[#EBE9FE] placeholder:text-[#9B8AFB] text-[#9B8AFB] placeholder:text-[#FFFFFF] text-[#FFFFFF] placeholder:text-[#DD2590] text-[#DD2590]">
  </div>
  <div
    class="placeholder:text-[#101828] text-[#101828] placeholder:text-[#444CE7] text-[#444CE7] placeholder:text-[#594839] text-[#594839]">
  </div>
  <div
    class="from-palette-red-700 via-palette-red-500 to-palette-pink-600 bg-palette-violet-600 from-palette-blue-700 to-palette-cyan-400 from-palette-gray-300 to-palette-gray-900">
  </div>
  <div
    class="from-palette-green-500 to-palette-yellow-300 from-palette-cyan-300 to-palette-fuchsia-300 from-palette-pink-200 to-palette-pink-50 from-palette-yellow-200 to-palette-yellow-100">
  </div>
  <div
    class="from-[#594D42] to-[#BF9075] from-palette-teal-200 to-palette-indigo-300 to-palette-indigo-700 from-palette-blue-25 to-palette-gray-300 from-[#D299C2] to-[#FEF9D7]">
  </div>
  <div
    class="from-[#F2E3D5] to-[#D9BEA7] from-palette-rose-300 to-palette-rose-400 from-[#DEECDD] to-[#C1DFC4] from-[#BAC8E0] to-[#6A85B6] from-[#B12A5B] to-[#CF556C]">
  </div>
</div>

<photo-view *ngIf="isShowView" [userPosts]="(userPosts$ | async)!" [activeIndex]="activeIndex"
  [post]="data.post_info || data" [mediaList]="mediaList" [(isShowView)]="isShowView"
  (deletePost)="onDeletePost($event)" (archivePost)="onArchivePost($event)">
</photo-view>

<ng-template #postContent let-isViewMore="isViewMore">
  <ng-template [ngIf]="isTranslate" [ngIfElse]="notTranslate">
    <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
      <div class="whitespace-pre-wrap comment-content text-sm font-normal seo-description emoji {{
          isViewMore ? 'truncated-text' : 'block'
        }}" [innerHTML]="translateContent() + (isViewMore ? ' ...' : '')"></div>
    </ng-template>

    <ng-template #loadingTranslate>
      <div class="flex flex-col justify-center">
        <p-skeleton width="100px" height="12px"></p-skeleton>
        <p-skeleton class="my-1" width="200px" height="10px"></p-skeleton>
        <p-skeleton width="50px" height="12px"></p-skeleton>
      </div>
    </ng-template>
  </ng-template>
  <ng-template #notTranslate>
    <div class="whitespace-pre-wrap post-content emoji {{ isViewMore ? 'truncated-text' : 'block' }} seo-description"
      [innerHTML]="formattedContent() + (isViewMore ? ' ...' : '')"></div>
  </ng-template>

  <div>
    <div class="float-left">
      <ng-template [ngIf]="isTranslate" [ngIfElse]="viewTrans">
        <ng-container *ngIf="formattedContent()">
          <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
            <div class="py-1 mt-1 post-action" (click)="isTranslate = false">
              {{ 'COMMON.SEE_ORIGINAL' | translate }}
            </div>
          </ng-template>
          <ng-template #loadingTranslate>
            <div class="font-semibold w-fit text-palette-gray-700 text-sm mt-1">
              {{ 'COMMON.TRANSLATING' | translate }}...
            </div>
          </ng-template>
        </ng-container>
      </ng-template>
      <ng-template #viewTrans>
        <ng-container *ngIf="isShowTranslateButton(data)">
          <div class="py-1 mt-1 post-action {{ applyFontStyles(data?.theme_object) }} float-left"
            (click)="onTranslateText()">
            {{ 'COMMON.VIEW_TRANSLATION' | translate }}
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div class="float-right">
      <ng-template [ngIf]="isViewMore" [ngIfElse]="viewLess">
        <div class="float-right py-1 mt-1 post-action" (click)="onExpandText()">
          {{ 'COMMON.VIEW_MORE' | translate }}
        </div>
      </ng-template>
      <ng-template #viewLess>
        <div *ngIf="isShowAllContent || (contentHeight > MAX_TRUNCATED_TEXT_HEIGHT && !data?.theme_object)"
          class="float-right py-1 mt-1 post-action" (click)="onExpandText()">
          {{ 'COMMON.VIEW_LESS' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<div *ngIf="isShowScaleView" class="fixed w-[400px] h-[300px] z-10 bottom-5" [ngClass]="{
  'right-24': !mediaService.isShowChatBox, 'right-[calc(30%_+_110px)]': mediaService.isShowChatBox
}">
  <div class="relative w-full h-full">
    <i class="sctr-icon-x-close text-2xl/6 absolute top-3 right-3 text-palette-gray-300 z-10 cursor-pointer" (click)="detachVideo()"></i>
    <ng-template
      custom-video-directive
      [item]="itemScale"
      [isCover]="false"></ng-template>
  </div>
</div>