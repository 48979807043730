<p-dialog
  [(visible)]="visible"
  [style]="{ width: '708px', height: screenWidth > 450 ? 'auto' : '80%' }"
  [breakpoints]="{ '768px': '80vw', '450px': '100%' }"
  [modal]="true"
  class="custom-dialog"
  [ngClass]="{ 'isDialogShare': shareType, 'hide-closable': showStatusTag || isPendingCheckIn }"
  [draggable]="false"
  [dismissableMask]="true"
  [resizable]="false"
  [closable]="true"
  (onHide)="onDialogHide()"
  [position]="screenWidth > 450 ? '' : 'bottom'">
  <ng-template pTemplate="header" class="relative">
    <div class="w-full flex items-center gap-[18px]">
      <i
        (click)="goBack()"
        *ngIf="shareType === shareTypeList.group"
        class="cursor-pointer pi pi-chevron-left text-palette-gray-500"></i>
      <ng-container *ngIf="loading">
        <span class="text-xl font-semibold text-palette-gray-900">{{ 'POST.POSTING' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="!loading">
        <span *ngIf="!showStatusTag" class="text-xl font-semibold text-palette-gray-900">{{
          handleGenerateTitleDialog() | translate
        }}</span>
        <span *ngIf="showStatusTag" class="text-xl font-semibold text-palette-gray-900">{{
          'POST.FEELINGS' | translate
        }}</span>
      </ng-container>
    </div>
    <div class="background-blob-left absolute top-[0] right-14">
      <img src="assets/icons/Vector.svg" />
    </div>
    <div class="absolute right-0 z-0 background-blob-left top-14">
      <img src="assets/icons/Vector-2.svg" />
    </div>
    <ng-container *ngIf="!loading">
      <button
        (click)="toggleStatusTags(false)"
        *ngIf="showStatusTag"
        class="right-0 p-dialog-header-icon p-dialog-header-close">
        <i class="sctr-icon-x-close text-2xl/6"></i>
      </button>

      <button
        (click)="isPendingCheckIn = false"
        *ngIf="isPendingCheckIn"
        class="right-0 p-dialog-header-icon p-dialog-header-close">
        <i class="sctr-icon-x-close text-2xl/6"></i>
      </button>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="content" class="rounded-b-xl">
    <!-- Create Post Field -->
    <div
      [ngClass]="{
        'hidden': loading || showStatusTag || isPendingCheckIn
      }"
      class="px-0 py-0 search-scrollbar">
      <div
        (paste)="onPaste($event)"
        class="theme-bg {{
          this.applyThemeStyles(selectedTheme)
        }} rounded-xl border p-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]]"
        [ngClass]="{ 'flex': !selectedTheme }">
        <div class="w-full" [ngClass]="{ 'grid grid-cols-1 place-items-center  min-h-[400px]': selectedTheme }">
          <div class="flex w-full gap-2 place-self-end" [ngClass]="{ 'justify-center': selectedTheme }">
            <ng-container *ngIf="!selectedTheme">
              <avatar class="max-xs:flex-none" *ngIf="!isFanpagePost; else showFanpageAvatar" [userInfo]="userInfo">
              </avatar>
              <ng-template #showFanpageAvatar>
                <div class="relative w-10 mt-1" *ngIf="fanpageInfo">
                  <div class="w-10 rounded-full ring-2 ring-white h-9">
                    <div class="rounded-full p-0.5 ring-2 ring-palette-orange-500">
                      <div class="relative cursor-pointer w-9 h-9">
                        <div class="rounded-full ring-2 ring-transparent w-9 h-9">
                          <img
                            *ngIf="!!fanpageInfo?.avatar; else showAvatarName"
                            class="w-full h-full rounded-full"
                            onerror="this.src='assets/images/default_user_avatar.png'"
                            [src]="getImgUrl(fanpageInfo?.avatar)" />
                          <ng-template #showAvatarName>
                            <div class="flex items-center justify-center w-full h-full rounded-full overflow-clip">
                              <p-avatar
                                styleClass="w-10 h-10 bg-palette-blue-50 text-palette-blue-600 font-bold text-lg"
                                [label]="fanpageInfo?.page_name | abbreviate: 'fanpage'"></p-avatar>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <textarea-post
              class="max-xs:flex-1"
              [userInfo]="userInfo"
              [fanpageInfo]="fanpageInfo"
              [friendList]="friendList"
              [isGroupPost]="isGroupPost"
              [isFanpagePost]="isFanpagePost"
              [content]="content"
              [theme]="selectedTheme"
              [messageRanges]="messageRanges"
              (onChangeCurrentCharacter)="currentCharacter = $event"
              (onChangeMention)="onChangeMessageRanges($event)"
              (onChangeContent)="changeContent($event)"></textarea-post>
            <ng-container *ngIf="!selectedTheme">
              <div class="self-end mr-2">
                <i
                  class="sctr-icon-face-smile text-base cursor-pointer rounded-md px-1.5 py-0.5 hover:bg-palette-gray-200"
                  (click)="emoji.toggle($event)"></i>
                <p-overlayPanel [styleClass]="'emojiPanel'" (onShow)="onShowOverlayPanel()" #emoji>
                  <comment-emoji (selectEmoji)="onSelectEmoji($event)" [isShowEmoji]="true"></comment-emoji>
                </p-overlayPanel>
              </div>
            </ng-container>
          </div>

          <div class="w-full">
            <div
              class="flex flex-wrap w-full gap-1 py-2"
              *ngIf="this.selectionFriendTag && this.selectionFriendTag.length > 0">
              <span class="text-sm font-medium">{{ 'POST.POPUP.CREATE.FEELING.WITH' | translate }}</span>
              <div *ngFor="let item of selectionFriendTag; let i = index">
                <div class="flex border rounded-md align-items-center bg-palette-base-white">
                  <div class="grid content-center p-1">
                    <img [src]="getAvatar(item.avatar_thumbnail_url)" class="w-4 h-4 min-w-[16px] rounded-full" />
                  </div>
                  <span class="px-1 text-sm font-semibold" style="line-break: anywhere">{{ item.full_name }}</span>
                  <div class="grid content-center px-1 cursor-pointer" (click)="deleteFriendTags(i)">
                    <i class="pi pi-times text-[12px] text-palette-gray-400"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-wrap w-full gap-1 py-2"
              *ngIf="this.selectionGroupTag && this.selectionGroupTag.length > 0">
              <span class="text-sm font-medium">{{ 'POST.POPUP.CREATE.FEELING.WITH' | translate }}</span>
              <div *ngFor="let item of selectionGroupTag; let i = index">
                <div class="flex border rounded-md items-center">
                  <div class="grid content-center p-1">
                    <img [src]="getAvatar(item.user_info.avatar_thumbnail_url)" class="w-4 h-4 rounded-full" />
                  </div>

                  <span class="px-1 text-sm font-semibold">{{ item.user_info.full_name }}</span>
                  <div class="grid content-center px-1 cursor-pointer" (click)="deleteGroupTags(i)">
                    <i class="pi pi-times text-[12px] text-palette-gray-400"></i>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showFriendTag" class="flex w-full py-2 gap-2">
              <img alt="logo" class="w-5" src="../../../../assets/icons/leading.svg" />
              <div class="w-full p-fluid">
                <input
                  #tagFriend
                  type="text"
                  pInputText
                  placeholder="{{ 'POST.ENTER_YOUR_NAME_FRIEND' | translate }}"
                  (keyup)="onSearchKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                  class="w-full text-sm font-normal rounded-lg border-palette-gray-100 bg-palette-gray-50 placeholder:text-palette-gray-500" />
                <p-overlayPanel
                  #tagOverlayPanel
                  appendTo="tagFriend"
                  styleClass="before:content-[none] after:content-[none] mt-1 p-0 absolute bg-white border border-palette-gray-200 rounded-xl shadow !z-[10000]"
                  [style]="{ 'top': overlayPositionTop + 'px', 'left': overlayPosition.left + 40 + 'px' }">
                  <div
                    #tagListContainer
                    scroll
                    (scrolledToBottom)="loadMoreMentionFriends()"
                    class="h-fit max-h-[300px] flex flex-col gap-1 scroll-smooth overflow-y-auto -my-3 -mx-4 px-1">
                    <ng-container *ngIf="!isLoadingMentionList && mentionList.length; else loadingMentionList">
                      <div
                        [id]="'tag-item-' + index"
                        class="min-h-fit"
                        *ngFor="let friend of mentionList; index as index"
                        (click)="onSelectTagFriend(friend)">
                        <div
                          class="flex items-center min-h-fit cursor-pointer rounded-lg p-1.5"
                          [ngClass]="highlightedIndex === index ? 'bg-palette-blue-100' : 'hover:bg-palette-gray-100'">
                          <avatar [isDisableNavigate]="true" [isSmallThumbnail]="true" [userInfo]="friend"></avatar>
                          <span
                            [title]="friend.full_name"
                            class="px-2 font-semibold text-sm whitespace-nowrap overflow-hidden w-[200px] md:w-fit max-w-[200px] truncate select-none"
                            #nameSpan
                            >{{ friend.full_name }}
                          </span>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="isLoadingMoreMentionList"
                      [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
                    <ng-template #loadingMentionList>
                      <ng-container [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
                      <ng-container [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
                      <ng-container [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
                    </ng-template>
                  </div>
                  <ng-template #loadingMentionFriend>
                    <div class="flex items-center gap-2 min-h-fit p-1.5">
                      <p-skeleton shape="circle" size="40px" />
                      <p-skeleton width="9rem" height="20px" />
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
            <div *ngIf="showGroupMembers" class="flex">
              <img alt="logo" class="w-5" src="../../../../assets/icons/leading.svg" />
              <div class="w-full px-2 p-fluid">
                <input
                  #tagGroup
                  type="text"
                  pInputText
                  [placeholder]="'GROUP.ENTER_@_TO_TAG_YOUR_GROUP' | translate"
                  (keyup)="onSearchKeyUpGroup($event)"
                  class="w-full text-sm font-normal rounded-lg bg-palette-gray-50 border-palette-gray-100 text-palette-gray-500" />
                <div *ngIf="isShowSuggestionsGroup" class="absolute z-50 mt-1">
                  <p-listbox
                    [options]="memberGroupSuggestion"
                    [listStyle]="{ 'max-height': '250px' }"
                    [multiple]="true"
                    optionLabel="full_name"
                    [style]="{ width: '15rem' }"
                    [metaKeySelection]="false"
                    (onClick)="addGroupTag($event)">
                    <ng-template let-group pTemplate="item">
                      <div class="flex align-items-center">
                        <img [src]="getAvatar(group.user_info.avatar_thumbnail_url)" class="w-6 h-6 rounded-full" />
                        <span class="px-2 text-sm font-semibold">{{ group.user_info.full_name }}</span>
                      </div>
                    </ng-template>
                  </p-listbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [ngClass]="{
          'min-w-[160px] max-h-[calc(100vh_-_400px)] overflow-x-hidden overflow-y-auto': !(shareType && sharedPost),
          'max-h-[calc(100vh_-_480px)]': !(shareType && sharedPost && (showFriendTag || showGroupMembers))
        }">
        <div class="p-4 pb-0">
          <p class="flex-wrap max-w-full py-1 text-sm" *ngIf="checkInObject">
            {{ 'POST.STATUS.CHECKIN_AT' | translate }}
            <span class="text-base break-words">{{ checkInObject.object_data_check_in?.page_name }}</span>
          </p>
          <div *ngIf="selectedStatus" class="flex flex-row items-center gap-1 mb-2 emoji">
            <span class="text-sm font-medium">{{ 'POST.POPUP.CREATE.FEELING.TITLE' | translate }}</span>
            <span>{{ selectedStatus | statusIcons }}</span>
            <span class="text-base font-semibold">{{ translate(selectedStatus) }}</span>
          </div>
        </div>

        <div *ngIf="checkCharacterLimit()" class="p-4 text-system-error-400">
          <p>
            {{
              'ERROR.POST.LIMIT_CHARACTER' | translate: { characterLimit: limit, currentCharacters: currentCharacter }
            }}
          </p>
        </div>
        <div *ngIf="previewData || loadingUrl" class="py-2">
          <post-content-preview
            [loading]="loadingUrl"
            [isCreatingPost]="true"
            [data]="previewData"
            (removePreview)="onRemovePreviewLink($event)">
          </post-content-preview>
        </div>
        <div
          *ngIf="this.postEdit?.post_pattern !== POST_PATTERN.link && !this.previewData && !captureImg"
          class="pt-4"
          [ngClass]="{ 'hidden': selectedFiles.length > 0 || (shareType && sharedPost) || !isTheme }">
          <p-carousel
            #carousel
            [responsiveOptions]="responsiveOptions"
            [value]="themes"
            [numVisible]="11"
            [numScroll]="8"
            [showIndicators]="false">
            <ng-template let-theme pTemplate="item">
              <div class="group p-1">
                <div
                  #themeSelect
                  (click)="selectTheme(theme)"
                  [ngClass]="{
                    'ring ring-offset-1 ring-palette-blue-300 hover:ring-palette-blue-400':
                      theme === selectedTheme || (theme?.code === 'default' && !selectedTheme),
                    'bg-palette-gray-200': theme?.code === 'default'
                  }"
                  class="hover:ring hover:ring-offset-1 hover:ring-palette-gray-300
              relative rounded-lg h-11 w-11 cursor-pointer transition-all theme-selection flex justify-center items-center {{
                    applyThemeSelection(theme, themeSelect)
                  }}">
                  <i
                    *ngIf="theme?.code === 'default'"
                    class="sctr-icon-slash-circle-01 text-xl/5 text-palette-gray-600 font-semibold"></i>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>
        <div
          *ngIf="shareType === shareTypeList.group"
          class="items-center mt-3 text-sm font-medium text-palette-gray-600">
          {{ 'POST.DROPDOWN_SHARE.SHARE_TO_GROUP' | translate }}
          <span class="ml-[6px] mr-2 font-semibold text-base text-gray-800">
            {{ groupInfo ? groupInfo.group_name : postEdit?.group_object?.group_name}}
          </span>
          <i *ngIf="!postEdit" class="text-base cursor-pointer sctr-icon-edit-03 text-palette-blue-600" (click)="editGroupToShare()"></i>
        </div>

        <div class="mt-3">
          <div *ngIf="shareType && sharedPost">
            <post [isDisabledPost]="true" [isSharedPost]="true" [post]="sharedPost"></post>
          </div>
          <div
            #captureContainer
            *ngIf="captureImg"
            class="flex items-center justify-center w-full h-full p-4 bg-center bg-cover rounded-lg"
            style="background-image: url('assets/images/bg-quick-summary.png')">
            <img class="w-[40%]" src="{{ captureImg }}" />
          </div>
        </div>

        <div *ngIf="!shareType && !captureImg" class="flex w-full p-2">
          <div class="flex w-1/2 justify-content-center">
            <button
              *ngIf="this.postEdit?.post_pattern !== POST_PATTERN.link"
              [disabled]="selectedTheme || previewData"
              [ngClass]="{ 'cursor-not-allowed': selectedTheme }"
              class="flex items-center p-2 text-white rounded"
              (click)="toggleUploadSection()"
              type="button">
              <img
                alt="logo"
                [src]="
                  selectedTheme || previewData
                    ? '../../../../assets/images/icon/image-disable.svg'
                    : '../../../../assets/images/icon/image.svg'
                " />
            </button>
            <button class="flex items-center p-2 text-white rounded" type="button" (click)="toggleStatusTags(true)">
              <img alt="logo" src="../../../../assets/images/icon/emoji.svg" />
            </button>
            <button (click)="isPendingCheckIn = true" class="flex items-center p-2 text-white rounded" type="button">
              <img alt="logo" src="../../../../assets/images/icon/location.svg" />
            </button>
            <ng-container *ngIf="!isFanpagePost">
              <button
                *ngIf="!isGroupPost"
                class="flex items-center p-2 text-white rounded"
                type="button"
                (click)="toggleFriendTags()">
                <img alt="logo" src="../../../../assets/images/icon/tag.svg" />
              </button>
              <button
                *ngIf="isGroupPost"
                class="flex items-center p-2 text-white rounded"
                type="button"
                (click)="toggleGroupMembers()">
                <img alt="logo" src="../../../../assets/images/icon/tag.svg" />
              </button>
            </ng-container>
          </div>
        </div>

        <p-progressBar
          *ngIf="showProgress"
          mode="indeterminate"
          [style]="{ 'height': '6px', 'marginBottom': '1rem' }"></p-progressBar>
        <div class="grid flex-column border rounded-2xl p-[10px]" *ngIf="selectedFiles && selectedFiles.length > 0">
          <div class="relative overflow-hidden cursor-default rounded-2xl">
            <div class="absolute top-[10px] left-[10px] flex gap-3 z-[1]">
              <p-button
                [styleClass]="'bg-white text-palette-gray-700 border-none hover:bg-palette-gray-200'"
                [label]="'POST.POPUP.EDIT_POST.EDIT_ALL' | translate"
                icon="sctr-icon-edit-02"
                size="small"
                (onClick)="isEditImgVideo = true"></p-button>
              <p-button
                [styleClass]="'bg-white text-palette-gray-700 border-none relative hover:bg-palette-gray-200'"
                [label]="'COMMON.ADD_PHOTOS_VIDEOS' | translate"
                icon="sctr-icon-image-01"
                size="small">
                <input
                  type="file"
                  id="file_upload"
                  name="file_upload"
                  class="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
                  multiple
                  accept="image/*, video/*"
                  (change)="handleFileUpload($event)" />
              </p-button>
            </div>
            <p-button
              [styleClass]="
                'bg-white text-palette-gray-700 border-none absolute top-[10px] right-[10px] z-[1] rounded-[50%] text-xl leading-[18px]'
              "
              icon="sctr-icon-x-close"
              outlined
              (onClick)="deleteMedia()"></p-button>
            <media [isPreview]="true" [mediaList]="selectedFiles"></media>
          </div>
        </div>

        <div
          class="w-full"
          *ngIf="showUploadSection && selectedFiles.length <= 0 && !selectedTheme"
          (dragover)="onDragOver($event)"
          (drop)="onDrop($event)">
          <label
            class="flex justify-center w-full h-32 px-4 transition bg-white border appearance-none cursor-pointer rounded-xl border-palette-gray-200 hover:border-palette-gray-400 focus:outline-none">
            <span class="flex items-center space-x-2">
              <span class="font-medium text-gray-600">
                <div class="flex justify-center py-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                  </svg>
                </div>
                <div class="text-center">
                  <span class="text-blue-600">{{ 'POST.CLICK_TO_UPLOAD' | translate }}</span>
                </div>

                <div class="text-center">{{ 'POST.DRAG_AND_DROP' | translate }}</div>
              </span>
            </span>
            <input
              type="file"
              id="file_upload"
              name="file_upload"
              class="hidden"
              multiple
              [accept]="allowedTypes.join(',')"
              (change)="handleFileUpload($event)" />
          </label>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="loading">
      <p-progressSpinner
        styleClass="w-14 h-14 flex justify-center"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"></p-progressSpinner>
    </div>
    <!-- End Create Post Field -->

    <!-- Tag Status  -->
    <div *ngIf="showStatusTag">
      <post-dialog-tag-status [selectedStatus]="selectedStatus" (selected)="selectedStatusChange($event)" />
    </div>
    <!-- End Tag Status  -->

    <!-- Pending check in  -->
    <div *ngIf="isPendingCheckIn">
      <post-dialog-tag-status
        [isCheckIn]="true"
        [selectedLocation]="checkInObject"
        (selectedLocationEmit)="selectedLocationChange($event)" />
    </div>
    <!-- Create Post Button Footer -->
  </ng-template>
  <ng-template pTemplate="footer">
    <!-- End Create Post Button Footer -->
    <div *ngIf="!showStatusTag && !loading" class="flex justify-end">
      <!-- <p-dropdown
        *ngIf="!isPendingCheckIn"
        [options]="privacyOptions"
        optionLabel="label"
        [(ngModel)]="privacy"
        [disabled]="isGroupPost || isFanpagePost"
        class="w-32 custom-dropdown-post"
        (onChange)="onChangePriority($event.value.privacy)"
        (ngModelChange)="handlePrivacyGroup()">
        <ng-template let-option pTemplate="selectedItem">
          <div class="flex items-center w-16 p-0 text-sm">
            <i class="{{ option.icon }} mr-2 text-base"></i>
            <span>{{ option.label }}</span>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div class="flex items-center text-sm">
            <i class="{{ option.icon }} mr-2 text-base"></i>
            <span>{{ option.label }}</span>
          </div>
        </ng-template>
      </p-dropdown> -->
      <div class="flex items-center mr-2 privacy-dropdown">
        <p-dropdown
          *ngIf="!(selectGroupPrivacy$ | async)"
          (onChange)="onChangePriority($event.value.privacy)"
          (ngModelChange)="isEditPost ? setPrivacyEditPost() : handlePrivacyGroup()"
          [(ngModel)]="privacy"
          [options]="privacyOptions"
          [disabled]="isGroupPost || isFanpagePost || shareType === shareTypeList.group"
          styleClass="w-full items-center rounded-lg border-none shadow-none bg-transparent"
          dropdownIcon="sctr-icon-chevron-down text-xl/5"
          optionLabel="label"
          [ngClass]="{ 'remove-arrow': isGroupPost || isFanpagePost || shareType === shareTypeList.group }">
          <ng-template let-option pTemplate="selectedItem">
            <div class="flex gap-2 items-center text-palette-gray-600 text-sm font-normal">
              <i class="{{ option.icon }} text-base text-palette-gray-600"></i>
              <span class="font-medium text-sm text-palette-gray-600">{{ option.label }}</span>
            </div>
          </ng-template>
          <ng-template let-privacyItem pTemplate="item">
            <div class="flex gap-2 items-center">
              <i class="{{ privacyItem.icon }} text-palette-gray-600"></i>
              <span class="font-medium text-sm text-palette-gray-600">{{ privacyItem.label }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <p-dropdown
          *ngIf="selectGroupPrivacy$ | async"
          (onChange)="onChangePriority($event.value.privacy)"
          (ngModelChange)="handlePrivacyGroup()"
          [(ngModel)]="privacy"
          [options]="privacyOptions"
          [disabled]="isGroupPost || isFanpagePost || shareType === shareTypeList.group"
          styleClass="w-full items-center rounded-lg border-none shadow-none bg-transparent"
          dropdownIcon="sctr-icon-chevron-down text-xl/5"
          optionLabel="label"
          [ngClass]="{ 'remove-arrow': isGroupPost || isFanpagePost || shareType === shareTypeList.group }">
          <ng-template let-option pTemplate="selectedItem">
            <div class="flex gap-2 items-center text-palette-gray-600 text-sm font-normal">
              <i class="{{ option.icon }} text-base text-palette-gray-600"></i>
              <span class="font-medium text-sm text-palette-gray-600">{{ option.label }}</span>
            </div>
          </ng-template>
          <ng-template let-privacyItem pTemplate="item">
            <div class="flex gap-2 items-center">
              <i class="{{ privacyItem.icon }} text-palette-gray-600"></i>
              <span class="font-medium text-sm text-palette-gray-600">{{ privacyItem.label }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <button
        *ngIf="!isPendingCheckIn"
        class=" text-white font-bold py-2 px-8 rounded-lg {{
          shouldDisableButton() && isFormDisabled
            ? 'bg-palette-blue-200 cursor-not-allowed'
            : 'bg-palette-blue-600 hover:bg-palette-blue-700'
        }}"
        [disabled]="shouldDisableButton() && isFormDisabled"
        type="submit"
        form="postForm"
        (click)="onSubmit()">
        {{ isEditPost ? ('COMMON.BUTTON_ACTION.CONFIRM' | translate) : ('POST.POPUP.CREATE.POST' | translate) }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf="isEditImgVideo"
  [(visible)]="isEditImgVideo"
  [style]="{ height: 'auto', minWidth: '300px' }"
  [modal]="true"
  class="custom-dialog"
  [ngClass]="{ 'isDialogShare': shareType }"
  [draggable]="false"
  [resizable]="false"
  [closable]="!showStatusTag">
  <ng-template pTemplate="header" class="relative">
    <div class="w-full flex items-center gap-[18px] text-xl font-semibold">
      {{ 'POST.POPUP.EDIT_POST.EDIT_ALL_TITLE' | translate }}
    </div>
  </ng-template>

  <ng-template pTemplate="contentHeight" class="relative">
    <div class="container mb-6">
      <div
        class="grid gap-3"
        pDroppable
        [ngClass]="selectedFiles.length >= 2 ? 'md:grid-cols-3 grid-cols-2' : 'grid-cols-1'">
        <div
          *ngFor="let item of selectedFiles; let i = index"
          class="relative flex justify-center group bg-palette-gray-100"
          pDraggable>
          <custom-video
            class="max-h-[270px]"
            *ngIf="item.type === video"
            [isPreview]="true"
            [item]="item"
            [isCover]="!(selectedFiles.length === 1)"></custom-video>
          <img
            *ngIf="item.type === image"
            [src]="customUrl(item)"
            class="h-[270px] object-contain m-auto w-full"
            alt="Image" />
          <p-button
            [styleClass]="
              'bg-white text-palette-gray-700 border-none absolute top-[10px] right-[10px] z-[1] rounded-[50%] text-xl leading-[18px] group-hover:flex justify-center hidden'
            "
            icon="sctr-icon-x-close"
            outlined
            (onClick)="deleteImage(i)"></p-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <ng-container *ngIf="!showStatusTag">
      <div>
        <p-button
          [styleClass]="'bg-transparent text-palette-blue-600 border-none hover:bg-palette-gray-200 relative'"
          [label]="'COMMON.ADD_PHOTOS_VIDEOS' | translate"
          icon="ctr-icon-image-01"
          size="small"
          (onClick)="isEditImgVideo = true">
          <input
            type="file"
            id="file_upload"
            name="file_upload"
            class="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
            multiple
            accept="image/*, video/*"
            (change)="handleFileUpload($event)"
        /></p-button>
        <p-button
          [styleClass]="'bg-palette-blue-600 text-white border-none'"
          [label]="'POST.POPUP.EDIT_POST.EDIT_ALL_DONE' | translate"
          size="small"
          (onClick)="isEditImgVideo = false; isEditting = true"></p-button>
      </div>
    </ng-container>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="visibleCloseConfirm"
  [style]="{ 'width': '610px' }"
  class="custom-dialog"
  styleClass="border border-palette-gray-200 rounded-xl bg-white"
  contentStyleClass="border-0 py-0 px-6"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="confirm('')">
  <ng-template pTemplate="header">
    <h1 class="text-lg font-semibold text-palette-gray-900">{{ 'POST.DISCARD_POST' | translate }}</h1>
  </ng-template>
  <span class="text-sm text-palette-gray-700">
    {{ 'POST.DISCARD_POST_CONFIRMATION_DETAILS' | translate }}
  </span>
  <ng-template pTemplate="footer">
    <div class="w-full flex gap-3 justify-end">
      <button (click)="confirm(confirmCloseActions.discard)" class="secondary-btn mr-0">
        {{ 'COMMON.BUTTON_ACTION.DISCARD' | translate }}
      </button>
      <button
        *ngIf="!(shareType || isEditPost || captureImg)"
        (click)="confirm(confirmCloseActions.saveAsDraft)"
        class="secondary-btn mr-0">
        {{ 'COMMON.BUTTON_ACTION.SAVE_AS_DRAFT' | translate }}
      </button>
      <button
        (click)="confirm(confirmCloseActions.keepEditing)"
        class="confirm-cta-buttons text-white bg-palette-blue-600 border-palette-blue-600 hover:bg-palette-blue-700 hover:border-palette-blue-700">
        {{ 'COMMON.BUTTON_ACTION.KEEP_EDITING' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
