import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import {
  ClaimedRewardModel,
  CoinSentModel,
  FanpageCoinModel,
  MyCoinModel,
  QuestModel,
  SentCoinToFriendModel
} from '@app/lib/api/coin-exchange/api.coin.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoinManagementService {
  private apiUrl = environment.baseURL;
  totalCoin = new BehaviorSubject(0);
  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getTasks(pageNum: number, pageSize: number): Observable<QuestModel[]> {
    return this.api.coinManagement.getTasks(pageNum, pageSize);
  }

  getCoin(): Observable<MyCoinModel> {
    return this.api.coinManagement.getMyCoin();
  }

  claimReward(questId: string, body: any): Observable<ClaimedRewardModel> {
    return this.api.coinManagement.postClaimReward(questId, body);
  }

  postGiftCoin(body: any): Observable<CoinSentModel> {
    return this.api.coinManagement.postGiftCoin(body);
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  sendCoinToFriend(body: any): Observable<SentCoinToFriendModel> {
    return this.api.coinManagement.postSendCoinToFriend(body);
  }

  getCoinFanpage(objectId: string): Observable<FanpageCoinModel> {
    return this.api.coinManagement.getCoinFanpage(objectId);
  }

  getTransactionHistory(pageNum: number, pageSize: number) {
    return this.api.coinManagement.getTransactionHistory(pageNum, pageSize);
  }

  getCoinBloomFilter(): Observable<string> {
    return this.api.coinManagement.getCoinBloomFilter();
  }
}
