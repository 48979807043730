import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { FeedsEngineService } from '@app/core/services/feeds-engine.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_ACTION, ADS_CATEGORY } from '@app/lib/api/client/api.client.constant';
import { LANGUAGES } from '@app/shared/constant';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

const DEFAULT_AD_BANNERS = {
  sponsored1: {
    id: null,
    imageUrl: '/media/libraries/default/ads_default_sponsored1.png',
    title: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_TITLE',
    description: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_DESCRIPTION',
    destination_url: ''
  },
  sponsored2: {
    id: null,
    imageUrl: '/media/libraries/default/ads_default_sponsored2.png',
    title: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_TITLE',
    description: 'ADVERTISING.DEFAULT_ADS_BANNER.SPONSORED_DESCRIPTION',
    destination_url: ''
  }
};

@Component({
  selector: 'ads-banner',
  templateUrl: './ads-banner.component.html',
  styleUrls: ['./ads-banner.component.scss']
})
export class AdsBannerComponent implements OnInit, OnDestroy {
  imgPanel1: string | null = null;
  imgPanel2: string | null = null;
  baseUrl: string = environment.baseURL;
  dataPanel1: any;
  dataPanel2: any;
  dataSponsored1: any;
  dataSponsored2: any;
  isLoadingSponsored = false;
  currentYear = new Date().getFullYear();
  companyName = environment.COMPANY_NAME;
  adsCategory = ADS_CATEGORY;
  hideSponsorSection = false;
  languageSub: Subscription;
  @Output() visibleLanguageSetting = new EventEmitter();
  @Input() currentLanguage: string;
  constructor(
    private feedsEngineService: FeedsEngineService,
    private analyticsService: AnalyticsService,
    private translationService: TranslationService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.feedsEngineService.adsEnginePanel(0, 1, true).subscribe((res: any) => {
      if (res) {
        const mediaId = res[0]?.post_info?.media[0]?.id;
        this.dataPanel1 = res[0];
        if (mediaId === '/media/libraries/default/ads_default_panel1.png') {
          this.imgPanel1 = mediaId;
        } else if (mediaId) {
          this.imgPanel1 = `/storage/files/thumbnail/${mediaId}`;
        } else {
          this.imgPanel1 = res.length !== 0 ? res[0][0]?.media[0]?.id : null;
        }
      }
    });
    this.feedsEngineService.adsEnginePanel(0, 1, false).subscribe((res: any) => {
      const mediaId = res[0]?.post_info?.media[0]?.id;
      this.dataPanel2 = res[0];
      if (mediaId === '/media/libraries/default/ads_default_panel2.png') {
        this.imgPanel2 = mediaId;
      } else if (mediaId) {
        // TODO: temporarily update ads display behavior
        // this.imgPanel2 = `/storage/files/thumbnail/${mediaId}`;
        this.imgPanel2 = mediaId;
      } else {
        this.imgPanel2 = res.length !== 0 ? res[0][0]?.media[0]?.id : null;
      }
    });
    this.isLoadingSponsored = true;
    this.feedsEngineService.adsEngineSponsored().subscribe({
      next: res => {
        this.isLoadingSponsored = false;
        if (res) {
          // TODO: temporarily update ads display behavior
          this.dataSponsored1 =
            res[0] && res[0].id
              ? { ...res[0], imageUrl: `/storage/files/thumbnail/${res[0].post_info?.media[0]?.id}` }
              : DEFAULT_AD_BANNERS.sponsored1;
          this.dataSponsored2 =
            res[1] && res[1].id
              ? { ...res[1], imageUrl: `/storage/files/thumbnail/${res[1].post_info?.media[0]?.id}` }
              : DEFAULT_AD_BANNERS.sponsored2;
        }
      },
      error: () => {
        this.isLoadingSponsored = false;
        this.hideSponsorSection = true;
      }
    });

    this.languageSub = this.translationService.languageSubject.subscribe(() => {
      this.currentLanguage =
        LANGUAGES.find(el => el.value === this.translateService.currentLang)?.label || 'HEADER.LANGUAGES.EN';
    });
  }

  ngOnDestroy(): void {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  handleNavigate(event: any, category: string) {
    event && event.id && this.analyticsService.pushToBuffer(event.id, category, ADS_ACTION.click);
    if (event?.destination_url) {
      window.open(
        `${this.baseUrl}/link-hub/?url=${event.destination_url}&adsId=${event.post_id}&platform=Web&screenSize=${innerWidth}x${innerHeight}&module=soctrip`,
        '_blank'
      );
    }
  }

  onImageLoad(event: any, category: string): void {
    if (event && event.id) {
      this.analyticsService.pushToBuffer(event.id, category, ADS_ACTION.view);
      this.analyticsService.pushToBuffer(event.id, category, ADS_ACTION.distribute);
    }
  }

  showLanguageSetting() {
    this.visibleLanguageSetting.emit(true);
  }
}
